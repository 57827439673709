import { fetchApi } from '../../services/api';
import { useLoaderData, Params } from 'react-router';
import { Title } from '../../utils/title';
import { getPropertyInvestmentType, getPropertyInvestorStatus } from '../../utils/property';
import { InvestorTransactionViewModel } from 'TypeGen/investor/transactions/investor-transaction-view-model';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { DateTimeCell } from 'components/DateTimeCell';
import { Link } from 'react-router-dom';

export const InvestorTransactionsLoader = (params: Params<string>) => {
    return fetchApi(`/api/Investor/Transactions/${params.InvestorId}`);
}

const InvestorTransactions = () => {
   const response = useLoaderData() as { Transactions: InvestorTransactionViewModel[] };

   return <>
      <br />
      <Title string="Transactions" />
      <Grid data={response.Transactions}>
        <GridColumn field="FullAddress" title="Address" cell={({dataItem}) => <td>
            <Link to={`/Property/Profile/${dataItem.PropertyID}`} style={{ color: '#007bff' }}>
                {dataItem.FullAddress}
            </Link>
        </td>} />
        <GridColumn field="InvestmentType" title="Type" cell={({dataItem}) => <td>{getPropertyInvestmentType(dataItem.InvestmentType)}</td>} />
        <GridColumn field="Status" title="Status" cell={({dataItem}) => <td>{getPropertyInvestorStatus(dataItem.Status)}</td>} />
        <GridColumn field="SubmittedDateTime" title="Submitted" cell={DateTimeCell} />
        <GridColumn field="OriginalOffer" title="Offer" format="{0:c}" />
        <GridColumn field="AcceptedOffer" title="Accepted" format="{0:c}" />
        <GridColumn field="FundingSource" title="Funding" />
      </Grid>
   </>
}

export default InvestorTransactions;