import { fetchApi } from '../../../services/api';
import { RegionCostViewModel } from '.';

export const insertItem = async (item: RegionCostViewModel) => {
  await fetchApi('/api/RegionCost/Insert', item, 'POST');
  const data = await getItems();
  return data;
};

export const getItems = async () => {
  const response = await fetchApi('/api/RegionCost/List');
    return {
        RegionCosts: response.RegionCosts as RegionCostViewModel[],
        IsAdmin: response.IsAdmin
    };
};

export const updateItem = async (item: RegionCostViewModel) => {
  await fetchApi('/api/RegionCost/Update', item, 'POST');
};

export const deleteItem = async (item: RegionCostViewModel) => {
  await fetchApi('/api/RegionCost/Remove', item, 'POST');
};