import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Checkbox, Input, MaskedTextBox } from "@progress/kendo-react-inputs";
import { Error, Label } from '@progress/kendo-react-labels';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { paths } from "../../App";
import { fetchApi } from "../../services/api";
import { emailValidator, isNullOrEmptyValidator, JsonResponse, phoneValidator } from "../../utils/generic";

const InvestorSignup = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState({ value: '', error: '' });
    const [lastName, setLastName] = useState({ value: '', error: '' });
    const [phone, setPhone] = useState({ value: '', error: '' });
    const [email, setEmail] = useState({ value: '', error: '' });
    const [howYouHeard, setHowYouHeard] = useState({ value: '', error: '' });
    const [howYouHeards, setHowYouHeards] = useState<Array<{ label: string, value: string }>>([]);
    const [feesPaid, setFeesPaid] = useState(false);
    const [loading, setLoading] = useState(false);

    const _onSignUpPressed = () => {
        const phoneValue = phone.value.replace(/\D/g, '');
        const firstNameError = isNullOrEmptyValidator(firstName.value, 'First name cannot be empty.');
        const lastNameError = isNullOrEmptyValidator(lastName.value, 'Last name cannot be empty.');
        const phoneError = phoneValidator(phoneValue);
        const emailError = emailValidator(email.value);
        const howDidHearError = isNullOrEmptyValidator(howYouHeard.value, 'Select how you heard.');

        if (firstNameError || lastNameError || phoneError || emailError || howDidHearError) {
            setFirstName({ ...firstName, error: firstNameError });
            setLastName({ ...lastName, error: lastNameError });
            setEmail({ ...email, error: emailError });
            setPhone({ ...phone, error: phoneError });
            setHowYouHeard({ ...howYouHeard, error: howDidHearError });
            return;
        }

        setLoading(true);

        const data = {
            FirstName: firstName.value,
            LastName: lastName.value,
            PhoneNumber: phoneValue,
            Email: email.value,
            HowYouHeard: howYouHeard.value,
            AcceptedTermsVersion: 0,
            FeesPaid: feesPaid
        }

        fetchApi('/api/Investor/Signup', data, 'POST')
            .then((data: JsonResponse) => {
                setLoading(false);
                if (data.Success) {
                    navigate(paths.Investor);
                } else {
                    alert(`Signup Error: ${data.ErrorMessage}`);
                }
            }).catch(() => {
                    setLoading(false);
                    alert('Signup Error: Unknown Error!');
            });
    };

    const _loadInitialData = () => {
        fetchApi('/api/Investor/HowYouHeard')
            .then((response: { HowYouHeards: string[] }) => {
                setHowYouHeards(response.HowYouHeards.map(howYouHeard => ({ label: howYouHeard, value: howYouHeard })));
            });
    }

    // componentDidMount
    useEffect(() => {
        _loadInitialData();
    }, []);

    return <div className="k-form k-form-md">
        <h3>Become an Investor</h3>
        <FieldWrapper>
            <Label>First Name</Label>
            <Input
                required
                readOnly={loading}
                onChange={e =>  setFirstName({ value: e.value, error: '' })}
                value={firstName.value}
                valid={firstName.error === ''}
                validationMessage={firstName.error}
            />
            <Error>{firstName.error}</Error>
        </FieldWrapper>
        <FieldWrapper>
            <Label>Last Name</Label>
            <Input
                required
                readOnly={loading}
                onChange={e =>  setLastName({ value: e.value, error: '' })}
                value={lastName.value}
                valid={lastName.error === ''}
            />
            <Error>{lastName.error}</Error>
        </FieldWrapper>
        <FieldWrapper>
            <Label>Phone Number</Label>
            <MaskedTextBox
                required
                readonly={loading}
                mask="(000) 000-0000"
                value={phone.value}
                valid={phone.error === ''}
                onChange={(e) => e.value === '(___) ___-____' ? setPhone({ value: '', error: '' }) : setPhone({ value: e.value, error: '' })}
            />
            <Error>{phone.error}</Error>
        </FieldWrapper>
        <FieldWrapper>
            <Label>Email</Label>
            <Input
                required
                readOnly={loading}
                onChange={e =>  setEmail({ value: e.value, error: '' })}
                value={email.value}
                valid={email.error === ''}
            />
            <Error>{email.error}</Error>
        </FieldWrapper>
        <FieldWrapper>
            <Label>How did you hear about us?</Label>
            <DropDownList
                required
                disabled={loading}
                data={howYouHeards}
                textField="label"
                dataItemKey="value"
                onChange={e => setHowYouHeard({ value: e.value.value, error: '' })}
                value={howYouHeards.find(x => x.value === howYouHeard.value)}
            />
            <Error>{howYouHeard.error}</Error>
        </FieldWrapper>

        <FieldWrapper>
            <Checkbox
                value={feesPaid}
                label="Fees already paid?"
                onChange={(e) => setFeesPaid(e.value)}
            />
        </FieldWrapper>

        <br />
        <Button type="button" themeColor="primary" disabled={loading} onClick={() => _onSignUpPressed()}>
            Add Investor
        </Button>
    </div>;
}

export default InvestorSignup;