import * as React from 'react';

type Props = {
    json: string;
}

export default class JsonPretty extends React.PureComponent<Props> {

    public render() {
        return <pre
            className="json-pretty"
            dangerouslySetInnerHTML={{ __html: this.pretty(JSON.parse(this.props.json)) }} />;
    }

    private _replace(match: string, ind: any, key: any, val: any, tra: any) {
        var spanEnd = '</span>';
        var keySpan = '<span class=json-key>';
        var valSpan = '<span class=json-value>';
        var strSpan = '<span class=json-string>';
        var booSpan = '<span class=json-boolean>';
        var sps = ind || '';
        if (key) {
          sps = sps + '"' + keySpan + key.replace(/^"|":\s$/g, '') + spanEnd + '": ';
        }

        if (val) {
          if (val === 'true' || val === 'false') {
            sps = sps + booSpan + val + spanEnd;
          } else {
            sps = sps + (val[0] === '"' ? strSpan : valSpan) + val + spanEnd;
          }
        }

        return sps + (tra || '');
    }

    private pretty(obj: string) {
        var regLine = /^( *)("[^"]+": )?("[^"]*"|[\w.+-]*)?([,[{]|\[\s*\],?|\{\s*\},?)?$/mg;
        var text = JSON.stringify(obj, null, 2);

        if (!text) {
          return text;
        }

        return text
            .replace(/&/g, '&amp;')
            .replace(/\\"([^,])/g, '&quot;$1')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(regLine, this._replace);
    }
}
