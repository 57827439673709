import { GridCellProps } from "@progress/kendo-react-grid";
import { Link } from 'react-router-dom';
import { AgentViewModel } from '../../utils/agent';

export const InvestorNameCell = (props: GridCellProps) => {
    const dataItem = props.dataItem as AgentViewModel;
    return (
        <td>
            <Link to={`/Agent/${dataItem.AgentID}/Profile`} style={{ color: '#007bff' }}>
                {dataItem.FullName}
            </Link>
        </td >
    );
}

export default InvestorNameCell;