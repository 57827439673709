import { useEffect, useState } from "react";

const InfoWindow: React.FC<google.maps.InfoWindowOptions> = (options) => {
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>();

  useEffect(() => {
    if (!infoWindow) {
      setInfoWindow(new google.maps.InfoWindow());
    }

    // remove marker from map on unmount
    return () => {
      if (infoWindow) {
        infoWindow.close();
      }
    };
  }, [infoWindow]);

  useEffect(() => {
    if (infoWindow) {
      infoWindow.setOptions(options);
    }
  }, [infoWindow, options]);

  return null;
};

export default InfoWindow;