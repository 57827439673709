import './ColumnMenu.css';
import { GridColumnMenuFilter, GridColumnMenuProps } from '@progress/kendo-react-grid';

const ColumnMenu = (props: GridColumnMenuProps) => {
    return (
        <div>
            <GridColumnMenuFilter {...props} filterOperators={props.filterOperators} expanded={true} hideSecondFilter={true} />
        </div>
    );
}

export default ColumnMenu;
