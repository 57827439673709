import { Grid, GridColumn as Column, GridNoRecords, GridToolbar } from "@progress/kendo-react-grid";
import { DateTimeCell } from "components/DateTimeCell";
import { useEffect, useState } from "react";
import { fetchApi } from "services/api";
import { Link } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { Title } from "utils/title";
import ClaimReward from "./ClaimReward";

type ClaimedReward = {
    InvestorRewardClaimedID: number;
    InvestorID: number;
    InvestorName: string;
    ClaimedStatus: ClaimedStatus;
    RewardName: string;
    PointsUsed: number;
    ClaimedDateTime: Date;
}

enum ClaimedStatus {
    Cancelled = 90,
    Submitted = 100,
    Shipped = 120
}

type Response = {
    RewardsClaimed: ClaimedReward[];
}

const InvestorRewardsClaimed = () => {
    const [showClaimReward, setShowClaimReward] = useState(false);
    const [data, setData] = useState<ClaimedReward[]>([]);

    const refresh = () => {
        fetchApi("/api/Investor/RewardsClaimed")
            .then((data: Response) => {
                setData(data.RewardsClaimed);
            });
    }

    const updateStatus = (id: number, status: ClaimedStatus) => {
        if (status === ClaimedStatus.Cancelled && !window.confirm("Are you sure you want to cancel this reward?")) {
            return;
        }
        if (status === ClaimedStatus.Shipped && !window.confirm("Are you sure you want to mark this reward as shipped?")) {
            return;
        }
        const data = {
            InvestorRewardClaimedID: id,
            ClaimedStatus: status
        }
        fetchApi(`/api/Investor/UpdateRewardStatus/`, data, "POST")
            .then(() => {
                refresh();
            });
    }

    useEffect(() => {
        refresh();
    }, []);

    const statusBadge = (status: ClaimedStatus) => {
        switch (status) {
            case ClaimedStatus.Cancelled:
                return <span className="badge bg-danger">Cancelled</span>;
            case ClaimedStatus.Submitted:
                return <span className="badge bg-secondary">Submitted</span>;
            case ClaimedStatus.Shipped:
                return <span className="badge bg-success">Shipped</span>;
            default:
                return <span className="badge bg-secondary">N/A</span>;
        }
    }

    return <>
        <Title string="Rewards Claimed" />
        {showClaimReward && <ClaimReward CloseDialog={(x) => {
            x && refresh();
            setShowClaimReward(false);
        }} />}
        <Grid data={data}>
            <GridToolbar>
                <Button onClick={refresh} icon="refresh" title="Refresh" />
                <Button
                    icon="plus"
                    themeColor="primary"
                    onClick={() => setShowClaimReward(true)}
                >
                    Claim Reward
                </Button>
            </GridToolbar>
            <GridNoRecords>
                No rewards claimed.
            </GridNoRecords>
            <Column field="InvestorName" title="Investor" cell={(props) => <td>
                <Link to={`/Investor/${props.dataItem.InvestorID}/Profile`} style={{ color: '#007bff' }}>
                    {props.dataItem.InvestorName}
                </Link>
            </td>} />
            <Column field="RewardName" title="Reward" />
            <Column field="ClaimedStatus" title="Status" cell={(props) => <td>
                {statusBadge(props.dataItem.ClaimedStatus)}
            </td>} />
            <Column field="PointsUsed" title="Points Used" format={"{0:n0}"} />
            <Column field="ClaimedDateTime" title="Claimed Date" cell={DateTimeCell} />
            <Column title="Actions" cell={(props) => <td className="k-command-cell">
                {props.dataItem.ClaimedStatus === 100 && <Button themeColor="success" onClick={() => updateStatus(props.dataItem.InvestorRewardClaimedID, ClaimedStatus.Shipped)}>Ship</Button>}
                {props.dataItem.ClaimedStatus === 100 && <Button themeColor="error" onClick={() => updateStatus(props.dataItem.InvestorRewardClaimedID, ClaimedStatus.Cancelled)}>Cancel</Button>}
            </td>} />
        </Grid>
    </>;
}

export default InvestorRewardsClaimed;