import { GridCellProps } from '@progress/kendo-react-grid';
import { ContractorViewModel } from 'TypeGen/contractor-view-model';
import { Link } from 'react-router-dom';

export default function ContractorNameCell(isAdmin: boolean) {
    return (props: GridCellProps) => {
        const dataItem = props.dataItem as ContractorViewModel;
        return (
            <td>
                {isAdmin ? (
                    <Link to={`/Contractor/${dataItem.ContractorID}/Profile`} style={{ color: '#007bff' }}>
                        {dataItem.FullName}
                    </Link>
                ) : (
                    <span>{dataItem.FullName}</span>
                )}
            </td>
        );
    };
}
