import { Grid, GridToolbar, GridColumn as Column, GridCellProps, GridItemChangeEvent } from '@progress/kendo-react-grid';
import { useEffect, useState } from 'react';
import { MyCommandCell } from './MyCommandCell';
import { PercentCell } from './PercentCell';
import { getItems, insertItem, updateItem, deleteItem } from './service';
import { Button } from '@progress/kendo-react-buttons';
import { Title } from '../../../utils/title';

export type RegionCostViewModel = {
    RegionCostFactorID: number;
    Region: number;
    LightSqft: number;
    MediumSqft: number;
    HeavySqft: number;
    ClosingCostPercent: number;
    InvestorReturnPercent: number;
    FundReturnPercent: number;

    inEdit: boolean;
}

const RegionCost = () => {

    const editField = "inEdit";
    const [regionCosts, setRegionCosts] = useState<RegionCostViewModel[]>([]);
    const [isAdmin, setIsAdmin] = useState<boolean>();

    useEffect(() => {
        getItems().then((data) => { setRegionCosts(data.RegionCosts); setIsAdmin(data.IsAdmin) });
    }, []);

    const _commandCell = (props: GridCellProps) => (
        <MyCommandCell
            {...props}
            edit={_enterEdit}
            remove={_remove}
            add={_add}
            discard={_discard}
            update={_update}
            cancel={_cancel}
            editField={editField}
            disabled={!isAdmin}
        />
    );

    const _remove = (dataItem: RegionCostViewModel) => {
        deleteItem(dataItem);
        setRegionCosts(regionCosts.filter(x => x.RegionCostFactorID !== dataItem.RegionCostFactorID));
    };

    const _add = (dataItem: RegionCostViewModel) => {
        dataItem.inEdit = false;

        insertItem(dataItem)
            .then((data) => setRegionCosts(data.RegionCosts));
    };

    const _update = async (dataItem: RegionCostViewModel) => {
        dataItem.inEdit = false;
        await updateItem(dataItem);

        // basically forceUpdate()
        const data = regionCosts.map(item =>
            item.RegionCostFactorID === dataItem.RegionCostFactorID ? dataItem : item
        );
        setRegionCosts(data);
    };

    const _discard = (dataItem: RegionCostViewModel) => {
        const data = [...regionCosts];
        data.splice(0, 1)
        setRegionCosts(data);
    };

    const _cancel = (dataItem: RegionCostViewModel) => {
        getItems().then((response) => {
            const originalItem = response.RegionCosts.find(x => x.RegionCostFactorID === dataItem.RegionCostFactorID);
            if (originalItem) {
                const data = regionCosts.map(item =>
                    item.RegionCostFactorID === originalItem.RegionCostFactorID ? originalItem : item
                );
                setRegionCosts(data);
            }
        });
    };

    const _enterEdit = (dataItem: RegionCostViewModel) => {
        setRegionCosts(regionCosts.map(item =>
            item.RegionCostFactorID === dataItem.RegionCostFactorID ? { ...item, inEdit: true } : item
        ));
    };

    const _itemChange = (event: GridItemChangeEvent) => {
        const data = regionCosts.map(item =>
            item.RegionCostFactorID === event.dataItem.RegionCostFactorID ? { ...item, [event.field || '']: event.value } : item
        );

        setRegionCosts(data);
    }

    const _addNew = () => {
        const newDataItem = { inEdit: true } as RegionCostViewModel;
        setRegionCosts([newDataItem, ...regionCosts]);
    }

    return <>
        <Title string="Region Cost" />
        <Grid
            scrollable="none"
            data={regionCosts}
            onItemChange={_itemChange}
            editField={editField}
        >
            <GridToolbar>
                <Button
                    themeColor="primary"
                    disabled={!isAdmin}
                    onClick={_addNew}
                >
                    Add Region
                </Button>
            </GridToolbar>
            <Column field="Region" editor="numeric" />
            <Column field="LightSqft" title="Light" editor="numeric" format="{0:c}" />
            <Column field="MediumSqft" title="Medium" editor="numeric" format="{0:c}" />
            <Column field="HeavySqft" title="Heavy" editor="numeric" format="{0:c}" />
            <Column field="ClosingCostPercent" title="CC %" editor="numeric" cell={PercentCell} format="{0:p}" />
            <Column field="InvestorReturnPercent" title="Investor %" editor="numeric" cell={PercentCell} format="{0:p}" />
            <Column field="FundReturnPercent" title="Fund %" editor="numeric" cell={PercentCell} format="{0:p}" />
            <Column cell={_commandCell} width="200px" />
        </Grid>
    </>;
}

export default RegionCost;
