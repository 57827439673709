import { Button } from '@progress/kendo-react-buttons';
import { ConstructionProjectViewModel } from 'TypeGen/construction-project-view-model';
import moment from 'moment';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fetchApi } from 'services/api';
import { flattenTasks, transformTasks } from 'utils/constructionProject';

type Props = {
    constructionProject?: ConstructionProjectViewModel;
    propertyId: number;
};

const PropertyConstructionProjectSummary = ({
    constructionProject,
    propertyId,
}: Props) => {
    return (
        <>
            <p className="property-panel-title">
                Construction Project Management
            </p>
            {constructionProject == null ? (
                <PropertyConstructionProjectSummaryEmpty
                    propertyId={propertyId}
                />
            ) : (
                <PropertyConstructionProjectSummaryBody
                    constructionProject={constructionProject}
                />
            )}
        </>
    );
};

const PropertyConstructionProjectSummaryEmpty = ({
    propertyId,
}: {
    propertyId: number;
}) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const createProject = async () => {
        setLoading(true);
        const res: ConstructionProjectViewModel = await fetchApi(
            `api/Property/ConstructionProject/${propertyId}`,
            {},
            'post',
        );
        navigate(`/Construction/Project/${res.ConstructionProjectID}`);
    };
    return (
        <>
            <Button
                themeColor="primary"
                onClick={createProject}
                disabled={loading}>
                {!loading
                    ? 'Create construction project'
                    : 'Creating construction project...'}
            </Button>
        </>
    );
};

const PropertyConstructionProjectSummaryBody = ({
    constructionProject,
}: {
    constructionProject: ConstructionProjectViewModel;
}) => {
    if (constructionProject == null) {
        return null;
    }
    const tasks = flattenTasks(transformTasks(constructionProject.Tasks)).sort(
        (a, b) => Number(a.start) - Number(b.start),
    );
    const childTasks = tasks.filter(
        (task) => (task.children?.length ?? 0) === 0,
    );
    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Task name</th>
                        <th scope="col">Progress complete (percent)</th>
                        <th scope="col">Planned start date</th>
                        <th scope="col">Planned end date</th>
                    </tr>
                </thead>
                <tbody>
                    {childTasks.map((task) => {
                        return (
                            <tr key={task.id}>
                                <td>{task.title}</td>
                                <td>{task.percentComplete * 100}%</td>
                                <td>
                                    {moment(task.start.toDateString()).format(
                                        'D MMM YYYY',
                                    )}
                                </td>
                                <td>
                                    {moment(task.start.toDateString()).format(
                                        'D MMM YYYY',
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <Link
                to={`/Construction/Project/${constructionProject.ConstructionProjectID}`}>
                Manage Project
            </Link>
        </>
    );
};

export default PropertyConstructionProjectSummary;
