import * as React from 'react';
import Moment from 'moment';
import { fetchApi } from '../../services/api';
import JsonPretty from '../../components/JsonPretty';
import { Dialog } from '@progress/kendo-react-dialogs';

interface Props {
    CloseDialog: () => void;
    PropertyID: number,
}

type State = {
    Logs: LogViewModel[];
}

type LogViewModel = {
    UserName: string;
    DateTime: Date;
    OldValues: string;
    NewValues: string;
}

export class AuditLogs extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            Logs: []
        }
    }

    public componentDidMount() {
        fetchApi(`/api/Property/Logs/${this.props.PropertyID}`)
            .then((response: { Logs: LogViewModel[] }) => {
                this.setState({ Logs: response.Logs });
            })
    }

    public render() {
        return (
            <Dialog title="Property Logs" onClose={this.props.CloseDialog} width={1024} height={650}>
                <table className="table">
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Timestamp</th>
                            <th>Old Values</th>
                            <th>New Values</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.Logs.map((log, index) => {
                            return <tr key={index}>
                                <td>{log.UserName}</td>
                                <td>{Moment.utc(log.DateTime).local().format("YYYY-MM-DD HH:mm:ss")}</td>
                                <td><JsonPretty json={log.OldValues} /></td>
                                <td><JsonPretty json={log.NewValues} /></td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </Dialog>
        );
    }
}
