import * as React from 'react';
import './imageViewer.css';
import ImageView from './ImageView';

export type imgDetail = {
    src: string;
    caption?: string;
    title?: string;
}

type Props = {
    isPopup: boolean;
    images: imgDetail[];
    CloseDialog: () => void;
}

type State = {
    image: imgDetail | null;
    openEditDialog: boolean;
}

const rotate_right = {
    msTransform: "rotate(7deg)", /* IE 9 */
    WebkitTransform: "rotate(7deg)", /* Safari */
    transform: "rotate(7deg)",
} as React.CSSProperties;

const rotate_left = {
    msTransform: "rotate(-8deg)", /* IE 9 */
    WebkitTransform: "rotate(-8deg)", /* Safari */
    transform: "rotate(-8deg)",
} as React.CSSProperties;


export default class ImageViewer extends React.Component<Props, State> {
    public static defaultProps = {
        isPopup: false
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            image: null,
            openEditDialog: false
        }
    }

    public render() {
        return (<>
            {this.props.isPopup && <div style={{ zIndex: 10002, backgroundColor: "rgba(0,0,0,0.8)", position: "fixed", width: "100%", height: "100%", left: "0px", top: "0px" }} onClick={() => { this.props.CloseDialog(); }}>
                <div style={{ zIndex: 10002, width: "100%", height: "100%" }}>
                    <div style={{ height: '100%', overflow: "auto" }}>
                        <div style={{ margin: "auto", width: "90%", color: "white", marginBottom: "65px", marginTop: "8px" }}>
                            <div style={{ float: "left" }}>
                                <h2> Property Images</h2>
                            </div>
                            <div style={{ float: "right" }}>
                                <span
                                    className="k-i-close k-icon glow-button"
                                    style={{ fontSize: 32 }} onClick={() => { this.props.CloseDialog(); }}
                                />
                            </div>
                        </div>
                        <main className="grid" style={{ width: '88%', margin: 'auto' }}>
                            {
                                this.props.images.map((image, index) => {
                                    let rotateStyle = index % 2 === 0 ? rotate_right : rotate_left;
                                    return (

                                        <div className="polaroid" style={{ ...rotateStyle }} key={index}>
                                            <p className="title">{image.title}</p>
                                            <img className="img" src={image.src}
                                                alt=''
                                                onClick={(e) => { e.stopPropagation(); this.setState({ image, openEditDialog: true }) }}
                                            />
                                            <p className="caption">{image.caption === null || image.caption === "" ? "\u00a0" : image.caption}</p>
                                        </div>

                                    );
                                })
                            }
                        </main>

                    </div>
                </div>
            </div>}

            {!this.props.isPopup && <div style={{ width: "100%", height: "100%", left: "0px", top: "0px" }} onClick={() => { this.props.CloseDialog(); }}>
                <div style={{ zIndex: 10002, width: "100%", height: "100%" }}>
                    <div style={{ height: '100%', overflow: "auto" }}>
                        <main className="grid" style={{ width: '100%' }}>
                            {
                                this.props.images.map((image, index) => {
                                    return (

                                        <div className="polaroid" key={index}>
                                            <p style={{ marginBottom: "8px", fontWeight: "bold", fontSize: 15 }}>{image.title}</p>
                                            <img className="img" src={image.src}
                                                alt=''
                                                onClick={(e) => { e.stopPropagation(); this.setState({ image, openEditDialog: true }) }}
                                            />
                                            <p className="caption">{image.caption === null || image.caption === "" ? "\u00a0" : image.caption}</p>
                                        </div>
                                    );
                                })
                            }
                        </main>
                    </div>
                </div>
            </div>}

            {this.state.openEditDialog && this.state.image &&
                <ImageView
                    Image={this.state.image}
                    CloseDialog={() => { this.setState({ openEditDialog: false }) }}
                />
            }
        </>);
    }
}
