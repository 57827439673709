import * as React from 'react';
import './imageViewer.css';
import { imgDetail } from './ImageViewer';

type Props = {
    CloseDialog: () => void;
    Image: imgDetail;
}

type State = {
    isLoading: boolean;
}

export default class ImageView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false
        }
    }

    public render() {
        return (<>
            <div style={{
                zIndex: 10003, backgroundColor: "rgba(0,0,0,0.9)", position: "fixed", width: "100%",
                height: "100%", left: "0px", top: "0px", overflow: "auto"
            }} onClick={() => { this.props.CloseDialog(); }}>
                <div style={{ zIndex: 10003, width: "100%", height: "100%" }}>
                    <div className="centerAbsoluteElementHorizontallyAndVertically">
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <span
                                className="k-i-close k-icon glow-button"
                                style={{ fontSize: 32, color: "white" }} onClick={(e) => { e.stopPropagation(); this.props.CloseDialog(); }}
                            />
                        </div>
                        <div className="polaroid">
                            <p className="title">{this.props.Image.title}</p>
                            <img className="responsive"
                                src={this.props.Image.src}
                                //width="600"
                                //height="600"
                                //style={{maxWidth:450, maxHeight:450}}
                                //style={{							
                                //	width: '1000px',
                                //	height: "1000px"
                                //}}
                                // width={width} 
                                // height={height}
                                alt=''
                                onClick={(e) => { e.stopPropagation(); }} />
                                <p className="caption">{this.props.Image.caption === null || this.props.Image.caption === "" ? "\u00a0" : this.props.Image.caption}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>);
    }
}