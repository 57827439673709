import { useRef, useState } from 'react';
import { fetchApi } from '../../services/api';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label, Error } from '@progress/kendo-react-labels';
import { PropertyViewModel } from '../../utils/property';
import { isNullOrEmptyValidator, isNullValidator } from '../../utils/generic';
import { Window } from '@progress/kendo-react-dialogs';
import { NumericTextBox, RadioGroup, Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';

type Props = {
    property: PropertyViewModel;
    refresh: () => void;
}

const PropertyProfileAddOffer = (props: Props) => {

    const buttonRef = useRef<HTMLButtonElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [reasonForRejecting, setReasonForRejecting] = useState('');
    const [price, setPrice] = useState<{ value: number | null, error: string }>({ value: null, error: '' });
    const [closeOfEscrowDays, setCloseOfEscrowDays] = useState<{ value: number | null, error: string }>({ value: null, error: '' });
    const [earnestMoneyDeposit, setEarnestMoneyDeposit] = useState<{ value: number | null, error: string }>({ value: null, error: '' });
    const [earnestMoneyDepositDays, setEarnestMoneyDepositDays] = useState<{ value: number | null, error: string }>({ value: null, error: '' });
    const [title, setTitle] = useState<{ value: number | null, error: string }>({ value: null, error: '' });
    const [escrow, setEscrow] = useState<{ value: number | null, error: string }>({ value: null, error: '' });

    const [closingCostCredit, setClosingCostCredit] = useState<{ value: boolean | null, error: string }>({ value: null, error: '' });
    const [closingCostCreditAmount, setClosingCostCreditAmount] = useState<{ value: number | null, error: string }>({ value: null, error: '' });
    const [clientFullName, setClientFullName] = useState({ value: '', error: '' });
    const [financeType, setFinanceType] = useState<{ value: number | null, error: string }>({ value: null, error: '' });

    const [escalationClause, setEscalationClause] = useState<{ value: boolean | null, error: string }>({ value: null, error: '' });
    const [escalationClauseReason, setEscalationClauseReason] = useState({ value: '', error: '' });
    const [notes, setNotes] = useState('');

    const yesNoRadioOptions = [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' }
    ];

    const buyerSellerOptions = [
        { label: 'Buyer', value: 0 },
        { label: 'Seller', value: 1 },
        { label: '50/50', value: 2 }
    ];

    const financeTypeOptions = [
        { label: 'Unknown', value: 0 },
        { label: 'VA', value: 10 },
        { label: 'FHA', value: 20 },
        { label: 'Conventional', value: 30 },
        { label: 'Cash', value: 40 }
    ];


    const _load = () => {
        fetchApi(`/api/Property/PropertyOffer/${props.property.PropertyID}`)
        .then((data: {
          Price: number,
          CloseOfEscrowDays: number,
          EarnestMoneyDeposit: number,
          EarnestMoneyDepositDays: number,
          ClosingCostCredit: boolean | null,
          ClosingCostCreditAmount: number | null,
          ClientFullName: string,
          Title: number | null,
          Escrow: number | null,
          FinanceType: number | null,
          EscalationClause: boolean | null,
          EscalationClauseReason: string,
          ReasonForRejecting: string,
        }) => {
          setLoading(false);

          setPrice({ value: data.Price > 0 ? data.Price : null, error: '' });
          setCloseOfEscrowDays({ value: data.CloseOfEscrowDays > 0 ? data.CloseOfEscrowDays : null, error: '' });
          setEarnestMoneyDeposit({ value: data.EarnestMoneyDeposit > 0 ? data.EarnestMoneyDeposit : null, error: '' });
          setEarnestMoneyDepositDays({ value: data.EarnestMoneyDepositDays > 0 ? data.EarnestMoneyDepositDays : null, error: '' });
          setClosingCostCredit({ value: data.ClosingCostCredit, error: '' });
          setClosingCostCreditAmount({ value: data.ClosingCostCreditAmount, error: '' });
          setClientFullName({ value: data.ClientFullName, error: ''});
          setTitle({ value: data.Title, error: '' });
          setEscrow({ value: data.Escrow, error: '' });
          setFinanceType({ value: data.FinanceType, error: ''});
          setEscalationClause({ value: data.EscalationClause, error: '' });
          setEscalationClauseReason({ value: data.EscalationClauseReason || '', error: '' });
          setReasonForRejecting(data.ReasonForRejecting || '');
        });
    }

    const _save = () => {
        let priceError = isNullValidator(price.value, 'Enter Offer Price.');
        const closeOfEscrowDaysError = isNullValidator(closeOfEscrowDays.value, 'Enter COE Days.');
        const earnestMoneyDepositError = isNullValidator(earnestMoneyDeposit.value, 'Enter EMD Amount.');
        const earnestMoneyDepositDaysError = isNullValidator(earnestMoneyDepositDays.value, 'Enter EMD Days.');
        const titleError = isNullValidator(title.value, 'Select Title.');
        const escrowError = isNullValidator(escrow.value, 'Select Escrow.');

        const financeError = isNullValidator(financeType.value, 'Select Finance.');
        const clientFullNameError = isNullOrEmptyValidator(clientFullName.value, 'Enter Client Name.');

        const closingCostCreditError = isNullValidator(closingCostCredit.value, 'Select Closing Cost Credit');
        let closingCostCreditAmountError = '';
        if (closingCostCredit.value === true) {
            closingCostCreditAmountError = isNullValidator(closingCostCreditAmount.value, 'Enter Closing Cost Credit Amount.');
        }

        const escalationClauseError = isNullValidator(escalationClause.value, 'Select Escalation Clause.');
        let escalationClauseReasonError = '';
        if (escalationClause.value) {
            escalationClauseReasonError = isNullOrEmptyValidator(escalationClauseReason.value, 'Enter Escalation Reason.');
        }

        if (priceError || closeOfEscrowDaysError || earnestMoneyDepositError || earnestMoneyDepositDaysError || titleError || escrowError
            || escalationClauseError || escalationClauseReasonError || closingCostCreditError || closingCostCreditAmountError || financeError || clientFullNameError) {

            setClientFullName({ ...clientFullName, error: clientFullNameError });
            setFinanceType({ ...financeType, error: financeError });
            setClosingCostCredit({ ...closingCostCredit, error: closingCostCreditError });
            setClosingCostCreditAmount({ ...closingCostCreditAmount, error: closingCostCreditAmountError });

            setPrice({ ...price, error: priceError });
            setCloseOfEscrowDays({ ...closeOfEscrowDays, error: closeOfEscrowDaysError });
            setEarnestMoneyDeposit({ ...earnestMoneyDeposit, error: earnestMoneyDepositError });
            setEarnestMoneyDepositDays({ ...earnestMoneyDepositDays, error: earnestMoneyDepositDaysError });
            setTitle({ ...title, error: titleError });
            setEscrow({ ...escrow, error: escrowError });

            setEscalationClause({ ...escalationClause, error: escalationClauseError });
            setEscalationClauseReason({ ...escalationClauseReason, error: escalationClauseReasonError });
            return;
        }

        setLoading(true);

        const data = {
            PropertyID: props.property.PropertyID,
            FinanceType: financeType.value,
            ClientFullName: clientFullName.value,
            ClosingCostCredit: closingCostCredit.value,
            ClosingCostCreditAmount: closingCostCreditAmount.value || 0,
            Price: price.value,
            CloseOfEscrowDays: closeOfEscrowDays.value,
            EarnestMoneyDeposit: earnestMoneyDeposit.value,
            EarnestMoneyDepositDays: earnestMoneyDepositDays.value,
            Title: title.value,
            Escrow: escrow.value,
            EscalationClause: escalationClause.value,
            EscalationClauseReason: escalationClauseReason.value || '',
            Notes: notes || '',
        }
        fetchApi('/api/Property/PropertyOffer', data, 'POST')
            .then((response: { Success: boolean, ErrorMessage: string }) => {
                setLoading(false);
                if (response.Success) {
                    setVisible(false);
                    props.refresh();
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                setLoading(false);
                alert("Unable to add offer!");
            });
    }
    return <>
        {visible && <Window
            title="Add Offer" modal
            onClose={() => setVisible(false)}
            initialWidth={Math.min(window.innerWidth, 600)} initialHeight={650}
            top={(buttonRef.current?.offsetTop ?? 325) - 325}
        >
            <form className="k-form k-form-md p-0">
                {reasonForRejecting && <p className="text-danger">{reasonForRejecting}</p>}
                <FieldWrapper>
                    <Label>Requires Closing Cost Credit</Label>
                    <RadioGroup layout="horizontal" data={yesNoRadioOptions} value={closingCostCredit.value?.toString()} onChange={(e) => setClosingCostCredit({ value: e.value === 'true', error: '' })} />
                    <Error>{closingCostCredit.error}</Error>
                </FieldWrapper>
                {closingCostCredit.value === true && <FieldWrapper>
                    <NumericTextBox
                        width="100%"
                        label="Closing Cost Credit Amount"
                        min={0}
                        step={5000}
                        format="c0"
                        value={closingCostCreditAmount.value}
                        onChange={(e) => setClosingCostCreditAmount({ value: e.value || 0, error: '' })}
                    />
                    <Error>{closingCostCreditAmount.error}</Error>
                </FieldWrapper>}

                <FieldWrapper>
                    <Label>Finance Type</Label>
                    <RadioGroup layout="horizontal" data={financeTypeOptions} value={financeType.value} onChange={(e) => setFinanceType({ value: e.value, error: '' })} />
                    <Error>{financeType.error}</Error>
                </FieldWrapper>

                <FieldWrapper>
                    <Input
                        label="Client Name"
                        value={clientFullName.value}
                        onChange={e => setClientFullName({ value: e.value, error: '' })}
                    />
                    <Error>{clientFullName.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <NumericTextBox
                        width="100%"
                        label="Offer Price"
                        min={0}
                        step={5000}
                        format="c0"
                        value={price.value}
                        onChange={(e) => setPrice({ value: e.value || 0, error: '' })}
                    />
                    <Error>{price.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <NumericTextBox
                        label="Close Of Escrow Days"
                        value={closeOfEscrowDays.value}
                        onChange={e => setCloseOfEscrowDays({ value: e.value || 0, error: '' })}
                        min={0}
                    />
                    <Error>{closeOfEscrowDays.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <NumericTextBox
                        label="Earnest Money Deposit Amount"
                        value={earnestMoneyDeposit.value}
                        onChange={(e) => setEarnestMoneyDeposit({ value: e.value || 0, error: '' })}
                        format="c0"
                        min={0}
                    />
                    <Error>{earnestMoneyDeposit.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <NumericTextBox
                        label="Earnest Money Deposit Days"
                        value={earnestMoneyDepositDays.value}
                        onChange={e => setEarnestMoneyDepositDays({ value: e.value || 0, error: '' })}
                        min={0}
                    />
                    <Error>{earnestMoneyDepositDays.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Title Fees Paid By</Label>
                    <RadioGroup layout="horizontal" data={buyerSellerOptions} value={title.value} onChange={(e) => setTitle({ value: e.value, error: '' })} />
                    <Error>{title.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Escrow Fees Paid By</Label>
                    <RadioGroup layout="horizontal" data={buyerSellerOptions} value={escrow.value} onChange={(e) => setEscrow({ value: e.value, error: '' })} />
                    <Error>{escrow.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Escalation Clause</Label>
                    <RadioGroup layout="horizontal" data={yesNoRadioOptions} value={escalationClause.value?.toString()} onChange={(e) => setEscalationClause({ value: e.value === 'true', error: '' })} />
                    <Error>{escalationClause.error}</Error>
                </FieldWrapper>
                {escalationClause.value === true && <FieldWrapper>
                    <Input
                        label="Escalation Clause Reason"
                        value={escalationClauseReason.value}
                        onChange={e => setEscalationClauseReason({ value: e.value, error: '' })}
                    />
                    <Error>{escalationClauseReason.error}</Error>
                </FieldWrapper>}
                <FieldWrapper>
                    <Input
                        label='Offer Notes'
                        value={notes}
                        onChange={text => setNotes(text.value)}
                    />
                </FieldWrapper>
            </form>
            <br />
            <div className="text-center">
                <Button onClick={() => setVisible(false)}>Cancel</Button>
                &nbsp;&nbsp;
                <Button themeColor="primary" disabled={loading} onClick={_save}>Add Offer</Button>
            </div>
        </Window>}
        <br />
        <button ref={buttonRef} type="button" className="btn btn-primary" onClick={() => {
            _load();
            setVisible(true);
        }}>Add New Offer</button>
    </>
}

export default PropertyProfileAddOffer;

