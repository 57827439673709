import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import * as React from 'react';
import { fetchApi } from '../../services/api';
import LoadingPanel from '../LoadingPanel';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';

interface Props {
    CloseDialog: (refresh: boolean) => void;
}

type State = {
    title: string;
    body: string;
    link: string;
    scheduleDateTime: Date | null;
    isLoading: boolean;
}

export default class AgentNotifyAll extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            title: '',
            body: '',
            link: '',
            scheduleDateTime: null,
            isLoading: false,
        }

        this.sendMessage = this.sendMessage.bind(this);
    }

    public render() {
        return (
            <Dialog title="Send Push Notification To ALL Agents" onClose={() => this.props.CloseDialog(false)} width={1024}>
                {this.state.isLoading && <LoadingPanel />}
                <div className="container">
                    <fieldset>
                        <p>This message will be sent to all agent devices.</p>
                        <Input
                            autoFocus
                            style={{ width: "100%" }}
                            label="Notification Title"
                            value={this.state.title}
                            onChange={(e) => this.setState({ title: e.value })}
                            maxLength={65}
                        />
                        <Input
                            style={{ width: "100%" }}
                            label="Notification Body"
                            value={this.state.body}
                            onChange={(e) => this.setState({ body: e.value })}
                            maxLength={105}
                        />
                        <Input
                            style={{ width: "100%" }}
                            label="Website URL (Optional)"
                            value={this.state.link}
                            onChange={(e) => this.setState({ link: e.value })}
                            type="url"
                            maxLength={2083}
                        />
                        <DateTimePicker
                            width="100%"
                            label="Schedule Date & Time (Optional)"
                            value={this.state.scheduleDateTime}
                            onChange={(e) => this.setState({ scheduleDateTime: e.value })}
                        />
                    </fieldset>
                </div>
                <DialogActionsBar>
                    <Button themeColor="primary" onClick={(e) => {
                        e.preventDefault();
                        if (window.confirm("Are you sure?")) {
                            this.sendMessage();
                        }
                    }}>{this.state.scheduleDateTime ? "Schedule" : "Send"} Notification</Button>
                </DialogActionsBar>
            </Dialog>
        );
    }

    private sendMessage() {
        if (!this.state.title && !this.state.body) {
            alert("You must enter a title or body!");
            return;
        }

        this.setState({ isLoading: true });

        const data = {
            title: this.state.title,
            body: this.state.body,
            scheduledDateTime: this.state.scheduleDateTime,
            link: this.state.link
        };
        fetchApi('/api/Agent/NotifyAll', data, 'POST')
            .then(() => {
                alert("Successfully Sent!");
                this.setState({ isLoading: false });
                this.props.CloseDialog(true);
            })
            .catch(() => {
                alert("Unable to send!");
                this.setState({ isLoading: false });
            })
    }
}
