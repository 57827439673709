import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { memo, useState } from 'react';
import { fetchApi } from '../../services/api';
import { isNullOrEmptyValidator, stateValidator, zipValidator } from '../../utils/generic';
import { useNavigate } from 'react-router-dom';
import { Title } from '../../utils/title';

export type GeoAddress = {
    UnitNumber: string;
    StreetNumber: string;
    StreetName: string;
    City: string;
    State: string;
    Zip: string;
};

const SubmitScreen = () => {

    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState<GeoAddress>({
        StreetNumber: '',
        StreetName: '',
        UnitNumber: '',
        City: '',
        State: '',
        Zip: '',
    });

  const _onSubmitPressed = (addToQueue: boolean) => {

    const streetNumberError = isNullOrEmptyValidator(address.StreetNumber, 'Street Number cannot be empty.');
    const streetNameError = isNullOrEmptyValidator(address.StreetName, 'Street Name cannot be empty.');
    const cityError = isNullOrEmptyValidator(address.City, 'City cannot be empty.');
    const stateError = stateValidator(address.State);
    const zipError = zipValidator(address.Zip);

    if (streetNumberError || streetNameError || cityError || stateError || zipError) {
      alert(`Address Error: ${[streetNumberError, streetNameError, cityError, stateError, zipError].filter(x => x).join("\r\n")}`);
      return;
    }

    setLoading(true);

    const data = {
        StreetNumber: address.StreetNumber,
        StreetName: address.StreetName,
        UnitNumber: address.UnitNumber,
        City: address.City,
        State: address.State,
        Zip: address.Zip,

        // Add to queue
        Force: addToQueue,
    }

    fetchApi('/api/Property/SubmitProperty', data, 'POST')
        .then((data: {
            Rejected: boolean,
            Exists: boolean,
            AddressNotFound: boolean,
            RegionNotFound: boolean,
            Success: boolean,
            ErrorMessage: string,
            PropertyID: number,
            Links: Array<{ Rel: string }>
        }) => {
            setLoading(false);
            if (data.RegionNotFound) {
                alert('We are currently not accepting properties from this region!');
                return;
            }
            if (data.AddressNotFound) {
                alert('Address was not found!');
                return;
            }
            if (data.Rejected) {
                alert('Address was previously rejected!');
                return;
            }
            if (data.Exists) {
                if (window.confirm('Address already exists! Add to Queue?')) {
                    _onSubmitPressed(true);
                }
                return;
            }
            if (data.Success) {
                // Clear Form
                setAddress({
                    StreetNumber: '',
                    StreetName: '',
                    UnitNumber: '',
                    City: '',
                    State: '',
                    Zip: '',
                });
                navigate(`/Property/Profile/${data.PropertyID}`);
            } else {
                alert(data.ErrorMessage);
            }
        }).catch(() => {
            setLoading(false);
            alert('Unknown Error!');
        });
  }

  return (
    <div className="k-form k-form-md">
        <Title string="Submit a Property" />
        <h3>Submit a Property</h3>
        <Input
            label="Street Number"
            type="number"
            maxLength={7}
            onChange={e => setAddress(prevState => {
                return { ...prevState, StreetNumber: e.value }
            })}
            value={address.StreetNumber}
        />
        <Input
            label="Street Name"
            maxLength={60}
            onChange={e => setAddress(prevState => {
                return { ...prevState, StreetName: e.value }
            })}
            value={address.StreetName}
        />
        <Input
            label="Unit # (Optional)"
            type="number"
            maxLength={5}
            value={address.UnitNumber}
            onChange={e => setAddress(prevState => {
                return { ...prevState, UnitNumber: e.value }
            })}
        />
        <Input
            label="City"
            maxLength={50}
            onChange={e => setAddress(prevState => {
                return { ...prevState, City: e.value }
            })}
            value={address.City}
        />
        <Input
            label="State Code"
            maxLength={2}
            autoCapitalize="characters"
            onChange={e => setAddress(prevState => {
                return { ...prevState, State: e.value }
            })}
            value={address.State}
        />
        <Input
            label="Zip"
            maxLength={7}
            onChange={e => setAddress(prevState => {
                return { ...prevState, Zip: e.value }
            })}
            value={address.Zip}
        />
        <br />
        <Button type="button" themeColor="primary" disabled={loading} onClick={() => _onSubmitPressed(false)}>
            Submit Property
        </Button>
    </div>
  )
}

export default memo(SubmitScreen);