import * as Moment from 'moment';
import { GridCellProps } from "@progress/kendo-react-grid";

export const DateTimeCell = (props: GridCellProps) => {
  if (!props.field || props.rowType !== 'data') return null;
  return (
    <td>
      {Moment.utc(props.dataItem[props.field]).local().format("MM/DD/YYYY HH:mm")}
    </td>
  );
}
