import { Title } from "utils/title";

const Home = () => {
    return <>
      <Title string="Home" />
      <br />
      <div className="container">
        <div className="jumbotron">
          <h1 className="display-4">Welcome to Belwood Investments!</h1>
          <p className="lead">Choose an item from the top menu to get started.</p>
        </div>
      </div>
    </>;
}

export default Home;