import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { openWindowPerc } from '../../utils/openWindow';
import { PropertyViewModel } from '../../utils/property';
import { Popup, PopupPropsContext } from '@progress/kendo-react-popup';
import PropertyNotes from './PropertyNotes';
import OutsideAlerter from '../OutsideAlerter';
import { Link } from 'react-router-dom';

export const PropertyAddressCell = (props: GridCellProps) => {
    const anchor = React.useRef<HTMLSpanElement>(null);
    const wrapper = React.useRef<HTMLSpanElement | null>(null);
    const [showPopover, setShowPopover] = React.useState(false);

    const dataItem = props.dataItem as PropertyViewModel;
    const address = `${dataItem.Address.StreetAddress} ${dataItem.Address.City}, ${dataItem.Address.State} ${dataItem.Address.Zip}`;

    return (
        <td>
            <Link to={`/Property/Profile/${dataItem.PropertyID}`} style={{ color: '#007bff' }}>
                {dataItem.Address.StreetAddress}
            </Link>
            &nbsp;
            <div style={{ float: "right" }}>
                {dataItem.HasNote && <>
                    <span ref={wrapper}>
                        <PopupPropsContext.Provider
                            value={(props) => ({ ...props, appendTo: wrapper.current })}
                        >
                            <span className="k-icon k-i-track-changes-enable me-1" style={{ fontSize: "20px", cursor: "pointer" }} ref={anchor} onClick={() =>  setShowPopover(!showPopover)}></span>
                            <Popup
                                show={showPopover}
                                anchor={anchor && anchor.current}
                                anchorAlign={{ horizontal: "right", vertical: 'bottom' }}
                                popupAlign={{ horizontal: "left", vertical: 'center' }}
                            >
                                <OutsideAlerter style={{ minHeight: '100px', minWidth: '125px' }} toggle={() => setShowPopover(!showPopover)}>
                                    <div className="ps-2 pe-2"> {/*leave for loading icon*/}
                                        <PropertyNotes propertyID={dataItem.PropertyID} />
                                    </div>
                                </OutsideAlerter>
                            </Popup>
                        </PopupPropsContext.Provider>
                    </span>
                </>}
                <img src="/images/googleMaps.png" alt="" style={{ width: "16px", cursor: "pointer" }}
                    onClick={() => {
                        openWindowPerc(`https://www.google.com/maps/place/${address}`, .80, .80);
                    }} />
            </div>
        </td>
    );
}