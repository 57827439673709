import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { UserViewModel } from '../../utils/user';
import { Link } from 'react-router-dom';

export default function UserNameCell(isAdmin: boolean) {
    return (props: GridCellProps) => {
        const dataItem = props.dataItem as UserViewModel;
        return (
            <td>
                {isAdmin ?
                    <Link to={`/user/${dataItem.Id}/userprofile`} style={{ color: '#007bff' }}>
                        {dataItem.UserName}
                    </Link> :
                    <span>{dataItem.UserName}</span>}
            </td >
        );
    }
}