
import { createContext, Dispatch, useReducer } from 'react';

export const AUTH_STORAGE_KEY = 'ACCESS_TOKEN';

export interface AuthenticationState {
    token: string;
    redirectUrl: string;
}

interface GetUserTokenAction {
    type: 'GET_USER_TOKEN';
}

interface SaveUserTokenAction {
    type: 'SAVE_USER_TOKEN';
    token: string;
}

interface ClearUserTokenAction {
    type: 'CLEAR_USER_TOKEN';
    redirectUrl: string;
}

type KnownAction = GetUserTokenAction | SaveUserTokenAction | ClearUserTokenAction;

export const reducer = (state: AuthenticationState, action: KnownAction) => {
    switch (action.type) {
        case 'SAVE_USER_TOKEN':
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem(AUTH_STORAGE_KEY, action.token);
            }
            return {
                ...state,
                token: action.token,
            };
        case 'CLEAR_USER_TOKEN':
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem(AUTH_STORAGE_KEY, '');
            }
            return {
                token: '',
                redirectUrl: action.redirectUrl === '/User/Login' ? state.redirectUrl : action.redirectUrl
            };
        case 'GET_USER_TOKEN':
        default:
            return state;
    }
};

const initialState: AuthenticationState = {
    token: (typeof localStorage !== 'undefined') ? localStorage.getItem(AUTH_STORAGE_KEY) || '' : '',
    redirectUrl: ''
};

export const AuthorizationContext = createContext<{
    state: AuthenticationState;
    dispatch: Dispatch<KnownAction>;
    getUserToken: (email: string, password: string) => void;
    clearUserToken: (redirectUrl: string) => void;
  }>({
    state: initialState,
    dispatch: () => null,
    getUserToken: () => null,
    clearUserToken: () => null
  });

type Props = {
    children: React.ReactNode;
}

export const AuthorizationProvider: React.FC<Props> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const getUserToken = (email: string, password: string) => {
        fetch(`api/User/Login`, {
            body: JSON.stringify({ email, password }),
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST',
            redirect: 'error'
        })
            .then((response) => {
                if (response.status === 401) {
                    alert('Invalid Username/Password!');
                } else if (!response.ok) {
                    alert('Unknown Login Error!');
                } else {
                    response.json().then((data: { id: string, token: string  }) => {
                        dispatch({ type: 'SAVE_USER_TOKEN', token: data.token });
                        localStorage.setItem('USER_ID', data.id);
                    });
                }
            });

        dispatch({ type: 'GET_USER_TOKEN' });
    }

    const clearUserToken = (redirectUrl: string) => {
        dispatch({ type: 'CLEAR_USER_TOKEN', redirectUrl });
    }

    return (
        <AuthorizationContext.Provider value={{state, dispatch, getUserToken, clearUserToken}}>
            {children}
        </AuthorizationContext.Provider>
    )
}
