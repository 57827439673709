type Props = {
    children: JSX.Element;
};

const CenterDivPanel = ({children}: Props) => {
    return (
        <div style={{
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "98%",
            height: "90%",
            position: "absolute",
            zIndex: 100
        }}>
            {children}
        </div>
    );
}

export default CenterDivPanel;
