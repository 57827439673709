import { GridCellProps } from "@progress/kendo-react-grid";
import { PropertyViewModel, getPropertyStatus } from '../../utils/property';

export const PropertyStatusCell = (props: GridCellProps) => {
    const dataItem = props.dataItem as PropertyViewModel;
    return (
        <td>
            {getPropertyStatus(dataItem.Status)}
        </td>
    );
}