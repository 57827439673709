import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { formatCurrency, IdTextPair, JsonResponse } from '../../utils/generic';
import { getPropertyInvestmentType, getPropertyInvestorStatus, PropertyInvestor } from '../../utils/property';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { NumericTextBox, TextArea } from '@progress/kendo-react-inputs';
import { fetchApi } from '../../services/api';
import InvestorAvatar from '../Investor/InvestorAvatar';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { PropertyInvestorStatus } from 'TypeGen/property-investor-status';

type Props = {
    propertyID: number;
    item: PropertyInvestor;
    firstItem: boolean;
    refresh: () => void;
}

const addOrdinal = (num: number): string => {

    if (num === -1) return "Skip";
    if (num <= 0) return String(num);

    switch (num % 100) {
        case 11:
        case 12:
        case 13:
            return num + "th";
    }

    switch (num % 10) {
        case 1:
            return num + "st";
        case 2:
            return num + "nd";
        case 3:
            return num + "rd";
        default:
            return num + "th";
    }
}


const PropertyProfileInvestor = (props: Props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [offerStatusDialog, setInvestorStatusDialog] = useState({
        offerStatus: PropertyInvestorStatus.Submitted,
        showDialog: false,
        editMode: false
    });
    const [acceptedOffer, setAcceptedOffer] = useState(props.item.AcceptedOffer === 0 ? props.item.OriginalOffer : props.item.AcceptedOffer);
    const [depositAmount, setDepositAmount] = useState(props.item.DepositAmount === 0 ? props.item.OriginalOffer : props.item.DepositAmount);
    const [payoutAmount, setPayoutAmount] = useState(props.item.PayoutAmount);
    const [reasonForRejecting, setReasonForRejecting] = useState('');

    const [deedPositionOrdinal, setDeedPositionOrdinal] = useState(props.item.DeedPosition);
    const [deedPositionOrdinals, setDeedPositionOrdinals] = useState<IdTextPair[]>([]);

    function getInvestorStatusColor(propertyInvestorStatus: PropertyInvestorStatus) {
        switch (propertyInvestorStatus) {
            case PropertyInvestorStatus.Submitted:
                return 'secondary';
            case PropertyInvestorStatus.Accepted:
                return "primary";
            //case PropertyInvestorStatus.Countered:
            //    return "info";
            case PropertyInvestorStatus.Rejected:
                return "warning";
        }
    }

    const _getInvestorStatusVerb = (propertyInvestorStatus: PropertyInvestorStatus): string => {
        if (offerStatusDialog.editMode)
            return "Save";

        switch (propertyInvestorStatus) {
            case PropertyInvestorStatus.Submitted:
                return "Undo";
            case PropertyInvestorStatus.Accepted:
                return "Accept"
            case PropertyInvestorStatus.Rejected:
                return "Decline"
            case PropertyInvestorStatus.Countered:
                return "Counter"
            case PropertyInvestorStatus.Exitted:
                return "Payout"
        }
    };

    useEffect(() => {
        if (offerStatusDialog.showDialog === false) return;

        fetchApi(`/api/Property/PropertyInvestorOfferStatus/${props.propertyID}/${props.item.PropertyInvestorID}`)
            .then((data: { DeedPositions: number[] }) => {
                setDeedPositionOrdinals(data.DeedPositions.map((i) => {
                    return { id: i, text: addOrdinal(i) };
                }));
            });
    }, [props.propertyID, props.item.PropertyInvestorID, offerStatusDialog.showDialog]);

    const _save = () => {
        const data = {
            PropertyID: props.propertyID,
            PropertyInvestorID: props.item.PropertyInvestorID,
            PropertyInvestorStatus: offerStatusDialog.offerStatus,
            EditMode: offerStatusDialog.editMode,
            AcceptedOffer: acceptedOffer,
            DepositAmount: depositAmount,
            PayoutAmount: payoutAmount,
            ReasonForRejecting: reasonForRejecting,
            DeedPosition: deedPositionOrdinal
        };

        fetchApi('/api/Property/UpdatePropertyInvestorOfferStatus', data, 'POST')
            .then(() => {
                props.refresh();
                setReasonForRejecting('');
            })
            .catch(() => {
                alert("Unable to save");
            });

        setInvestorStatusDialog({
            ...offerStatusDialog,
            showDialog: !offerStatusDialog.showDialog
        });
    };

    const _sendJointVenture = () => {
        const data = { PropertyInvestorID: props.item.PropertyInvestorID };
        fetchApi('/api/Property/SendJointVenture', data, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    alert("Success: Joint Venture agreement sent!");
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                alert("Unable to send joint venture");
            })
    }

    const _requestPayment = () => {
        if (!window.confirm("Are you sure you want to request funds with Stripe? This feature is in beta and may not work as expected.")) {
            return;
        }

        const data = { PropertyInvestorID: props.item.PropertyInvestorID };
        fetchApi('/api/Property/RequestPayment', data, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    alert("Success: Payment requested!");
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                alert("Unable to request payment");
            })
    }

    return (
        <React.Fragment>
            {!props.firstItem && <hr />}
            <div className="row">
                <div className="col-lg-3">
                    <div style={{ fontSize: "13px" }}><label className="fw-bold">Investor Date:</label>&nbsp;
                        {Moment.utc(props.item.SubmittedDateTime).local().format("MM/DD/YYYY HH:mm")}
                    </div>
                </div>
                <div className="col-lg-3">
                    <div style={{ fontSize: "13px" }}><label className="fw-bold">Investment Type:</label>&nbsp;
                        {getPropertyInvestmentType(props.item.InvestmentType)}
                    </div>
                </div>
                {props.item.FundingSource && <div className="col-lg-3">
                    <div style={{ fontSize: "13px" }}><label className="fw-bold">Funding Source:</label>&nbsp;
                        {props.item.FundingSource}
                    </div>
                </div>}
                {props.item.SubmittedInvestor.HowYouHeard && <div className="col-lg-3">
                    <div style={{ fontSize: "13px" }}><label className="fw-bold">Referral:</label>&nbsp;
                        {props.item.SubmittedInvestor.HowYouHeard}
                    </div>
                </div>}
            </div>

            <div className="row">
                <div className="col-lg-2">
                    <div className="fw-bold">Original Offer</div>
                    <div>{formatCurrency(props.item.OriginalOffer)}</div>
                </div>
                <div className="col-lg-2">
                    <div className="fw-bold">Accepted Offer</div>
                    <div>{formatCurrency(props.item.AcceptedOffer)}</div>
                </div>
                <div className="col-lg-2">
                    <div className="fw-bold">Funds</div>
                    {props.item.StripeInvoiceID ? <div>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className={props.item.InvoicePaid ? "text-success" : "text-warning"}
                            href={`https://dashboard.stripe.com/invoices/${props.item.StripeInvoiceID}`}
                        >
                            {props.item.InvoicePaid ? "Received" : "Requested"}
                        </a>
                    </div> : <div>
                        {props.item.InvoicePaid ? "Received" : props.item.InvoicePaid === false ? "Requested" : "N/A"}
                    </div>}
                </div>
                <div className="col-lg-1">
                    <div className="fw-bold">Deed Position</div>
                    <div>{addOrdinal(props.item.DeedPosition)}</div>
                </div>
                <div className="col-lg-3">
                    <div style={{ maxWidth: '400px' }}>
                        <InvestorAvatar investor={props.item.SubmittedInvestor} />
                    </div>
                </div>
                <div className="col-lg-2">
                    {props.item.Status === PropertyInvestorStatus.Submitted ?
                        <Dropdown
                            isOpen={dropdownOpen}
                            toggle={toggle}
                        >
                            <DropdownToggle caret>
                                PENDING DECISION
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem onClick={() => setInvestorStatusDialog({
                                    showDialog: !offerStatusDialog.showDialog,
                                    offerStatus: PropertyInvestorStatus.Accepted,
                                    editMode: false
                                })}>Accept</DropdownItem>
                                <DropdownItem onClick={() => setInvestorStatusDialog({
                                    showDialog: !offerStatusDialog.showDialog,
                                    offerStatus: PropertyInvestorStatus.Rejected,
                                    editMode: false
                                })}>Decline</DropdownItem>
                                {/*<DropdownItem onClick={() => setInvestorStatusDialog({*/}
                                {/*    showDialog: !offerStatusDialog.showDialog,*/}
                                {/*    offerStatus: PropertyInvestorStatus.Countered*/}
                                {/*})}>Counter</DropdownItem>*/}
                            </DropdownMenu>
                        </Dropdown>
                        : <>
                            <Dropdown
                                isOpen={dropdownOpen}
                                toggle={toggle}
                            >
                                <DropdownToggle color={getInvestorStatusColor(props.item.Status)} caret>
                                    {getPropertyInvestorStatus(props.item.Status).toUpperCase()}
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <DropdownItem onClick={() => setInvestorStatusDialog({
                                        showDialog: !offerStatusDialog.showDialog,
                                        offerStatus: props.item.Status === PropertyInvestorStatus.Exitted ? PropertyInvestorStatus.Accepted : PropertyInvestorStatus.Submitted,
                                        editMode: false
                                    })}>Undo {getPropertyInvestorStatus(props.item.Status)} Offer</DropdownItem>
                                    {props.item.Status === PropertyInvestorStatus.Accepted && <>
                                        <DropdownItem onClick={_sendJointVenture}>Send Joint Venture</DropdownItem>
                                        <DropdownItem onClick={_requestPayment}>BETA: Request Funds</DropdownItem>
                                        <DropdownItem onClick={() => setInvestorStatusDialog({
                                            showDialog: !offerStatusDialog.showDialog,
                                            offerStatus: props.item.Status,
                                            editMode: true
                                        })}>Edit Investment</DropdownItem>
                                        <DropdownItem onClick={() => setInvestorStatusDialog({
                                            showDialog: !offerStatusDialog.showDialog,
                                            offerStatus: PropertyInvestorStatus.Exitted,
                                            editMode: false
                                        })}>Payout Investment</DropdownItem>
                                    </>}
                                </DropdownMenu>

                            </Dropdown>
                            {props.item.ReasonForRejecting && <>
                                <br />
                                <span style={{ fontSize: "12px" }}>
                                    {props.item.ReasonForRejecting}
                                </span>
                            </>}
                        </>}
                    {props.item.JointVentureURL && <a href={props.item.JointVentureURL} target="_blank" rel="noopener noreferrer">
                        View Joint Venture
                    </a>}
                </div>
            </div>
            {
                offerStatusDialog.showDialog && <Dialog title={`${props.item.SubmittedInvestor.FullName} Offer`} style={{ fontSize: '22px !important' }} minWidth={350}
                    onClose={() => setInvestorStatusDialog({
                        ...offerStatusDialog,
                        showDialog: !offerStatusDialog.showDialog
                    })}>
                    {offerStatusDialog.offerStatus === PropertyInvestorStatus.Exitted &&
                        <div style={{ margin: 'auto', width: '50%' }}>
                            <div className="pt-2 pb-3">
                                <label className="fw-bold">Payout Amount</label>
                                <NumericTextBox
                                    value={payoutAmount}
                                    onChange={(e) => setPayoutAmount(e.value || 0)}
                                    format="c2"
                                    min={0}
                                    step={1000}
                                />
                            </div>
                        </div>
                    }
                    {offerStatusDialog.offerStatus === PropertyInvestorStatus.Accepted &&
                        <div style={{ margin: 'auto', width: '50%' }}>
                            <div className="pt-2 pb-3">
                                <label className="fw-bold">Deposit Amount</label>
                                <NumericTextBox
                                    value={depositAmount}
                                    onChange={(e) => setDepositAmount(e.value || 0)}
                                    format="c2"
                                    min={0}
                                    step={1000}
                                />
                            </div>
                            <div className="pb-3">
                                <label className="fw-bold">Original Offer</label>
                                <div>{formatCurrency(props.item.OriginalOffer)}</div>
                            </div>
                            <div className="pb-3">
                                <label className="fw-bold">Accepted Offer</label>
                                <NumericTextBox
                                    value={acceptedOffer}
                                    onChange={(e) => setAcceptedOffer(e.value || 0)}
                                    format="c2"
                                    min={0}
                                    step={1000}
                                />
                            </div>
                            <div className="pb-3">
                                <label className="fw-bold">Deed Position</label>
                                <DropDownList
                                    textField="text"
                                    value={deedPositionOrdinals.find(x => x.id === deedPositionOrdinal)} data={deedPositionOrdinals}
                                    onChange={(e) => setDeedPositionOrdinal((e.value as IdTextPair).id)}
                                />
                            </div>
                        </div>
                    }
                    {offerStatusDialog.offerStatus === PropertyInvestorStatus.Rejected &&
                        <div>
                            <TextArea
                                required
                                maxLength={240}
                                placeholder="Enter declined reason..."
                                value={reasonForRejecting}
                                onChange={(e) => setReasonForRejecting(e.value)}
                            />
                        </div>
                    }
                    {offerStatusDialog.offerStatus === PropertyInvestorStatus.Submitted &&
                        <p>
                            Are you sure?
                        </p>
                    }
                    <DialogActionsBar>
                        <Button onClick={() => setInvestorStatusDialog({
                            ...offerStatusDialog,
                            showDialog: !offerStatusDialog.showDialog
                        })}>CANCEL</Button>
                        {/*<button disabled={offerStatusDialog.offerStatus !== PropertyInvestorStatus.Accepted && (counterReason === undefined || counterReason.length === 0)}*/}
                        <Button
                            themeColor="primary"
                            disabled={(offerStatusDialog.offerStatus === PropertyInvestorStatus.Accepted && acceptedOffer <= 0) ||
                                (offerStatusDialog.offerStatus === PropertyInvestorStatus.Accepted && deedPositionOrdinal === 0)}
                            onClick={() => {
                                _save();
                            }}>{_getInvestorStatusVerb(offerStatusDialog.offerStatus).toUpperCase()} INVESTMENT</Button>
                    </DialogActionsBar>
                </Dialog>
            }
        </React.Fragment >
    );
}

export default PropertyProfileInvestor;