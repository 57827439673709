import { mapTree } from "@progress/kendo-react-gantt";
import { AddDirection } from "@progress/kendo-react-gantt/dist/npm/interfaces/AddDirection";
import { AddConstructionProjectTaskDirection } from "TypeGen/add-construction-project-task-direction";
import { ConstructionProjectTaskViewModel } from "TypeGen/construction-project-task-view-model";
import { TaskType } from "components/Construction/Task";

export const transformTask = (
  task: ConstructionProjectTaskViewModel
): TaskType => {
  const out: TaskType = {
    start: new Date(Date.parse(task.PlannedStartDateTime)),
    end: new Date(Date.parse(task.PlannedEndDateTime)),
    id: task.ConstructionProjectTaskID,
    isExpanded: true,
    orderId: 0,
    percentComplete: task.ProgressPercent / 100,
    percentGoal: task.GoalPercent / 100,
    title: task.Name,
    children: task.Children ? transformTasks(task.Children) : [],
    contractorId: task.AssignedContractorID ?? 0,
    details: task.ScopeOfWork,
    week: task.WeekNumber,
    drawBudget: task.DrawBudget,
  };
  return out;
};

export const transformTasks = (
  tasks: ConstructionProjectTaskViewModel[]
): TaskType[] => tasks.map(transformTask);

export function getTaskIds(tasks: TaskType[]): number[] {
  return tasks.flatMap((t) => {
    return [t.id, ...getTaskIds(t.children ?? [])];
  });
}

export function findTask(tasks: TaskType[] | undefined, id: number): TaskType {
  if (tasks == null) {
    throw Error(`list of tasks is null`);
  }
  tasks.forEach((task) => {
    if (task.id === id) {
      return task;
    }
    if (task.children && task.children.length > 0) {
      return findTask(task.children, id);
    }
  });

  throw Error(`could not find task with id: ${id}`);
}

export function findParent(
  tasks: TaskType[] | undefined,
  id: number
): TaskType | null {
  if (tasks == null) {
    throw Error(`list of tasks is null`);
  }
  return findParentR(tasks, id, null);
}

function findParentR(
  tasks: TaskType[],
  id: number,
  parentId: number | null
): TaskType | null {
  tasks.forEach((task) => {
    if (task.id === id) {
      return parentId;
    }
    if (task.children && task.children.length > 0) {
      return findTask(task.children, id);
    }
  });

  return null;
}

export function flattenTasks(tasks: TaskType[]): TaskType[] {
  const out: TaskType[] = [];
  mapTree(tasks, "children", (x) => out.push(x));
  return out;
}

export const KENDO_ADD_DIRECTION_TO_SERVER_DIRECTION: Record<
  AddDirection,
  AddConstructionProjectTaskDirection | undefined
> = {
  above: AddConstructionProjectTaskDirection.Above,
  below: AddConstructionProjectTaskDirection.Below,
  child: AddConstructionProjectTaskDirection.Child,
  none: undefined,
};
