import React, { useEffect, useState } from 'react';

const Marker: React.FC<
  google.maps.MarkerOptions & {
    onClick?: (market: google.maps.Marker) => void;
  }
> = ({ onClick, ...options }) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker && onClick) {
      marker.addListener('click', () => onClick(marker));
    }
  }, [onClick, marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions({
        ...options,
      });
    }
  }, [marker, options]);

  return null;
};

export default Marker;