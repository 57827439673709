import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { GridFilterCellProps } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { IDName } from '../utils/generic';

interface DropdownFilterCellProps extends GridFilterCellProps {
    defaultItem: IDName,
    data: IDName[]
}

export const DropdownFilterCell = (props: DropdownFilterCellProps) => {
    let hasValue: any = (value: any) => Boolean(value && value !== props.defaultItem);

    const onChange = (event: DropDownListChangeEvent) => {
        hasValue = hasValue(event.target.value);
        props.onChange({
            value: hasValue ? event.target.value.ID : '',
            operator: hasValue ? 'eq' : '',
            syntheticEvent: event.syntheticEvent
        });
    }

    const onClearButtonClick = (event: any) => {
        event.preventDefault();
        props.onChange({
            value: '',
            operator: '',
            syntheticEvent: event
        });
    }
    return (
        <div className="k-filtercell">
            <DropDownList
                dataItemKey="ID"
                textField="Name"
                data={props.data}
                onChange={onChange}
                value={props.value !== '' ? props.data.find(x => x.ID === props.value) : props.defaultItem}
                defaultItem={props.defaultItem}
            />&nbsp;
            <Button
                title="Clear"
                disabled={props.value === ''}
                onClick={onClearButtonClick}
                icon="filter-clear"
            />
        </div>
    );
}