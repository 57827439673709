import { useCallback, useEffect, useState } from 'react';
import { fetchApi } from '../../services/api';
import LoadingPanel from '../LoadingPanel';
import Linkify from 'react-linkify';
import Moment from 'moment';

type Props = {
    propertyID: number;
}

type PropertyNoteType = {
    ID: number;
    Note: string;
    DateTime: Date;
    User: { Name: string };
    IsUser: boolean;
}

const PropertyNotes = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [propertyNotes, setPropertyNotes] = useState<PropertyNoteType[]>([]);

    const _getPropertyNotes = useCallback(() => {
        setLoading(true);
        fetchApi(`/api/Property/PropertyNotes/${props.propertyID}`)
            .then((response: { Notes: PropertyNoteType[] }) => {
                setLoading(false);
                setPropertyNotes(response.Notes);
            })
            .catch((e) => {
                setLoading(false);
                console.error(e);
            })
    }, [props.propertyID]);

    useEffect(() => {
        _getPropertyNotes();
    }, [_getPropertyNotes]);

    return <div>
        {loading && <LoadingPanel />}
        <div className="k-chat ps-5" style={{ minWidth: '425px', height: 'auto', maxHeight: '500px', overflowY: 'auto', borderWidth: 0 }}>
            {propertyNotes.map((note) => <div className={`k-message-group${note.IsUser ? ' k-alt' : ''}`} key={note.ID}>
                <p className="k-author">{note.User.Name}</p>
                <div className="k-first k-state-selected k-message">
                    <time className="k-message-time" aria-hidden="true">{Moment.utc(note.DateTime).local().format("MM/DD/YYYY HH:mm")}</time>
                    <Linkify
                        componentDecorator={(decoratedHref: string, decoratedText: string, key: number) =>
                            <a key={key} style={{ color: 'inherit' }} target="_blank" rel="noreferrer" href={decoratedHref}>{decoratedText}</a>
                        }
                    >
                        <div className="k-bubble"><p>{note.Note}</p></div>
                    </Linkify>
                </div>
            </div>)}
        </div>
    </div >
}

export default PropertyNotes;
