import { Grid, GridColumn as Column, GridDataStateChangeEvent, GridFilterChangeEvent, GridSortChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { CompositeFilterDescriptor, FilterDescriptor, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { fetchApi } from 'services/api';
import { Title } from 'utils/title';
import ContractorNameCell from './ContractorNameCell';
import { ContractorViewModel } from 'TypeGen/contractor-view-model';
import { PhoneNumberCell } from 'components/PhoneNumberCell';
import LoadingPanel from 'components/LoadingPanel';
import { DateTimeCell } from 'components/DateTimeCell';
import { EmailAddressCell } from 'components/EmailAddressCell';
import { ActiveCell } from 'components/ActiveCell';
import { GridCellFilterType } from 'utils/kendo';
import ActiveFilterCell from 'components/ActiveFilterCell';
import { debounce } from 'ts-debounce';
import { paths } from 'App';
import { Link } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { downloadIcon } from '@progress/kendo-svg-icons';

const CONST_TAKE: number = 50;

const CONST_DATASTATE_FILTER_DEFAULT = {
    logic: 'and',
} as CompositeFilterDescriptor;

const CONST_DATASTATE_DEFAULT = {
    take: CONST_TAKE,
    skip: 0,
    sort: [{ field: 'FullName', dir: 'asc' }],
    filter: { ...CONST_DATASTATE_FILTER_DEFAULT },
} as GridState;

const Contractor = () => {
    const _export = useRef<ExcelExport | null>(null);
    const [data, setData] = useState<ContractorViewModel[]>();
    const [gridState, setGridState] = useState<GridState>(CONST_DATASTATE_DEFAULT);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const shouldBypassDebounce = useRef(true);

    const fetchData = useCallback(async (gridState: GridState) => {
        setLoading(true);
        const queryStr = toDataSourceRequestString(gridState);
        const res = await fetchApi(`/api/Contractor/List?${queryStr}`);
        setData(res.DataSourceResult.Data);
        setIsAdmin(res.isAdmin);
        setLoading(false);
        return Promise.resolve(false);
    }, []);
    const fetchDataDebounce = useMemo(() => debounce(fetchData, 500), [fetchData]);

    useEffect(() => {
        if (shouldBypassDebounce.current) {
            fetchData(gridState);
        } else {
            setLoading(true);
            fetchDataDebounce(gridState);
        }
        shouldBypassDebounce.current = false;
    }, [fetchData, fetchDataDebounce, gridState]);

    function dataStateChange(changeEvent: GridDataStateChangeEvent) {
        shouldBypassDebounce.current = true;
        setGridState(changeEvent.dataState);
    }

    const filterChange = (event: GridFilterChangeEvent) => {
        if (event.filter) {
            //let filters = (event.filter.filters as FilterDescriptor[]).filter(x => x.value !== null); //ensure that the requested filters aren't null (due to switching lt or eq params)
            let filters = event.filter.filters as FilterDescriptor[]; //ensure that the requested filters aren't null (due to switching lt or eq params)
            if (gridState.filter && filters.length) {
                setGridState({ ...gridState, filter: { ...gridState.filter, filters } });
            }
        } else if (gridState.filter && gridState.filter.filters.length) {
            //if no filter being requested, but we have some in state, empty it
            setGridState({ ...gridState, filter: { ...gridState.filter, filters: [] } });
        }
    };

    const sortChange = (event: GridSortChangeEvent) => {
        shouldBypassDebounce.current = true;
        setGridState({ ...gridState, sort: event.sort });
    };

    const download = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
      };

    return (
        <>
            <Title string="Contractors" />
            <h3 className="mb-2">Contractors</h3>
            {isLoading && <LoadingPanel />}
            <ExcelExport data={data} ref={_export}>
                <Grid
                    data={data}
                    scrollable="none"
                    pageable={{ pageSizes: [50, 100, 200, 500] }}
                    filterable
                    resizable
                    sortable={{
                        allowUnsort: true,
                        mode: 'multiple',
                    }}
                    {...gridState}
                    onSortChange={sortChange}
                    onDataStateChange={dataStateChange}
                    onFilterChange={filterChange}
                >
                    <GridToolbar>
                        <Link
                            to={paths.ContractorCreate}
                            role="button"
                            className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-primary k-rounded-md k-icon-button"
                            title="Add Investor"
                        >
                            <span role="presentation" style={{ color: 'white' }} className="k-button-icon k-icon k-i-add"></span>
                        </Link>
                        {isAdmin && (
                            <Button
                                icon="download"
                                svgIcon={downloadIcon}
                                title="Download"
                                style={{ marginLeft: 'auto' }}
                                onClick={download}
                            />
                        )}
                    </GridToolbar>
                    <Column field="FullName" title="Name" cell={ContractorNameCell(isAdmin)} />
                    <Column field="PhoneNumber" title="Phone" cell={PhoneNumberCell} />
                    <Column field="Email" cell={EmailAddressCell} />
                    <Column field="Active" cell={ActiveCell} filterCell={ActiveFilterCell(GridCellFilterType.ServerSide)} />
                    <Column field="CreatedDateTime" title="Created Date" cell={DateTimeCell} filterable={false} />
                </Grid>
            </ExcelExport>
        </>
    );
};

export default Contractor;
