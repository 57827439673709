import { useState } from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label, Error } from '@progress/kendo-react-labels';
import { Checkbox, RadioGroup } from '@progress/kendo-react-inputs';
import { Upload } from '@progress/kendo-react-upload';
import { getPropertyCondition, PropertyCondition, PropertyFrontBack, PropertyImage, PropertyImageType, PropertyViewModel } from '../../utils/property';
import { getPropertyFrontBack } from '../../utils/property';
import { physicalAttributeValidator, physicalConditionValidator } from '../../utils/generic';
import { fetchApi } from '../../services/api';
import { Button } from '@progress/kendo-react-buttons';

type Props = {
    property: PropertyViewModel;
    images: PropertyImage[];
    refresh: () => void;
}

export type PhysicalAttribute = {
    front: boolean;
    back: boolean;
    none: boolean;
    images: string[];
    error: string;
}

export type PhysicalCondition = {
    good: boolean | null;
    images: string[];
    error: string;
}

const PropertyProfileAttributes = (props: Props) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);

    const [busyStreet, setBusyStreet] = useState<PhysicalAttribute>({
        front: (([PropertyFrontBack.Front, PropertyFrontBack.Both] as Array<PropertyFrontBack | null>) as Array<PropertyFrontBack | null>).includes(props.property.PhysicalAttributes.BusyStreet),
        back: ([PropertyFrontBack.Back, PropertyFrontBack.Both] as Array<PropertyFrontBack | null>).includes(props.property.PhysicalAttributes.BusyStreet),
        none: props.property.PhysicalAttributes.BusyStreet === PropertyFrontBack.None,
        images: props.images.filter(x => x.PropertyImageType === PropertyImageType.BusyStreet).map(x => x.PropertyImageID.toString()),
        error: '',
    });

    const [cemetery, setCemetery] = useState<PhysicalAttribute>({
        front: ([PropertyFrontBack.Front, PropertyFrontBack.Both] as Array<PropertyFrontBack | null>).includes(props.property.PhysicalAttributes.Cemetery),
        back: ([PropertyFrontBack.Back, PropertyFrontBack.Both] as Array<PropertyFrontBack | null>).includes(props.property.PhysicalAttributes.Cemetery),
        none: props.property.PhysicalAttributes.Cemetery === PropertyFrontBack.None,
        images: props.images.filter(x => x.PropertyImageType === PropertyImageType.Cemetery).map(x => x.PropertyImageID.toString()),
        error: '',
    });

    const [school, setSchool] = useState<PhysicalAttribute>({
        front: ([PropertyFrontBack.Front, PropertyFrontBack.Both] as Array<PropertyFrontBack | null>).includes(props.property.PhysicalAttributes.School),
        back: ([PropertyFrontBack.Back, PropertyFrontBack.Both] as Array<PropertyFrontBack | null>).includes(props.property.PhysicalAttributes.School),
        none: props.property.PhysicalAttributes.School === PropertyFrontBack.None,
        images: props.images.filter(x => x.PropertyImageType === PropertyImageType.School).map(x => x.PropertyImageID.toString()),
        error: '',
    });

    const [doubleYellowLine, setDoubleYellowLine] = useState<PhysicalAttribute>({
        front: ([PropertyFrontBack.Front, PropertyFrontBack.Both] as Array<PropertyFrontBack | null>).includes(props.property.PhysicalAttributes.DoubleYellowLine),
        back: ([PropertyFrontBack.Back, PropertyFrontBack.Both] as Array<PropertyFrontBack | null>).includes(props.property.PhysicalAttributes.DoubleYellowLine),
        none: props.property.PhysicalAttributes.DoubleYellowLine === PropertyFrontBack.None,
        images: props.images.filter(x => x.PropertyImageType === PropertyImageType.DoubleYellowLine).map(x => x.PropertyImageID.toString()),
        error: '',
    });

    const [commercialBuilding, setCommercialBuilding] = useState<PhysicalAttribute>({
        front: ([PropertyFrontBack.Front, PropertyFrontBack.Both] as Array<PropertyFrontBack | null>).includes(props.property.PhysicalAttributes.CommercialBuilding),
        back: ([PropertyFrontBack.Back, PropertyFrontBack.Both] as Array<PropertyFrontBack | null>).includes(props.property.PhysicalAttributes.CommercialBuilding),
        none: props.property.PhysicalAttributes.CommercialBuilding === PropertyFrontBack.None,
        images: props.images.filter(x => x.PropertyImageType === PropertyImageType.CommercialBuilding).map(x => x.PropertyImageID.toString()),
        error: '',
    });

    const [roofCondition, setRoofCondition] = useState<PhysicalCondition>({
        good: props.property.PhysicalAttributes.Roof == null || props.property.PhysicalAttributes.Roof  === 0 ? null : props.property.PhysicalAttributes.Roof === PropertyCondition.Good,
        images: props.images.filter(x => x.PropertyImageType === PropertyImageType.Roof).map(x => x.PropertyImageID.toString()),
        error: '',
    });

    const _frontBackEnum = (value: PhysicalAttribute) => value.none ? 0 : (value.back && value.front) ? 3 : value.back ? 2 : 1;

    const goodBadRadioOptions = [
        { label: 'Good', value: 'true' },
        { label: 'Bad', value: 'false' }
    ];

    const _save = () => {

        // Validation
        const busyStreetError = physicalAttributeValidator(busyStreet);
        const cemeteryError = physicalAttributeValidator(cemetery);
        const schoolError = physicalAttributeValidator(school);
        const doubleYellowLineError = physicalAttributeValidator(doubleYellowLine);
        const commercialBuildingError = physicalAttributeValidator(commercialBuilding);
        const roofConditionError = physicalConditionValidator(roofCondition);

        if (busyStreetError || cemeteryError || schoolError || doubleYellowLineError || commercialBuildingError || roofConditionError) {
            setBusyStreet({ ...busyStreet, error: busyStreetError });
            setCemetery({ ...cemetery, error: cemeteryError });
            setSchool({ ...school, error: schoolError });
            setDoubleYellowLine({ ...doubleYellowLine, error: doubleYellowLineError });
            setCommercialBuilding({ ...commercialBuilding, error: commercialBuildingError });
            setRoofCondition({ ...roofCondition, error: roofConditionError });
            return;
        }

        setLoading(true);

        const data = {
            PropertyID: props.property.PropertyID,
            BusyStreet: _frontBackEnum(busyStreet),
            Cemetery: _frontBackEnum(cemetery),
            School: _frontBackEnum(school),
            DoubleYellowLine: _frontBackEnum(doubleYellowLine),
            CommercialBuilding: _frontBackEnum(commercialBuilding),
            Roof: roofCondition.good ? 1 : 2
        }
        fetchApi('/api/Property/PropertyAttributes', data, 'POST')
            .then(() => {
                setLoading(false);
                setVisible(false);
                props.refresh();
            })
            .catch(() => {
                setLoading(false);
                alert("Unable to save attributes!");
            });
    }

    const _physicalAttributeFront = (value: PhysicalAttribute, setValue: React.Dispatch<React.SetStateAction<PhysicalAttribute>>) => {
        setValue({ ...value, front: !value.front, none: false, error: '' });
    }

    const _physicalAttributeBack = (value: PhysicalAttribute, setValue: React.Dispatch<React.SetStateAction<PhysicalAttribute>>) => {
        setValue({ ...value, back: !value.back, none: false, error: '' });
    }

    const _physicalAttributeNone = (value: PhysicalAttribute, setValue: React.Dispatch<React.SetStateAction<PhysicalAttribute>>) => {
        setValue({ ...value, none: !value.none, front: false, back: false, error: '' });
    }

    const _physicalAttributeAddImage = (value: PhysicalAttribute, setValue: React.Dispatch<React.SetStateAction<PhysicalAttribute>>) => {
        setValue({ ...value, images: [''], error: '' });
    }

    const _physicalAttributeField = (label: string, imageType: number, attribute: PhysicalAttribute, setValue: React.Dispatch<React.SetStateAction<PhysicalAttribute>>) => {
        return <FieldWrapper>
            <Label>{label}</Label>
            <Checkbox className="pe-2" label="Front" checked={attribute.front} onChange={(e) => _physicalAttributeFront(attribute, setValue)} />
            <Checkbox className="pe-2" label="Back" checked={attribute.back} onChange={(e) => _physicalAttributeBack(attribute, setValue)} />
            <Checkbox label="None" checked={attribute.none} onChange={(e) => _physicalAttributeNone(attribute, setValue)} />
            {(attribute.front || attribute.back) && <Upload
                batch={false}
                restrictions={{
                    allowedExtensions: [ '.jpg', '.jpeg', '.png', '.gif' ],
                    maxFileSize: 10000000 // 10 MB
                }}
                saveField="File"
                defaultFiles={[]}
                withCredentials={false}
                saveHeaders={{
                 'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                }}
                saveUrl={`/api/Property/UploadImage/${props.property.PropertyID}/${imageType}`}
                onStatusChange={(e) => {
                    if (e.response?.status === 200) _physicalAttributeAddImage(attribute, setValue);
                }}
            />}
            <Error>{attribute.error}</Error>
        </FieldWrapper>
    }

    return <>
        {visible && <Window
            title="Physical Attributes" modal
            onClose={() => setVisible(false)}
            initialWidth={Math.min(window.innerWidth, 600)}
            initialHeight={650}
        >
            <form className="k-form k-form-md p-0">
                {_physicalAttributeField("Busy Street?", 1, busyStreet, setBusyStreet)}
                {_physicalAttributeField("Cemetery", 2, cemetery, setCemetery)}
                {_physicalAttributeField("School", 3, school, setSchool)}
                {_physicalAttributeField("Double Yellow Line", 4, doubleYellowLine, setDoubleYellowLine)}
                {_physicalAttributeField("Commercial Building", 5, commercialBuilding, setCommercialBuilding)}
                <FieldWrapper>
                    <Label>What is the condition of the roof?</Label>
                    <RadioGroup layout="horizontal" value={roofCondition.good?.toString()} data={goodBadRadioOptions} onChange={(e) => setRoofCondition({ ...roofCondition, good: e.value === 'true', error: ''})} />
                    <Upload
                        batch={false}
                        restrictions={{
                            allowedExtensions: [ '.jpg', '.jpeg', '.png', '.gif' ],
                            maxFileSize: 10000000 // 10 MB
                        }}
                        saveField="File"
                        defaultFiles={[]}
                        withCredentials={false}
                        saveHeaders={{
                            'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                        }}
                        saveUrl={`/api/Property/UploadImage/${props.property.PropertyID}/6`}
                        onStatusChange={(e) => {
                            if (e.response?.status === 200) setRoofCondition({ ...roofCondition, images: [''], error: '' });
                        }}
                    />
                    <Error>{roofCondition.error}</Error>
                </FieldWrapper>
            </form>
            <div className="text-center">
                <Button onClick={() => setVisible(false)}>Cancel</Button>
                &nbsp;&nbsp;
                <Button themeColor="primary" disabled={loading} onClick={_save}>Save</Button>
            </div>
        </Window>}
        <div className="groupbox groupbox-editable col-md-12" onClick={() => setVisible(true)}>
            <p className="property-panel-title">Physical Attributes</p>
            <div className="row">
                {props.property.PhysicalAttributes.BusyStreet != null && props.property.PhysicalAttributes.BusyStreet > 0 && <div className="col-md">
                    <div className="fw-bold">Busy Street</div>
                    <div>{getPropertyFrontBack(props.property.PhysicalAttributes.BusyStreet)}</div>
                </div>}
                {props.property.PhysicalAttributes.Cemetery != null && props.property.PhysicalAttributes.Cemetery > 0 && <div className="col-md">
                    <div className="fw-bold">Cemetery</div>
                    <div>{getPropertyFrontBack(props.property.PhysicalAttributes.Cemetery)}</div>
                </div>}
                {props.property.PhysicalAttributes.School != null && props.property.PhysicalAttributes.School > 0 && <div className="col-md">
                    <div className="fw-bold">School</div>
                    <div>{getPropertyFrontBack(props.property.PhysicalAttributes.School)}</div>
                </div>}
                {props.property.PhysicalAttributes.DoubleYellowLine != null && props.property.PhysicalAttributes.DoubleYellowLine > 0 && <div className="col-md">
                    <div className="fw-bold">Double Yellow Line</div>
                    <div>{getPropertyFrontBack(props.property.PhysicalAttributes.DoubleYellowLine)}</div>
                </div>}
                {props.property.PhysicalAttributes.CommercialBuilding != null && props.property.PhysicalAttributes.CommercialBuilding > 0 && <div className="col-md">
                    <div className="fw-bold">Commercial Building</div>
                    <div>{getPropertyFrontBack(props.property.PhysicalAttributes.CommercialBuilding)}</div>
                </div>}
                {props.property.PhysicalAttributes.Roof != null && <div className="col-md">
                    <div className="fw-bold">Roof Condition</div>
                    <div>{getPropertyCondition(props.property.PhysicalAttributes.Roof)}</div>
                </div>}
                {props.property.Status === 20 && <div className="mx-auto">
                    <button type="button" className="btn btn-primary">Physical Attributes</button>
                </div>}
            </div>
        </div>
    </>
}

export default PropertyProfileAttributes;