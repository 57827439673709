export const fetchApi = (endPoint: string, payload = {}, method = 'get') => {
  const mediaHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
  };

  const options = {
    body: JSON.stringify(payload),
    headers: mediaHeaders,
    method: method.toLowerCase(),
  } as RequestInit;

  if (method.toLowerCase() !== 'post' && Object.keys(payload).length === 0) {
    delete options.body;
  }

  return fetch(endPoint, options)
    .then(async (response) => {
      if ((response.status >= 200 && response.status < 300) || response.status === 422) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/blob") !== -1) {
          const filename = response.headers.get('Content-Disposition')?.split(';').find(n => n.includes('filename='))?.replace('filename=', '').trim().replace(/(^"|"$)/g, '').trim();
          return { blob: await response.blob(), filename };
        } else {
          return response.json();
        }
      }

      // Redirect for logout
      if (response.status === 401) {
        window.location.href = '/User/Logout?redirectUrl=' + encodeURI(window.location.pathname).replace("#", "%23");
      }

      if (response.status === 400) {
        throw new Error(await response.text());
      }

      throw new Error("An unexpected error has occurred");
  });
};

export const fetchPublicApi = (endPoint: string, payload = {}, method = 'get') => {
  const mediaHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };

  const options = {
    body: JSON.stringify(payload),
    headers: { ...mediaHeaders },
    method: method.toLowerCase(),
  } as RequestInit;

  if (Object.keys(payload).length === 0) {
    delete options.body;
  }

  return fetch(endPoint, options)
    .then((response) => {
      if ((response.status >= 200 && response.status < 300) || response.status === 422) {
        return response.json();
      }

      throw new Error(`${response.status}`);
    });
};