import { useRef, useState } from 'react';
import { fetchApi } from '../../services/api';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label, Error } from '@progress/kendo-react-labels';
import { PropertyViewModel } from '../../utils/property';
import { isNullOrEmptyValidator, formatCurrency, isNullValidator } from '../../utils/generic';
import { Window } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { NumericTextBox, RadioGroup, Input } from '@progress/kendo-react-inputs';
import { useDebouncedCallback } from 'use-debounce';
import { Button } from '@progress/kendo-react-buttons';

type Props = {
    property: PropertyViewModel;
    refresh: () => void;
}

const PropertyProfileAddBid = (props: Props) => {

    const buttonRef = useRef<HTMLButtonElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [reasonForRejecting, setReasonForRejecting] = useState('');
    const [propertyRenovation, setPropertyRenovation] = useState<{ value: number | null, error: string }>({ value: null, error: '' });
    const [afterRepairValue, setAfterRepairValue] = useState<{ value: number | null, error: string }>({ value: null, error: '' });
    const [price, setPrice] = useState<{ value: number | null, error: string }>({ value: null, error: '' });
    const [maxPrice, setMaxPrice] = useState(0);
    const [closeOfEscrowDays, setCloseOfEscrowDays] = useState<{ value: number | null, error: string }>({ value: null, error: '' });
    const [earnestMoneyDeposit, setEarnestMoneyDeposit] = useState<{ value: number | null, error: string }>({ value: null, error: '' });
    const [earnestMoneyDepositDays, setEarnestMoneyDepositDays] = useState<{ value: number | null, error: string }>({ value: null, error: '' });
    const [title, setTitle] = useState<{ value: number | null, error: string }>({ value: null, error: '' });
    const [escrow, setEscrow] = useState<{ value: number | null, error: string }>({ value: null, error: '' });
    const [hoa, setHoa] = useState<{ value: number | null, error: string }>({ value: null, error: '' });
    const [escalationClause, setEscalactionClause] = useState<{ value: boolean | null, error: string }>({ value: null, error: '' });
    const [escalationClauseReason, setEscalactionClauseReason] = useState({ value: '', error: '' });
    const [notes, setNotes] = useState('');

    const propertyRenovations = [
      { ID: 0, Name: 'Light Renovation' },
      { ID: 1, Name: 'Medium Renovation' },
      { ID: 2, Name: 'Heavy Renovation' }
    ];

    const yesNoRadioOptions = [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ];

    const buyerSellerOptions = [
      { label: 'Buyer', value: 0 },
      { label: 'Seller', value: 1 },
      { label: '50/50', value: 2 }
    ];

    const _updateOfferPrice = useDebouncedCallback((initial: boolean = false) => {
      if (propertyRenovation.value === null || !afterRepairValue.value) return;
      const data = {
        PropertyID: props.property.PropertyID,
        PropertyRenovation: propertyRenovation.value,
        AfterRepairValue: afterRepairValue.value
      }
      fetchApi('/api/Property/OfferPrice', data, 'post')
        .then((response: { OfferPrice: number, RegionNotFound: boolean }) => {
          if (response.RegionNotFound) {
            alert('We are currently not accepting properties from this region!');
            return;
          }
          if (!initial) setPrice({ value: response.OfferPrice, error: '' });
          setMaxPrice(response.OfferPrice);
        })
        .catch(() => {
          setPrice({ value: 0, error: 'Unable to calculate price!' });
          setMaxPrice(0);
        });
    }, 500);

    const _load = () => {
      fetchApi(`/api/Property/NegotiateProperty/${props.property.PropertyID}`)
        .then((data: {
          ReasonForRejecting: string,
          PropertyRenovation: number | null,
          AfterRepairValue: number,
          Price: number,
          CloseOfEscrowDays: number,
          EarnestMoneyDeposit: number,
          EarnestMoneyDepositDays: number,
          Title: number | null,
          Escrow: number | null,
          HOA: number | null,
          EscalactionClause: boolean | null,
          EscalactionClauseReason: string,
        }) => {
          setLoading(false);
          setReasonForRejecting(data.ReasonForRejecting || '');
          setPropertyRenovation({ value: data.PropertyRenovation, error: '' });
          setAfterRepairValue({ value: data.AfterRepairValue > 0 ? data.AfterRepairValue : null, error: '' });
          setPrice({ value: data.Price > 0 ? data.Price : null, error: '' });
          setCloseOfEscrowDays({ value: data.CloseOfEscrowDays > 0 ? data.CloseOfEscrowDays : null, error: '' });
          setEarnestMoneyDeposit({ value: data.EarnestMoneyDeposit > 0 ? data.EarnestMoneyDeposit : null, error: '' });
          setEarnestMoneyDepositDays({ value: data.EarnestMoneyDepositDays > 0 ? data.EarnestMoneyDepositDays : null, error: '' });
          setTitle({ value: data.Title, error: '' });
          setEscrow({ value: data.Escrow, error: '' });
          setHoa({ value: data.HOA, error: '' });
          setEscalactionClause({ value: data.EscalactionClause, error: '' });
          setEscalactionClauseReason({ value: data.EscalactionClauseReason || '', error: '' });

          _updateOfferPrice(true);
        });
    }

    const _save = () => {

        const propertyRenovationError = isNullValidator(propertyRenovation.value, 'Select Construction Type.');
        const afterRepairValueError = isNullValidator(afterRepairValue.value, 'Enter After Repair Value.');
        let priceError = isNullValidator(price.value, 'Enter Offer Price.');
        if (!priceError && price.value !== null) {
          if (price.value > maxPrice) priceError = `Above max offer price of ${formatCurrency(maxPrice)}`;
        }
        const closeOfEscrowDaysError = isNullValidator(closeOfEscrowDays.value, 'Enter COE Days.');
        const earnestMoneyDepositError = isNullValidator(earnestMoneyDeposit.value, 'Enter EMD Amount.');
        const earnestMoneyDepositDaysError = isNullValidator(earnestMoneyDepositDays.value, 'Enter EMD Days.');
        const titleError = isNullValidator(title.value, 'Select Title.');
        const escrowError = isNullValidator(escrow.value, 'Select Escrow.');
        const hoaError = isNullValidator(hoa.value, 'Select HOA.');
        const escalationClauseError = isNullValidator(escalationClause.value, 'Select Escalation Clause.');
        let escalationClauseReasonError = '';
        if (escalationClause.value) {
          escalationClauseReasonError = isNullOrEmptyValidator(escalationClauseReason.value, 'Enter Escalation Reason.');
        }

        if (propertyRenovationError || afterRepairValueError || priceError || closeOfEscrowDaysError || earnestMoneyDepositError || earnestMoneyDepositDaysError 
          || titleError || escrowError || hoaError || escalationClauseError || escalationClauseReasonError)  {
          setPropertyRenovation({ ...propertyRenovation, error: propertyRenovationError });
          setAfterRepairValue({ ...afterRepairValue, error: afterRepairValueError });
          setPrice({ ...price, error: priceError });
          setCloseOfEscrowDays({ ...closeOfEscrowDays, error: closeOfEscrowDaysError });
          setEarnestMoneyDeposit({ ...earnestMoneyDeposit, error: earnestMoneyDepositError });
          setEarnestMoneyDepositDays({ ...earnestMoneyDepositDays, error: earnestMoneyDepositDaysError });
          setTitle({ ...title, error: titleError });
          setEscrow({ ...escrow, error: escrowError });
          setHoa({ ...hoa, error: hoaError });
          setEscalactionClause({ ...escalationClause, error: escalationClauseError });
          setEscalactionClauseReason({ ...escalationClauseReason, error: escalationClauseReasonError });
          return;
        }

        setLoading(true);

        const data = {
          PropertyID: props.property.PropertyID,
          PropertyRenovation: propertyRenovation.value,
          AfterRepairValue:  afterRepairValue.value,
          Price: price.value,
          CloseOfEscrowDays: closeOfEscrowDays.value,
          EarnestMoneyDeposit: earnestMoneyDeposit.value,
          EarnestMoneyDepositDays: earnestMoneyDepositDays.value,
          Title: title.value,
          Escrow: escrow.value,
          HOA: hoa.value,
          EscalactionClause: escalationClause.value,
          EscalactionClauseReason: escalationClauseReason.value || '',
          Notes: notes || '',
        }
        fetchApi('/api/Property/NegotiateProperty', data, 'POST')
          .then((response: { Success: boolean, ErrorMessage: string }) => {
            setLoading(false);
            if (response.Success) {
              setVisible(false);
              props.refresh();
            } else {
              alert(response.ErrorMessage);
            }
          })
          .catch(() => {
            setLoading(false);
            alert("Unable to add bid!");
          });
    }
    return <>
        {visible && <Window
          title="Add Bid" modal
          onClose={() => setVisible(false)}
          initialWidth={Math.min(window.innerWidth, 600)} initialHeight={650}
          top={(buttonRef.current?.offsetTop ?? 325) - 325}
        >
            <form className="k-form k-form-md p-0">
              {reasonForRejecting && <p className="text-danger">{reasonForRejecting}</p>}
              <FieldWrapper>
                <DropDownList
                  label="Construction Type"
                  textField="Name"
                  value={propertyRenovations.find(x => x.ID === propertyRenovation.value)}
                  data={propertyRenovations}
                  onChange={(e) => {
                    setPropertyRenovation({ value: e.value.ID, error: '' });
                    _updateOfferPrice();
                  }}
                />
                <Error>{propertyRenovation.error}</Error>
              </FieldWrapper>
              <FieldWrapper>
                <NumericTextBox
                  width="100%"
                  label="After Repair Value (ARV)"
                  min={0}
                  step={5000}
                  format="c0"
                  value={afterRepairValue.value}
                  onChange={(e) => {
                    setAfterRepairValue({ value: e.value || 0, error: '' });
                    _updateOfferPrice();
                  }}
                />
                <Error>{afterRepairValue.error}</Error>
              </FieldWrapper>
              <FieldWrapper>
                <NumericTextBox
                  disabled={maxPrice === 0}
                  width="100%"
                  label="Offer Price"
                  min={0}
                  step={5000}
                  format="c0"
                  value={price.value}
                  onChange={(e) => {
                    let priceError = '';
                    const newValue = e.value || 0;
                    if (newValue > maxPrice) priceError = `Above max offer price of ${formatCurrency(maxPrice)}`;
                    setPrice({ value: newValue, error: priceError });
                  }}
                />
                <Error>{price.error}</Error>
              </FieldWrapper>
              <FieldWrapper>
                <NumericTextBox 
                  label="Close Of Escrow Days"
                  value={closeOfEscrowDays.value}
                  onChange={e => setCloseOfEscrowDays({ value: e.value || 0, error: '' })}
                  min={0}
                />
                <Error>{closeOfEscrowDays.error}</Error>
              </FieldWrapper>
              <FieldWrapper>
                <NumericTextBox 
                  label="Earnest Money Deposit Amount"
                  value={earnestMoneyDeposit.value}
                  onChange={(e) => setEarnestMoneyDeposit({ value: e.value || 0, error: '' }) }
                  format="c0"
                  min={0}
                />
                <Error>{earnestMoneyDeposit.error}</Error>
              </FieldWrapper>
              <FieldWrapper>
                <NumericTextBox 
                  label="Earnest Money Deposit Days"
                  value={earnestMoneyDepositDays.value}
                  onChange={e => setEarnestMoneyDepositDays({ value: e.value || 0, error: '' })}
                  min={0}
                />
                <Error>{earnestMoneyDepositDays.error}</Error>
              </FieldWrapper>
              <FieldWrapper>
                <Label>Title Fees Paid By</Label>
                <RadioGroup layout="horizontal" data={buyerSellerOptions} value={title.value} onChange={(e) => setTitle({ value: e.value, error: '' })} />
                <Error>{title.error}</Error>
              </FieldWrapper>
              <FieldWrapper>
                <Label>Escrow Fees Paid By</Label>
                <RadioGroup layout="horizontal" data={buyerSellerOptions} value={escrow.value} onChange={(e) => setEscrow({ value: e.value, error: '' })} />
                <Error>{escrow.error}</Error>
              </FieldWrapper>
              <FieldWrapper>
                <Label>HOA Fees Paid By</Label>
                <RadioGroup layout="horizontal" data={buyerSellerOptions} value={hoa.value} onChange={(e) => setHoa({ value: e.value, error: '' })} />
                <Error>{hoa.error}</Error>
              </FieldWrapper>
              <FieldWrapper>
                <Label>Escalation Clause</Label>
                <RadioGroup layout="horizontal" data={yesNoRadioOptions} value={escalationClause.value?.toString()} onChange={(e) => setEscalactionClause({ value: e.value === 'true', error: '' })} />
                <Error>{escalationClause.error}</Error>
              </FieldWrapper>
              {escalationClause.value === true && <FieldWrapper>
                <Input 
                  label="Escalation Clause Reason"
                  value={escalationClauseReason.value}
                  onChange={e => setEscalactionClauseReason({ value: e.value, error: '' })}
                />
                <Error>{escalationClauseReason.error}</Error>
              </FieldWrapper>}
              <FieldWrapper>
                <Input 
                  label='Bid Notes'
                  value={notes}
                  onChange={text => setNotes(text.value)}
                />
              </FieldWrapper>
            </form>
            <br />
            <div className="text-center">
              <Button onClick={() => setVisible(false)}>Cancel</Button>
              &nbsp;&nbsp;
              <Button themeColor="primary" disabled={loading} onClick={_save}>Add Bid</Button>
            </div>
        </Window>}
        <br />
        <button ref={buttonRef} type="button" className="btn btn-primary" onClick={() => {
          _load();
          setVisible(true);
        }}>Add New Bid</button>
    </>
}

export default PropertyProfileAddBid;

