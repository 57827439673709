import * as React from 'react';
import { Avatar } from '@progress/kendo-react-layout';
import { getAgentLevel, AgentViewModel } from '../../utils/agent';
import { formatPhoneNumber } from '../../utils/generic';
import { avatarType as AvatarType } from '@progress/kendo-react-layout/dist/npm/card/interfaces/Enums';
import './Agent.css';

type Props = {
    hideLinks?: boolean;
    title?: string;
    titleClassName?: string;
    agent: AgentViewModel;
    onIconClicked?: (agent: AgentViewModel) => void;
}

type AvatarViewModel = {
    avatar: string;
    avatarType: AvatarType;
};
export type AgentAvatarViewModel = AgentViewModel & AvatarViewModel;

type State = {
    avatar: AgentAvatarViewModel | null;
}

export default class AgentAvatar extends React.Component<Props, State> {

    //private divStyle = { borderRadius: '10px', padding: '8px 14px', marginBottom: '4px', boxShadow: '5px 5px 5px -3px #ccc', border: '1px solid #BFBFBF' };

    constructor(props: Props) {
        super(props);

        this.state = {
            avatar: null
        }
    }

    public render() {
        return (<>
            {this.state.avatar &&
                <>
                    <div className="groupbox" style={{ position: "relative" }}>
                        {this.props.onIconClicked && <a
                            href="/#"
                            title="Unassign Agent?"
                            style={{ position: "absolute", top: "-14px", right: "-8px", textDecoration: "none" }}
                            onClick={(e) => {
                                if (this.props.onIconClicked) {
                                    if (window.confirm("Unassign Agent?")) {
                                        this.props.onIconClicked(this.props.agent);
                                    }
                                }
                                e.preventDefault();
                            }}
                        >
                            <span className="k-icon k-i-minus-outline" style={{ fontSize: "20px" }} />
                        </a>}

                        {this.props.title && <div className={this.props.titleClassName} style={{ textAlign: 'left', padding: 4 }}>{this.props.title}</div>}
                        <div className="k-hbox">
                            {
                                this.state.avatar.AgentID === 5 ?
                                    <Avatar type='image'><img src='/images/profilePhoto.jpg' alt='' /></Avatar> :
                                    <Avatar type='text'>{this.state.avatar.avatar}</Avatar>
                            }
                            <div style={{ wordBreak: "break-word" }}>
                                <h2 style={{ fontSize: '1.3em', fontWeight: 'normal', margin: 0 }}>{this.state.avatar.FullName} - {getAgentLevel(this.state.avatar.Level)}</h2>
                                <p style={{ margin: '0', fontSize: '0.9em' }}>
                                    {this.props.hideLinks ? this.state.avatar.Email : <a
                                        href={`mailto:${this.state.avatar.Email}`}
                                        style={{ color: '#007bff' }}
                                    >{this.state.avatar.Email}
                                    </a>}
                                </p>
                                <p style={{ margin: '0', fontSize: '0.9em' }}>
                                    {this.props.hideLinks ? this.state.avatar.PhoneNumber : <a
                                        href={`tel:${this.state.avatar.PhoneNumber}`}
                                        style={{ color: '#007bff' }}
                                    >{this.state.avatar.PhoneNumber}
                                    </a>}
                                </p>
                            </div>
                        </div>

                    </div>
                </>
            }
        </>);
    }

    public componentDidMount() {
        this.setState({
            avatar: {
                AgentID: this.props.agent.AgentID,
                Level: this.props.agent.Level,
                Email: this.props.agent.Email,
                PhoneNumber: formatPhoneNumber(this.props.agent.PhoneNumber),
                FullName: this.props.agent.FullName,
                avatar: `${this.props.agent.FullName.charAt(0)}${this.props.agent.FullName.toUpperCase().split(" ")[1].charAt(0)}`,
                avatarType: AvatarType.TEXT
            } as AgentAvatarViewModel
        });
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.props.agent.AgentID !== prevProps.agent.AgentID) {
            this.componentDidMount();
        }
    }
}