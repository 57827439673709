import { GridCellProps } from "@progress/kendo-react-grid";
import { InvestorViewModel } from "utils/investor";

export const InvestorTypesCell = (props: GridCellProps) => {
  const dataItem = props.dataItem as InvestorViewModel;
  return (
      <td>
          {dataItem.InvestorTypes?.replace('|', ', ')}
      </td>
  );
}