import * as React from 'react';
import { GridFilterCell } from '@progress/kendo-react-grid';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { GridCellFilterType } from '../utils/kendo';
import { IdTextPair } from '../utils/generic';

export default function ActiveFilterCell(filterType: GridCellFilterType) {
    return class extends GridFilterCell {
        private statusOption: IdTextPair[] = [{ text: "Active", id: 1 }, { text: "Retired", id: 0 }, { text: "All", id: 2 }];

        getIdTextPair = () => {
            let lookup = 2;
            if (this.props.value !== "") {
                lookup = Number(this.props.value); //Convert boolean to number // If 2 in the props value, it'll skip this and just use the 2 i.e. BOTH
            }
            return this.statusOption.filter(x => x.id === lookup)[0];
        };

        getPairValue = (idTextPair: IdTextPair) => {
            switch (filterType) {
                case GridCellFilterType.ServerSide:
                    return idTextPair.id;
                default:
                    return idTextPair.text;
            }
        };

        onChange = (event: any) => {
            const value = this.getPairValue(event.target.value as IdTextPair);

            if (value === 2) { //if Both, remove the filters when returning to parent
                this.props.onChange({
                    value: '',
                    operator: '',
                    syntheticEvent: event
                });
            } else {
                let status = Boolean(Number(value));
                this.props.onChange({
                    value: status,
                    operator: 'eq',
                    syntheticEvent: event.syntheticEvent
                });
            }
        }

        render() {
            const value = this.getIdTextPair();
            return (
                <React.Fragment>
                    <div className="k-filtercell">
                        <div className="k-filtercell-wrapper">
                            <DropDownList
                                data={this.statusOption}
                                textField="text"
                                dataItemKey="id"
                                onChange={this.onChange}
                                value={value}
                            />
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }
}