import { GridCellProps } from "@progress/kendo-react-grid";
import { PropertyViewModel, getPropertyConstructionStatus } from '../../utils/property';

export const PropertyConstructionStatusCell = (props: GridCellProps) => {
    const dataItem = props.dataItem as PropertyViewModel;
    if (!dataItem.ConstructionStatus) return <td />;
    return (
        <td>
            {getPropertyConstructionStatus(dataItem.ConstructionStatus)}
        </td>
    );
}