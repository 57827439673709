import React, { useState } from 'react';
import { fetchApi } from 'services/api';

const RequestAccountDeletion = () => {

  const [appName, setAppName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    fetchApi('/api/User/RequestAccountDeletion', { AppName: appName, EmailAddress: emailAddress }, 'POST')
      .then(() => {
        alert('Your request has been submitted. We\'ll take care of it as soon as possible.');
        window.location.href = 'https://belwoodinvestments.com/';
      })
      .catch(err => {
        alert('An error occurred. Please try again.');
        console.error(err);
      });
  }

  return <div className="container">
    <div className="row">
      <div className="col-md-12">
        <h1>Request Account Deletion</h1>
        <p>
          If you would like to delete your account, please select the app you are using and enter the email address associated with your account.
        </p>
        <form onSubmit={submit}>
          <div className="form-group">
            <label htmlFor="appName">App Name</label>
            <select required className="form-control" id="appName" name="appName" value={appName} onChange={e => setAppName(e.target.value)}>
              <option value="">Select App</option>
              <option>BI Agent</option>
              <option>BI Investor</option>
            </select>
          </div>
          <br />
          <div className="form-group">
            <label htmlFor="emailAddress">Email Address</label>
            <input required type="email" className="form-control" id="emailAddress" name="emailAddress" value={emailAddress} onChange={e => setEmailAddress(e.target.value)} />
          </div>
          <br />
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
}

export default RequestAccountDeletion;