import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import * as React from 'react';
import { fetchApi } from '../../services/api';
import LoadingPanel from '../LoadingPanel';
import { JsonResponse } from 'utils/generic';
import { useDebouncedCallback } from 'use-debounce';
import { CompositeFilterDescriptor, FilterDescriptor, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { DropDownList } from '@progress/kendo-react-dropdowns';

interface Props {
    CloseDialog: (refresh: boolean) => void;
}

const ClaimReward = (props: Props) => {
    const [investorSearchData, setInvestorSearchData] = React.useState([]);
    const [investorSearchLoading, setInvestorSearchLoading] = React.useState(false);

    const [reward, setReward] = React.useState('');
    const [investorId, setInvestorId] = React.useState<number | null>(null);
    const [points, setPoints] = React.useState<number | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);

    const searchInvestors = useDebouncedCallback((filter: FilterDescriptor) => {
        const dataGridState = {
            take: 15,
            sort: [{ field: "FullName", dir: "asc" }],
            filter: {
                logic: 'and', filters: [filter] as FilterDescriptor[]
            } as CompositeFilterDescriptor
        } as GridState;
        let queryStr = `${toDataSourceRequestString(dataGridState)}`;

        fetchApi(`/api/Investor/List?${queryStr}`)
            .then((response: any) => {
                setInvestorSearchData(response.DataSourceResult.Data);
                setInvestorSearchLoading(false);
            }).catch(() => {
                alert('Error!');
                setInvestorSearchLoading(false);
            });
    }, 250);


    const claimReward = () => {
        if (!investorId) {
            alert("You must select an investor!");
            return;
        }

        if (!reward) {
            alert("You must enter a reward name!");
            return;
        }

        if (!points) {
            alert("You must enter a point value to use!");
            return;
        }

        setIsLoading(true);

        const data = {
            InvestorID: investorId,
            PointsUsed: points,
            RewardName: reward,
        };
        fetchApi('/api/Investor/ClaimReward', data, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    alert("Successfully claimed reward!");
                    props.CloseDialog(true);
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                alert("Unable to claim reward!");
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <Dialog title="Claim Reward for Investor" onClose={() => props.CloseDialog(false)} width={720}>
            {isLoading && <LoadingPanel />}
            <div className="container">
                <fieldset>
                    <p>This will allow you to claim a reward for an investor, subtracting the points from their account.</p>
                    <DropDownList
                        data={investorSearchData}
                        textField="FullName"
                        defaultValue={{ FullName: "Select an Investor", InvestorID: null }}
                        filterable={true}
                        loading={investorSearchLoading}
                        onFilterChange={(e) => {
                            setInvestorSearchData([]);
                            if (String(e.filter.value).length > 2) {
                                setInvestorSearchLoading(true);
                                searchInvestors(e.filter);
                            }
                        }}
                        onChange={(e) => setInvestorId(e.value.InvestorID)}
                    />
                    <Input
                        autoFocus
                        style={{ width: "100%" }}
                        label="Reward Name"
                        value={reward}
                        onChange={(e) => setReward(e.value)}
                        maxLength={100}
                    />
                    <NumericTextBox
                        width="100%"
                        label="Points"
                        value={points}
                        onChange={(e) => setPoints(e.value)}
                        min={0}
                        max={1000000}
                        step={1000}
                    />
                </fieldset>
            </div>
            <DialogActionsBar>
                <Button onClick={() => props.CloseDialog(false)}>Cancel</Button>
                <Button themeColor="primary" onClick={(e) => {
                    e.preventDefault();
                    if (window.confirm("Are you sure?")) {
                        claimReward();
                    }
                }}>Claim</Button>
            </DialogActionsBar>
        </Dialog>
    )
}

export default ClaimReward;
