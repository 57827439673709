import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { Button } from '@progress/kendo-react-buttons';
import { PropertyViewModel } from '../../utils/property';
import AgentAvatar from '../Agent/AgentAvatar';
import { AgentViewModel } from '../../utils/agent';
import OutsideAlerter from '../OutsideAlerter';

export const AgentCell = (props: GridCellProps) => {

    const [showMenu, setShowMenu] = React.useState<boolean>(false);

    const dataItem = props.dataItem as PropertyViewModel;
    let agent: AgentViewModel | null;
    if ((props.field as string).includes("AssignedAgent")) {
        if (dataItem.AssignedAgent === null || !dataItem.AssignedAgent.FullName)
            return (<td></td>);
        else
            agent = dataItem.AssignedAgent;
    } else {
        agent = dataItem.SubmittedAgent;
    }

    return (
        <td>
            <div>
                <Button fillMode="flat" onClick={(e) => {
                    setShowMenu((prevState => !prevState));
                    e.stopPropagation();
                }}>{agent.FullName}</Button>
            </div>
            {showMenu &&
                <OutsideAlerter style={{ position: "absolute", zIndex: 1, }} toggle={() => setShowMenu((prevState => !prevState))}>
                    <div className="agentdiv absolute-bg">
                        <AgentAvatar agent={agent} />
                    </div>
                </OutsideAlerter>
            }
        </td>
    );
}