export function drawRefreshButtonOnGridFooter(onclick: any): void {
    const div = document.querySelector(`.k-pager-info`) as HTMLDivElement;
    div.classList.remove("k-pager-info");
    div.style.marginLeft = "auto";
    div.style.display = "flex";
    if (div.parentNode) {
        const btn = document.createElement('button');
        btn.title = "Refresh";
        btn.className = "k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md k-icon-button";
        btn.style.marginLeft = "1%";
        btn.style.display = "flex";
        btn.onclick = onclick;
        const span = document.createElement('span');
        span.className = "k-button-icon k-icon k-i-refresh";
        btn.appendChild(span);
        div.parentNode.insertBefore(btn, div.nextSibling);
    }
}

export enum GridCellFilterType {
    ServerSide,
    ClientSide,
}