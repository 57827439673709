import { GridCellProps } from "@progress/kendo-react-grid";
import { Link } from 'react-router-dom';
import { InvestorViewModel } from '../../utils/investor';

export const InvestorNameCell = (props: GridCellProps) => {
    const dataItem = props.dataItem as InvestorViewModel;
    return (
        <td>
            <Link to={`/Investor/${dataItem.InvestorID}/Profile`} style={{ color: '#007bff' }}>
                {dataItem.FullName}
            </Link>
        </td >
    );
}

export default InvestorNameCell;