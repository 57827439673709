import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AgentLevel, AgentViewModel } from '../../utils/agent';
import { fetchApi } from '../../services/api';
import './Agent.css';
import { debounce } from 'ts-debounce';
import LoadingPanel from '../LoadingPanel';
import AgentAvatar from './AgentAvatar';

type Props = {
    anchor: HTMLElement;
    close: (selectedAgent?: AgentViewModel) => void;
    agent?: AgentViewModel;
    showSearchInput?: boolean;
    autoFocusSearchInput?: boolean;
    agentLevelExclusionList?: AgentLevel[]
}

type State = {
    isLoading: boolean;
    agentAvatars: AgentViewModel[];
    avatarFilter: AgentViewModel[];
    inputFilter: string;
    anchor: HTMLElement | null;
}

export default class AgentAvatarListing extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            agentAvatars: [],
            avatarFilter: [],
            inputFilter: "",
            anchor: this.props.anchor
        }

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.fetch = this.fetch.bind(this);
    }

    private filterInputChangeDebounced = debounce(this.filterInputChange, 500);
    private filterInputChange(): Promise<boolean> {
        this.fetch();
        return Promise.resolve(false);
    }

    public render() {
        return (
            <>
                {this.state.isLoading && <LoadingPanel />}
                <div>
                    {this.props.showSearchInput &&
                        <input className="form-control"
                            value={this.state.inputFilter}
                            autoFocus={this.props.autoFocusSearchInput ?? false}
                            placeholder="Search"
                            onChange={(e) => {
                                document.removeEventListener("mousedown", this.handleClickOutside);
                                document.addEventListener('mousedown', this.handleClickOutside);
                                this.setState({
                                    inputFilter: e.target.value,
                                }, () => { this.filterInputChangeDebounced() })
                            }}
                        />
                    }
                    <div style={{ position: "absolute", zIndex: 1, minWidth: "300px", marginTop: "8px" }} ref={'wrapper'}>
                        {this.state.avatarFilter && this.state.avatarFilter.map((avatar, index) => {
                            return (
                                <div key={avatar.AgentID}
                                    onClick={() => { this.props.showSearchInput && this.props.close(this.state.agentAvatars.filter(x => x.AgentID === avatar.AgentID)[0]) }}
                                    className={this.props.showSearchInput ? "agentdivlisting absolute-bg" : ""}>
                                    <AgentAvatar
                                        hideLinks={true}
                                        agent={avatar}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </>);
    }

    private handleClickOutside(event: MouseEvent) {        
        if (this.props.anchor) {            
            const area = ReactDOM.findDOMNode(this.refs.wrapper);
            if (area && !area.contains(event.target as Node)) {                
                document.removeEventListener('mousedown', this.handleClickOutside);
                this.setState({ agentAvatars: [], avatarFilter: [] });
                this.props.close();
            }
        }
    }

    componentDidUpdate(prevProps: Props) { //In parent caller/component, it's best to store the anchor in state.  When anchor is loaded in parent, it'll update props here
        if (this.props.anchor !== prevProps.anchor) {
            this.setState({ anchor: this.props.anchor })
        }
    }

    public componentDidMount() {
        if (this.props.showSearchInput) {
        } else {
            const _agents = [{ ...this.props.agent } as AgentViewModel];
            this.setState({
                agentAvatars: _agents.map(x => x),
                avatarFilter: _agents.map(x => x),
                isLoading: false
            });
        }
    }

    public componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    private fetch() {
        if (this.state.inputFilter.length > 2) {
            this.setState({
                isLoading: true
            });

            var data = {
                NameFilter: this.state.inputFilter,
                ExcludedAgentLevels: this.props.agentLevelExclusionList as AgentLevel[]
            };

            fetchApi('/api/Agent/GetAgentAvatars', data, "POST")
                .then((agents: AgentViewModel[]) => {
                    this.setState({
                        agentAvatars: [...agents] as AgentViewModel[],
                        avatarFilter: [...agents] as AgentViewModel[],
                        isLoading: false
                    }, () => { });

                })
                .catch(() => {
                    this.setState({ isLoading: false });
                });
        } else {
            this.setState({
                agentAvatars: [],
                avatarFilter: []
            })
        }
    }
}