import { useState } from 'react';
import { fetchApi } from '../../services/api';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import { getPropertyLotSizeUnit, getPropertyUnitType, PropertyViewModel } from '../../utils/property';
import { formatNumber, yearBuiltValidator, isPositiveValidator } from '../../utils/generic';
import { Window } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { NumericTextBox, RadioGroup } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';

type Props = {
    property: PropertyViewModel;
    refresh: () => void;
}

const PropertyProfileSpecifications = (props: Props) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [yearBuilt, setYearBuilt] = useState({ value: props.property.Specifications.YearBuilt, error: '' });
    const [bedrooms, setBedrooms] = useState({ value: props.property.Specifications.Bedrooms, error: '' });
    const [bathrooms, setBathrooms] = useState({ value: props.property.Specifications.Bathrooms, error: '' });
    const [squareFeet, setSquareFeet] = useState({ value: props.property.Specifications.SquareFeet, error: '' });
    const [lotSize, setLotSize] = useState({ value: props.property.Specifications.LotSize, error: '' });
    const [lotSizeUnit, setLotSizeUnit] = useState(props.property.Specifications.LotSizeUnit);
    const [unitType, setUnitType] = useState({ value: props.property.Specifications.UnitType, error: '' });

    const unitTypes = [
        { ID: 1, Name: 'Single' },
        { ID: 2, Name: 'Duplex' },
        { ID: 3, Name: 'Triplex' }
    ];

    const lotSizeUnits = [
        { label: getPropertyLotSizeUnit(0), value: 0 },
        { label: getPropertyLotSizeUnit(1), value: 1 },
    ]

    const _save = () => {

        const yearBuiltError = yearBuiltValidator(yearBuilt.value);
        const bedroomsError = isPositiveValidator(bedrooms.value, 'Bedrooms cannot be empty.');
        const bathroomsError = isPositiveValidator(bathrooms.value, 'Bathrooms cannot be empty.');
        const squareFeetError = isPositiveValidator(squareFeet.value, 'Square Feet cannot be empty.');
        const lotSizeError = isPositiveValidator(lotSize.value, 'Lot Size cannot be empty.');
        const unitTypeError = isPositiveValidator(unitType.value, 'Select Type of Unit.');

        if (yearBuiltError || bedroomsError || bathroomsError || squareFeetError || lotSizeError || unitTypeError) {
            setYearBuilt({ ...yearBuilt, error: yearBuiltError });
            setBedrooms({ ...bedrooms, error: bedroomsError });
            setBathrooms({ ...bathrooms, error: bathroomsError });
            setSquareFeet({ ...squareFeet, error: squareFeetError });
            setLotSize({ ...lotSize, error: lotSizeError });
            setUnitType({ ...unitType, error: unitTypeError });
            return;
        }

        setLoading(true);

        const data = {
            PropertyID: props.property.PropertyID,
            YearBuilt: yearBuilt.value,
            Bathrooms: bathrooms.value,
            Bedrooms: bedrooms.value,
            SquareFeet: squareFeet.value,
            LotSize: lotSize.value,
            LotSizeUnit: lotSizeUnit,
            UnitType: unitType.value,
        }
        fetchApi('/api/Property/PropertySpecifications', data, 'POST')
            .then(() => {
                setLoading(false);
                setVisible(false);
                props.refresh();
            })
            .catch(() => {
                setLoading(false);
                alert("Unable to save specifications!");
            });
    }

    return <>
        {visible && <Window
            title="Home Specifications" modal
            onClose={() => setVisible(false)}
            initialWidth={Math.min(window.innerWidth, 600)}
            initialHeight={650}
        >
            <form className="k-form k-form-md p-0">
                <FieldWrapper>
                    <NumericTextBox
                        label="Year Built"
                        placeholder="yyyy"
                        value={yearBuilt.value}
                        onChange={(e) => setYearBuilt({ value: e.value || 0, error: '' })}
                        format={{ useGrouping: false, maximumFractionDigits: 0 }}
                        max={3000}
                        min={1000}
                    />
                    <Error>{yearBuilt.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <NumericTextBox
                        label="No. of Bedrooms"
                        value={bedrooms.value}
                        onChange={(e) => setBedrooms({ value: e.value || 0, error: '' })}
                        format="n0"
                        max={100}
                        min={1}
                    />
                    <Error>{bedrooms.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <NumericTextBox
                        label="No. of Bathrooms"
                        value={bathrooms.value}
                        onChange={(e) => setBathrooms({ value: e.value || 0, error: '' })}
                        format="n0"
                        max={100}
                        min={1}
                    />
                    <Error>{bathrooms.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <NumericTextBox
                        label={`Living Space? (${getPropertyLotSizeUnit(0)})`}
                        value={squareFeet.value}
                        onChange={(e) => setSquareFeet({ value: e.value || 0, error: '' })}
                        format="n0"
                        min={1}
                    />
                    <Error>{squareFeet.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <NumericTextBox
                        label={`Lot Size? (${getPropertyLotSizeUnit(lotSizeUnit)})`}
                        value={lotSize.value}
                        onChange={(e) => setLotSize({ value: e.value || 0, error: '' })}
                        min={0}
                    />
                    <Error>{lotSize.error}</Error>
                </FieldWrapper>
                <RadioGroup data={lotSizeUnits} layout="horizontal" value={lotSizeUnit} onChange={(e) => setLotSizeUnit(e.value)} />
                <FieldWrapper>
                    <DropDownList label="Unit Type" textField="Name" value={unitTypes.find(x => x.ID === unitType.value)} data={unitTypes} onChange={(e) => setUnitType({ value: e.value.ID, error: '' })} />
                    <Error>{unitType.error}</Error>
                </FieldWrapper>
            </form>
            <div className="text-center">
                <Button onClick={() => setVisible(false)}>Cancel</Button>
                &nbsp;&nbsp;
                <Button themeColor="primary" disabled={loading} onClick={_save}>Save</Button>
            </div>
        </Window>}
        <div className="groupbox groupbox-editable col-md-12" onClick={() => setVisible(true)}>
            <p className="property-panel-title">Home Specifications</p>
            {props.property.Status > 30 ? <div className="row">
                <div className="col-md">
                    <div className="fw-bold">Year Built</div>
                    <div>{props.property.Specifications.YearBuilt}</div>
                </div>
                <div className="col-md">
                    <div className="fw-bold">Bedrooms</div>
                    <div>{props.property.Specifications.Bedrooms}</div>
                </div>
                <div className="col-md">
                    <div className="fw-bold">Bathroom</div>
                    <div>{props.property.Specifications.Bathrooms}</div>
                </div>
                <div className="col-md">
                    <div className="fw-bold">Living Space</div>
                    <div>{formatNumber(props.property.Specifications.SquareFeet)} {getPropertyLotSizeUnit(0)}</div>
                </div>
                <div className="col-md">
                    <div className="fw-bold">Lot Size</div>
                    <div>{formatNumber(props.property.Specifications.LotSize)} {getPropertyLotSizeUnit(props.property.Specifications.LotSizeUnit)}</div>
                </div>
                <div className="col-md">
                    <div className="fw-bold">Unit Type</div>
                    <div>{getPropertyUnitType(props.property.Specifications.UnitType)}</div>
                </div>
            </div> : <div className="row"><div className="mx-auto">
                <button type="button" className="btn btn-primary">Home Specifications</button>
            </div></div>}
        </div>
    </>
}

export default PropertyProfileSpecifications;
