import { IDName } from "./generic";

export enum AgentLevel {
    ScoutBasic,
    Contractor,
    ScoutPremium,
    Agent,
    AdvancedAgent,
    Dialer
}

export type AgentViewModel = {
    AgentID: number;
    StripeCustomerID: string;
    FullName: string;
    FirstName: string;
    LastName: string;
    AgentLevelDesc: string;
    Level: AgentLevel;
    Email: string;
    PhoneNumber: string;
    Active: boolean;
    SubscriptionExpired: boolean;
    HowYouHeard: string;
    AppReferralID: null | number;
    CreatedDateTime: Date;
}

export function getAgentLevel(agentLevel: AgentLevel): string {
    switch (agentLevel) {
        case AgentLevel.ScoutBasic:
            return "Scout Partner";
        case AgentLevel.Contractor:
            return "Contractor";
        case AgentLevel.ScoutPremium:
            return "Scout Premium";
        case AgentLevel.AdvancedAgent:
            return "Advanced Agent";
        default:
            return AgentLevel[agentLevel];
    }
}

export function getAgentLevels() {
    const agentLevelOptions: IDName[] = [];
    for (var enumMember in AgentLevel) {
      const num = Number(enumMember);
      if (!isNaN(num)) {
        agentLevelOptions.push({ ID: num, Name: getAgentLevel(num) });
      }
    }
    return agentLevelOptions;
  }
