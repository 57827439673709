import { useEffect, useState } from 'react';

const useTimeoutState = <T>(timeoutMs: number): [T | undefined, (value: T | undefined) => void] => {
    const [value, setValue] = useState<T>();
    useEffect(() => {
        if (value != null) {
            setTimeout(() => {
                setValue(undefined);
            }, timeoutMs);
        }
    }, [value, setValue, timeoutMs]);

    return [value, setValue];
};

export default useTimeoutState;
