import { useEffect, useState } from 'react';
import { Scheduler, DayView, AgendaView, WeekView, MonthView, SchedulerItemProps, SchedulerItem } from '@progress/kendo-react-scheduler';
import { Dialog, DialogActionsBar, Window } from "@progress/kendo-react-dialogs";
import { fetchApi } from '../../services/api';
import Moment from 'moment';
import { Button } from '@progress/kendo-react-buttons';

type Props = {
  PropertyID: number;
  CloseDialog: () => void;
}

type PropertyViewingType = {
  PropertyViewingID: number;
  ClientName: string;
  Start: string | Date;
  End: string | Date;
  Agent: AgentType;
}

type AgentType = {
  FullName: string;
  Email: string;
  PhoneNumber: string;
}

const PropertyProfileViewings = (props: Props) => {

  const [viewings, setViewings] = useState<PropertyViewingType[]>([]);
  const [viewing, setViewing] = useState<PropertyViewingType>();

  useEffect(() => {
    fetchApi(`/api/Property/PropertyViewings/${props.PropertyID}`)
      .then((response: { Viewings: PropertyViewingType[] }) => {
          setViewings(response.Viewings.map(x => { return { ...x, Start: new Date(x.Start), End: new Date(x.End) }}));
      }).catch((e) => {
          console.error(e);
      });
  }, [props.PropertyID]);

  const CustomItem = (props: SchedulerItemProps) => {
    return (
      <SchedulerItem onClick={e => setViewing(props.dataItem)} {...props}>
        {props.children}
      </SchedulerItem>
    );
  };

  return <Window
    title={"Viewings"}
    onClose={props.CloseDialog}
    stage="FULLSCREEN"
    resizable={false}
  >
    {viewing && <Dialog
      title="Viewing Request"
      onClose={() => setViewing(undefined)}
    >
      <p style={{ margin: "25px", textAlign: "center" }}>
        {Moment(viewing.Start).local().format("h:mm a")} - {Moment(viewing.End).local().format("h:mm a")}<br />
        Agent: {viewing.Agent.FullName}<br />
        Client: {viewing.ClientName}<br />
        <a href={`mailto:${viewing.Agent.Email}`}>{viewing.Agent.Email}</a><br />
        <a href={`tel:${viewing.Agent.PhoneNumber}`}>{viewing.Agent.PhoneNumber}</a>
      </p>
      <DialogActionsBar>
        <Button themeColor="primary" onClick={() => setViewing(undefined)}>Close</Button>
      </DialogActionsBar>
    </Dialog>}
    <Scheduler
      data={viewings}
      item={CustomItem}
      defaultView="day"
      height="auto"
      modelFields={{
        id: "PropertyViewingID",
        title: "Agent.FullName",
        start: "Start",
        end: "End",
      }}
    >
      <AgendaView />
      <DayView />
      <WeekView />
      <MonthView />
    </Scheduler>
  </Window>;
}

export default PropertyProfileViewings;