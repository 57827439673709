import * as React from 'react';
import LoadingPanel from '../LoadingPanel';
import { State as GridState, SortDescriptor, CompositeFilterDescriptor, toDataSourceRequestString, FilterDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridSortChangeEvent, GridDataStateChangeEvent, GridFilterChangeEvent, GridToolbar, GridCellProps } from '@progress/kendo-react-grid';
import { fetchApi } from '../../services/api';
import { drawRefreshButtonOnGridFooter } from '../../utils/kendo';
import { PhoneNumberCell } from '../PhoneNumberCell';
import { DateTimeCell } from '../DateTimeCell';
import { debounce } from 'ts-debounce';
import { Link } from 'react-router-dom';
import { EmailAddressCell } from '../EmailAddressCell';
import { Title } from '../../utils/title';

type Props = {}

export type EscrowCompanyViewModel = {
    EscrowCompanyID: number;
    EscrowCompanyName: string;

    FirstName: string;
    LastName: string;
    Email: string;

    StreetNumber: string;
    StreetName: string;
    UnitNumber: string;
    City: string;
    StateCode: string;
    ZipCode: string;
    StreetAddress: string;
    PhoneNumber: string;
    WireInstructions: string;

    CreatedDateTime: Date;
}

type State = {
    isLoading: boolean;
    data: EscrowCompanyViewModel[];
    skip: number;
    take: number;
    sort: SortDescriptor[];
    filter: CompositeFilterDescriptor;
    dataState: GridState;
    totalRecords: number;
    isAdmin: boolean;
}

const EscrowCompanyNameCell = (props: GridCellProps) => {
    const dataItem = props.dataItem as EscrowCompanyViewModel;
    return (
        <td>
            <Link to={`/EscrowCompany/${dataItem.EscrowCompanyID}/EscrowCompanyProfile`} style={{ color: '#007bff' }}>
                {dataItem.EscrowCompanyName}
            </Link>
        </td >
    );
}

export default class EscrowCompany extends React.Component<Props, State> {

    private CONST_TAKE: number = 50;

    private CONST_DATASTATE_FILTER_DEFAULT = {
        logic: 'and',
    } as CompositeFilterDescriptor;

    private CONST_DATASTATE_DEFAULT = {
        take: this.CONST_TAKE, skip: 0,
        sort: [{ field: "EscrowCompanyName", dir: "asc" }],
        filter: { ...this.CONST_DATASTATE_FILTER_DEFAULT }
    } as GridState;
    //filter: {
    //    logic: "and",
    //    filters: [{ field: "Active", operator: "eq", value: true }]
    //} as CompositeFilterDescriptor,

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            data: [],
            totalRecords: 0,
            dataState: { ...this.CONST_DATASTATE_DEFAULT },
            skip: 0,
            take: 50,
            sort: [],
            filter: {} as CompositeFilterDescriptor,
            isAdmin: false
        }

        this.dataStateChange = this.dataStateChange.bind(this);
    }

    private dataStateChange(changeEvent: GridDataStateChangeEvent) {
        this.setState({ dataState: changeEvent.dataState }, () => { this.fetch() });
    }

    sortChange = (event: GridSortChangeEvent) => {
        const dS = this.state.dataState;
        dS.sort = event.sort;
        this.setState({ dataState: dS }, () => { this.fetch() });
    }

    filterChange = (event: GridFilterChangeEvent) => {
        let state = this.state;
        if (event.filter) {
            //let filters = (event.filter.filters as FilterDescriptor[]).filter(x => x.value !== null); //ensure that the requested filters aren't null (due to switching lt or eq params)
            let filters = (event.filter.filters as FilterDescriptor[]); //ensure that the requested filters aren't null (due to switching lt or eq params)
            if (state.dataState.filter && filters.length) {
                state.dataState.filter.filters = filters;
                this.setState(state, () => { this.filterInputChangeDebounced() });
            }

        } else if (state.dataState.filter && state.dataState.filter.filters.length) { //if no filter being requested, but we have some in state, empty it            
            state.dataState.filter.filters = [];
            this.setState(state, () => { this.filterInputChangeDebounced() });
        }
    }

    private filterInputChangeDebounced = debounce(this.filterInputChange, 500);
    private filterInputChange(): Promise<boolean> {
        this.fetch();
        return Promise.resolve(false);
    }

    public render() {
        return (<>
            <Title string="Escrow Companies" />
            <h3 className="mb-2">Escrow Companies</h3>
            {this.state.isLoading && <LoadingPanel />}
            <Grid
                scrollable="none"
                pageable={{ pageSizes: [50, 100, 200] }}
                total={this.state.totalRecords}
                data={this.state.data}
                {...this.state.dataState}
                onDataStateChange={this.dataStateChange}
                resizable={true}
                filterable
                onFilterChange={this.filterChange}
                onSortChange={this.sortChange}
                sortable={{
                    allowUnsort: true,
                    mode: 'multiple'
                }}
            >
                <GridToolbar>
                    <Link
                        hidden={!this.state.isAdmin}
                        to="/escrowcompany/new/escrowcompanyprofile"
                        role="button"
                        className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-primary k-rounded-md"
                        title="Submit Property"
                    >
                        <span style={{color:'white'}}>Add Escrow Company</span>
                    </Link>
                </GridToolbar>
                <Column field="EscrowCompanyName" title="Company" cell={EscrowCompanyNameCell} />
                <Column field="StreetAddress" title="Address" />
                <Column field="City" />
                <Column field="StateCode" title="State" />
                <Column field="ZipCode" title="Zip" />
                <Column field="PhoneNumber" title="Phone" cell={PhoneNumberCell} />
                <Column field="Email" cell={EmailAddressCell} />
                <Column field="CreatedDateTime" title="Created Date" cell={DateTimeCell} filterable={false} />
            </Grid>
        </>);
    }

    public componentDidMount() {
        this.fetch();

        drawRefreshButtonOnGridFooter(() => { this.fetch() });
    }

    private fetch() {
        this.setState({
            isLoading: true
        })

        const dataGridState = this.state.dataState;
        let queryStr = `${toDataSourceRequestString(dataGridState)}`;

        fetchApi(`/api/EscrowCompany/List?${queryStr}`)
            .then((response: any) => {
                this.setState({ isLoading: false, data: response.DataSourceResult.Data, totalRecords: response.DataSourceResult.Total, isAdmin: response.isAdmin });
            })
    }
}