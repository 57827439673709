import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import LoadingPanel from 'components/LoadingPanel';
import { Link } from 'react-router-dom';
import { Title } from 'utils/title';
import { ConstructionProjectViewModel } from 'TypeGen/construction-project-view-model';
import { useQuery } from '@tanstack/react-query';
import { fetchApi } from 'services/api';

export type ProjectSummary = {
    ConstructionProjectID: number;
    Name: string;
    Active: boolean;
    ScopeOfWork: string;
};

const Projects = () => {
    const { data: projects, isLoading } = useQuery({
        queryKey: ['projects'],
        queryFn: async () => {
            const res: ConstructionProjectViewModel[] = await fetchApi(`/api/ConstructionProject/List`);

            return res;
        },
        // placeholderData: exampleTaskData,
    });

    return (
        <div>
            <Title string="Projects" />
            {isLoading && <LoadingPanel />}
            {projects && (
                <Grid data={projects}>
                    <Column
                        field="Name"
                        title="Project Name"
                        cell={(props) => (
                            <td>
                                <Link style={{ color: '#007bff' }} to={`/Construction/Project/${props.dataItem.ConstructionProjectID}`}>
                                    {props.dataItem.Name ?? 'undefined'}
                                </Link>
                            </td>
                        )}
                    />
                    <Column field="Active" title="Active" cell={(props) => <td>{props.dataItem.Active ? 'Yes' : 'No'}</td>} />
                </Grid>
            )}
        </div>
    );
};

export default Projects;
