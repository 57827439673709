import * as React from 'react';
import { Avatar } from '@progress/kendo-react-layout';
//import { getInvestorLevel, InvestorViewModel } from '../../utils/agent';
import { formatPhoneNumberIntl } from '../../utils/generic';
import { avatarType as AvatarType } from '@progress/kendo-react-layout/dist/npm/card/interfaces/Enums';
import { InvestorViewModel } from '../../utils/investor';

type Props = {
    hideLinks?: boolean;
    title?: string;
    titleClassName?: string;
    investor: InvestorViewModel;
    onIconClicked?: (investor: InvestorViewModel) => void;
}

type AvatarViewModel = {
    avatar: string;
    avatarType: AvatarType;
};
export type InvestorAvatarViewModel = InvestorViewModel & AvatarViewModel;

type State = {
    avatar: InvestorAvatarViewModel | null;
}

export default class InvestorAvatar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            avatar: null
        }
    }

    public render() {
        return (<>
            {this.state.avatar &&
                <>
                    <div className="groupbox" style={{ position: "relative" }}>
                        {this.props.onIconClicked && <a
                            href="/#"
                            title="Unassign Investor?"
                            style={{ position: "absolute", top: "-14px", right: "-8px", textDecoration: "none" }}
                            onClick={(e) => {
                                if (this.props.onIconClicked) {
                                    if (window.confirm("Unassign Investor?")) {
                                        this.props.onIconClicked(this.props.investor);
                                    }
                                }
                                e.preventDefault();
                            }}
                        >
                            <span className="k-icon k-i-minus-outline" style={{ fontSize: "20px" }} />
                        </a>}

                        {this.props.title && <div className={this.props.titleClassName} style={{ textAlign: 'left', padding: 4 }}>{this.props.title}</div>}
                        <div className="k-hbox">
                            {
                                this.state.avatar.InvestorID === 7 ?
                                    <Avatar type='image'><img src='/images/profilePhoto.jpg' alt='' /></Avatar> :
                                    <Avatar type='text'>{this.state.avatar.avatar}</Avatar>
                            }
                            <div style={{ wordBreak: "break-word" }}>
                                <h2 style={{ fontSize: '1.3em', fontWeight: 'normal', margin: 0 }}>{this.state.avatar.FullName} - {this.state.avatar.InvestorTypes}</h2>
                                <p style={{ margin: '0', fontSize: '0.9em' }}>
                                    {this.props.hideLinks ? this.state.avatar.Email : <a
                                        href={`mailto:${this.state.avatar.Email}`}
                                        style={{ color: '#007bff' }}
                                    >{this.state.avatar.Email}
                                    </a>}
                                </p>
                                <p style={{ margin: '0', fontSize: '0.9em' }}>
                                    {this.props.hideLinks ? this.state.avatar.PhoneNumber : <a
                                        href={`tel:${this.state.avatar.PhoneNumber}`}
                                        style={{ color: '#007bff' }}
                                    >{this.state.avatar.PhoneNumber}
                                    </a>}
                                </p>
                            </div>
                        </div>

                    </div>
                </>
            }
        </>);
    }

    public componentDidMount() {
        const avatar = Object.assign({}, this.state.avatar, {
            InvestorID: this.props.investor.InvestorID,
            InvestorTypes: this.props.investor.InvestorTypes?.replace('|', ', '),
            Email: this.props.investor.Email,
            FullName: this.props.investor.FullName,
            avatar: `${this.props.investor.FullName.charAt(0)}${this.props.investor.FullName.toUpperCase().split(" ")[1].charAt(0)}`,
            PhoneNumber: formatPhoneNumberIntl(this.props.investor.PhoneNumber),
            avatarType: AvatarType.TEXT,
        });
        this.setState({ avatar });
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.props.investor.InvestorID !== prevProps.investor.InvestorID) {
            this.componentDidMount();
        }
    }
}