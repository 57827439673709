import React, { useState } from 'react';
import Moment from 'moment';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import AgentAvatar from '../Agent/AgentAvatar';
import { toYesNo, formatCurrency } from '../../utils/generic';
import { getPropertyEscrow, getPropertyOfferFinanceType, getPropertyOfferStatus, getPropertyTitle, PropertyOffer, PropertyOfferStatus } from '../../utils/property';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { TextArea } from '@progress/kendo-react-inputs';
import { fetchApi } from '../../services/api';
import { Button } from '@progress/kendo-react-buttons';

type Props = {
    propertyID: number;
    item: PropertyOffer;
    index: number;
    refresh: () => void;
}

const PropertyProfileOffer = (props: Props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const [offerStatusDialog, setOfferStatusDialog] = useState({
        offerStatus: PropertyOfferStatus.Submitted,
        showDialog: false        
    });
    const [counterReason, setCounterReason] = useState<string>();

    function getOfferStatusColor(propertyOfferStatus: PropertyOfferStatus) {
        switch (propertyOfferStatus) {
            case PropertyOfferStatus.Accepted:
                return "bg-primary";
            case PropertyOfferStatus.Countered:
                return "bg-info";
            case PropertyOfferStatus.Rejected:
                return "bg-warning";
            default:
                return "bg-secondary";
        }
    }

    const _getOfferStatusVerb = (propertyOfferStatus: PropertyOfferStatus): string => {
        switch (propertyOfferStatus) {
            case PropertyOfferStatus.Accepted:
                return "Accept"
            case PropertyOfferStatus.Rejected:
                return "Reject"
            case PropertyOfferStatus.Countered:
                return "Counter"
        }
        return "Pending Decision"
    };

    const _save = () => {
        const data = {
            PropertyID: props.propertyID,
            PropertyOfferID: props.item.PropertyOfferID,
            PropertyOfferStatus: offerStatusDialog.offerStatus,
            Reason: counterReason
        };

        fetchApi('/api/Property/UpdatePropertyOfferStatus', data, 'POST')
            .then(() => {
                props.refresh();
                setCounterReason("");
            })
            .catch(() => {
                alert("Unable to save");
                setCounterReason("");
            });
    };

    return (
        <React.Fragment>
            {props.index > 0 && <hr />}
            <div className="row">
                <div className="col-lg-6">
                    <div style={{ fontSize: "13px" }}><label className="fw-bold">Offer Date:</label>&nbsp;
                        {Moment.utc(props.item.SubmittedDateTime).local().format("MM/DD/YYYY HH:mm")}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-2">
                    <div className="fw-bold">Offer Price</div>
                    <div>{formatCurrency(props.item.Price)}</div>
                </div>
                <div className="col-lg-2">
                    <div className="fw-bold">Earnest Deposit [Days]</div>
                    <div>{formatCurrency(props.item.EarnestMoneyDeposit)} [{props.item.EarnestMoneyDepositDays}]</div>
                </div>
                <div className="col-lg-2">
                    <div className="fw-bold">Finance Type</div>
                    <div>{getPropertyOfferFinanceType(props.item.FinanceType)}</div>
                </div>
                <div className="col-lg-2">
                    <AgentAvatar agent={props.item.SubmittedAgent} />
                </div>
                <div className="col-lg-2">
                    {/*<label className="fw-bold" style={{ marginBottom: "-10px", display: "table-row" }}>Status</label>*/}
                    {props.item.OfferStatus === PropertyOfferStatus.Submitted ?
                        <Dropdown
                            isOpen={dropdownOpen}
                            toggle={toggle}
                        >
                            <DropdownToggle caret>
                                PENDING DECISION
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem onClick={() => setOfferStatusDialog({
                                    showDialog: !offerStatusDialog.showDialog,
                                    offerStatus: PropertyOfferStatus.Accepted
                                })}>Accept</DropdownItem>
                                <DropdownItem onClick={() => setOfferStatusDialog({
                                    showDialog: !offerStatusDialog.showDialog,
                                    offerStatus: PropertyOfferStatus.Rejected
                                })}>Reject</DropdownItem>
                                <DropdownItem onClick={() => setOfferStatusDialog({
                                    showDialog: !offerStatusDialog.showDialog,
                                    offerStatus: PropertyOfferStatus.Countered
                                })}>Counter</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        :
                        <h4><span style={{ padding: "8px" }} className={getOfferStatusColor(props.item.OfferStatus) + " badge fw-normal"}>{getPropertyOfferStatus(props.item.OfferStatus).toUpperCase()}</span></h4>
                    }

                </div>
            </div>

            <div className="row" style={{ marginTop: "2px" }}>
                <div className="col-lg-2">
                    <div className="fw-bold">Escrow Fees Paid By [Days]</div>
                    <div>{getPropertyEscrow(props.item.Escrow)} [{props.item.CloseOfEscrowDays}]</div>
                </div>
                <div className="col-lg-2">
                    <div className="fw-bold">Title Fees Paid By</div>
                    <div>{getPropertyTitle(props.item.Title)}</div>
                </div>
                <div className="col-lg-2">
                    <div className="fw-bold">Client</div>
                    <div>{props.item.ClientFullName}</div>
                </div>
                {
                    props.item.ReasonForRejecting &&
                    <div className="col-lg-2">
                        <div className="fw-bold">Offer Response</div>
                        <div>{props.item.ReasonForRejecting}</div>
                    </div>
                }
            </div>
            <div className="row" style={{ marginTop: "2px" }}>
                <div className="col-lg-2 offset-lg-4">
                    <div className="fw-bold">Closing Cost Credit</div>
                    <div>{formatCurrency(props.item.ClosingCostCreditAmount)}</div>
                </div>
                <div className="col-lg-2">
                    <div className="fw-bold">Escalation Clause</div>
                    <div>{toYesNo(props.item.EscalactionClause)}{(props.item.EscalactionClause === true && props.item.EscalactionClauseReason) ? `: ${props.item.EscalactionClauseReason}` : ""}</div>
                </div>
                <div className="col-lg-2">
                    <div className="fw-bold">Notes</div>
                    <div>{props.item.Notes}</div>
                </div>
            </div>
            {
                offerStatusDialog.showDialog && <Dialog title={_getOfferStatusVerb(offerStatusDialog.offerStatus) + " Offer?"} minWidth={350}
                    onClose={() => setOfferStatusDialog({
                        ...offerStatusDialog,
                        showDialog: !offerStatusDialog.showDialog
                    })}>
                    {
                        (offerStatusDialog.offerStatus === PropertyOfferStatus.Countered || offerStatusDialog.offerStatus === PropertyOfferStatus.Rejected) &&
                        <p style={{ margin: "25px", textAlign: "center" }}>
                            <TextArea placeholder="Enter reason..." value={counterReason} onChange={(e: any) => setCounterReason(e.value)} />
                        </p>
                    }
                    <DialogActionsBar>
                        <Button onClick={() => setOfferStatusDialog({
                            ...offerStatusDialog,
                            showDialog: !offerStatusDialog.showDialog
                        })}>CANCEL</Button>
                        <Button disabled={offerStatusDialog.offerStatus !== PropertyOfferStatus.Accepted && (counterReason === undefined || counterReason.length === 0)}
                            themeColor="primary"
                            onClick={() => {
                                setOfferStatusDialog({
                                    ...offerStatusDialog,
                                    showDialog: !offerStatusDialog.showDialog
                                });
                                _save();
                            }}>{_getOfferStatusVerb(offerStatusDialog.offerStatus).toUpperCase()} OFFER</Button>
                    </DialogActionsBar>
                </Dialog>
            }
        </React.Fragment>
    );
}

export default PropertyProfileOffer;