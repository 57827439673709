import { useEffect, useState } from 'react';
import { fetchApi } from '../../services/api';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Error, Label, Hint } from '@progress/kendo-react-labels';
import { getPropertyInvestmentType, getPropertyInvestmentTypes, PropertyImage, PropertyInvestmentSpecification, PropertyInvestorOfferingStatus, PropertyViewModel } from '../../utils/property';
import { formatCurrency, isExpired, isNullOrEmptyValidator, isPositiveValidator, isValidUrl, JsonResponse } from '../../utils/generic';
import { Window } from '@progress/kendo-react-dialogs';
import { Upload } from '@progress/kendo-react-upload';
import { Checkbox, Input, NumericTextBox, TextArea } from '@progress/kendo-react-inputs';
import { DatePicker, DateTimePicker } from '@progress/kendo-react-dateinputs';
import Moment from 'moment';
import { AutoComplete, DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { CompositeFilterDescriptor, FilterDescriptor, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { EscrowCompanyViewModel } from '../EscrowCompany';
import { useDebouncedCallback } from 'use-debounce';
import { InvestorViewModel } from '../../utils/investor';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { formatNumber } from '@telerik/kendo-intl';
import { PropertyInvestmentType } from 'TypeGen/property-investment-type';

type Props = {
    property: PropertyViewModel;
    InvestmentSpecification: PropertyInvestmentSpecification;
    images: PropertyImage[];
    refresh: () => void;
}

const investmentTypeOptions = getPropertyInvestmentTypes();

const PropertyProfileInvestment = (props: Props) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);

    const [investmentType, setInvestmentType] = useState(props.InvestmentSpecification.InvestmentType);
    const [badge, setBadge] = useState({ value: props.InvestmentSpecification.Badge, error: '' });

    // Rental
    const [estimatedMonthlyExpenses, setEstimatedMonthlyExpenses] = useState({ value: props.InvestmentSpecification.EstimatedMonthlyExpenses, error: '' });
    const [estimatedMonthlyRent, setEstimatedMonthlyRent] = useState({ value: props.InvestmentSpecification.EstimatedMonthlyRent, error: '' });
    const [estimatedNightlyRate, setEstimatedNightlyRate] = useState({ value: props.InvestmentSpecification.EstimatedNightlyRate, error: '' });
    const [occupancyRate, setOccupancyRate] = useState({ value: props.InvestmentSpecification.OccupancyRate, error: '' });
    const [appreciationRate, setAppreciationRate] = useState({ value: props.InvestmentSpecification.AppreciationRate, error: '' });

    // B52
    const [escrowPurchaseDateTime, setEscrowPurchaseDateTime] = useState({ value: props.InvestmentSpecification.EscrowPurchaseDateTime ?? new Date(), error: '' });
    const [estimatedInvestmentAmount, setEstimatedInvestmentAmount] = useState({ value: props.InvestmentSpecification.EstimatedInvestmentAmount, error: '' });
    const [minInvestmentAmount, setMinInvestmentAmount] = useState({ value: props.InvestmentSpecification.MinInvestmentAmount, error: '' });
    const [maxInvestmentAmount, setMaxInvestmentAmount] = useState({ value: props.InvestmentSpecification.MaxInvestmentAmount, error: '' });
    const [loanPayoffAmount, setLoanPayoffAmount] = useState({ value: props.InvestmentSpecification.LoanPayoffAmount, error: '' });
    const [depositAmount, setDepositAmount] = useState({ value: props.InvestmentSpecification.DepositAmount, error: '' });
    //const [finalPrice, setFinalPrice] = useState({ value: props.InvestmentSpecification.FinalPrice, error: '' });
    const [purchasePrice, setPurchasePrice] = useState({ value: props.InvestmentSpecification.PurchasePrice, error: '' });

    const [estimatedConstructionBudget, setEstimatedConstructionBudget] = useState({ value: props.InvestmentSpecification.RehabCost, error: '' });
    const [afterRemodelValue, setAfterRemodelValue] = useState({ value: props.InvestmentSpecification.AfterRepairValue, error: '' });

    const [totalInvestors, setTotalInvestors] = useState({ value: props.InvestmentSpecification.TotalInvestors, error: '' });

    const [estimatedSplit, setEstimatedSplit] = useState({ value: props.InvestmentSpecification.EstimatedSplit || '', error: '' });
    const [investmentOfferNote, setInvestmentOfferNote] = useState({ value: props.InvestmentSpecification.InvestmentOfferNote || '', error: '' });
    const [constructionScopeOfWork, setConstructionScopeOfWork] = useState({value: props.InvestmentSpecification.ConstructionScopeOfWork || '', error: ''});
    const [expiryDateTime, setExpiryDateTime] = useState({ value: props.InvestmentSpecification.ExpiryDateTime, error: '' });
    const [percentFunded, setPercentFunded] = useState({ value: props.InvestmentSpecification.PercentFunded, error: '' });
    const [friendlyName, setFriendlyName] = useState({ value: props.InvestmentSpecification.FriendlyName, error: '' });
    const [listingLink, setListingLink] = useState({ value: props.InvestmentSpecification.ListingLink || '', error: '' });
    const [depositDue, setDepositDue] = useState({ value: props.InvestmentSpecification.DepositDue || 'ASAP', error: '' });

    const [, setImages] = useState(props.images.map(x => x.PropertyImageID.toString()));

    const [escrowCompany, setEscrowCompany] = useState({ value: props.InvestmentSpecification.AssignedEscrowCompany ? props.InvestmentSpecification.AssignedEscrowCompany.EscrowCompanyName : '', error: '' });
    const [escrowCompanyData, setEscrowCompanyData] = useState<EscrowCompanyViewModel[]>([]);
    const [escrowCompanyLoading, setEscrowCompanyLoading] = useState(false);

    const [ndaRequired, setNDARequired] = useState(props.InvestmentSpecification.NDARequired);
    const [ndaLink, setNDALink] = useState({ value: props.InvestmentSpecification.NDALink || '', error: '' });

    const [investors, setInvestors] = useState<InvestorViewModel[]>([]);
    const [investorSearchData, setInvestorSearchData] = useState<InvestorViewModel[]>([]);
    const [investorSearchLoading, setInvestorSearchLoading] = useState(false);

    // Useful when saving as a new offering
    useEffect(() => {
        setInvestmentType(props.InvestmentSpecification.InvestmentType);
        setBadge({ value: props.InvestmentSpecification.Badge, error: '' });
        setEstimatedMonthlyExpenses({ value: props.InvestmentSpecification.EstimatedMonthlyExpenses, error: '' });
        setEstimatedMonthlyRent({ value: props.InvestmentSpecification.EstimatedMonthlyRent, error: '' });
        setEstimatedNightlyRate({ value: props.InvestmentSpecification.EstimatedNightlyRate, error: '' });
        setOccupancyRate({ value: props.InvestmentSpecification.OccupancyRate, error: '' });
        setAppreciationRate({ value: props.InvestmentSpecification.AppreciationRate, error: '' });

        setEscrowPurchaseDateTime({ value: props.InvestmentSpecification.EscrowPurchaseDateTime ?? new Date(), error: '' });
        setEstimatedInvestmentAmount({ value: props.InvestmentSpecification.EstimatedInvestmentAmount, error: '' });
        setMinInvestmentAmount({ value: props.InvestmentSpecification.MinInvestmentAmount, error: '' });
        setMaxInvestmentAmount({ value: props.InvestmentSpecification.MaxInvestmentAmount, error: '' });
        setLoanPayoffAmount({ value: props.InvestmentSpecification.LoanPayoffAmount, error: '' });
        setDepositAmount({ value: props.InvestmentSpecification.DepositAmount, error: '' });
        //setFinalPrice({ value: props.InvestmentSpecification.FinalPrice, error: '' });
        setPurchasePrice({ value: props.InvestmentSpecification.PurchasePrice, error: '' });

        setEstimatedConstructionBudget({ value: props.InvestmentSpecification.RehabCost, error: '' });
        setAfterRemodelValue({ value: props.InvestmentSpecification.AfterRepairValue, error: '' });

        setTotalInvestors({ value: props.InvestmentSpecification.TotalInvestors, error: '' });

        setEstimatedSplit({ value: props.InvestmentSpecification.EstimatedSplit || '', error: '' });
        setInvestmentOfferNote({ value: props.InvestmentSpecification.InvestmentOfferNote || '', error: '' });
        setConstructionScopeOfWork({value: props.InvestmentSpecification.ConstructionScopeOfWork || '', error : ''});
        setExpiryDateTime({ value: props.InvestmentSpecification.ExpiryDateTime, error: '' });
        setPercentFunded({ value: props.InvestmentSpecification.PercentFunded, error: '' });
        setFriendlyName({ value: props.InvestmentSpecification.FriendlyName, error: '' });
        setListingLink({ value: props.InvestmentSpecification.ListingLink || '', error: '' });
        setDepositDue({ value: props.InvestmentSpecification.DepositDue || 'ASAP', error: '' });

        setImages(props.images.map(x => x.PropertyImageID.toString()));

        setEscrowCompany({ value: props.InvestmentSpecification.AssignedEscrowCompany ? props.InvestmentSpecification.AssignedEscrowCompany.EscrowCompanyName : '', error: '' });
        setEscrowCompanyData([]);

        setNDARequired(props.InvestmentSpecification.NDARequired);
        setNDALink({ value: props.InvestmentSpecification.NDALink || '', error: '' });

        setInvestors([]);
        setInvestorSearchData([]);
    }, [props.InvestmentSpecification, props.images]);

    const searchEscrowCompany = useDebouncedCallback((value: string) => {
        const dataGridState = {
            take: 15,
            sort: [{ field: "EscrowCompanyName", dir: "asc" }],
            filter: {
                logic: 'and', filters: [{ field: 'EscrowCompanyName', ignoreCase: true, value: value, operator: 'contains' } as FilterDescriptor] as FilterDescriptor[]
            } as CompositeFilterDescriptor
        } as GridState;
        let queryStr = `${toDataSourceRequestString(dataGridState)}`;

        fetchApi(`/api/EscrowCompany/List?${queryStr}`)
            .then((response: any) => {
                setEscrowCompanyData(response.DataSourceResult.Data);
                setEscrowCompanyLoading(false);
            }).catch(() => {
                alert('Error!');
                setEscrowCompanyLoading(false);
            });
    }, 250);

    const searchInvestors = useDebouncedCallback((filter: FilterDescriptor) => {
        const dataGridState = {
            take: 15,
            sort: [{ field: "FullName", dir: "asc" }],
            filter: {
                logic: 'and', filters: [filter] as FilterDescriptor[]
            } as CompositeFilterDescriptor
        } as GridState;
        let queryStr = `${toDataSourceRequestString(dataGridState)}`;

        fetchApi(`/api/Investor/List?${queryStr}`)
            .then((response: any) => {
                setInvestorSearchData(response.DataSourceResult.Data);
                setInvestorSearchLoading(false);
            }).catch(() => {
                alert('Error!');
                setInvestorSearchLoading(false);
            });
    }, 250);

    const _save = (incrementPropertyStatus: number = 1, saveNewOffering: boolean = false) => {

        // Rental
        let estimatedMonthlyExpensesError = '';
        let estimatedMonthlyRentError = '';
        let estimatedNightlyRateError = '';
        let occupancyRateError = '';
        let appreciationRateError = '';

        if (investmentType === PropertyInvestmentType.ShortTerm || investmentType === PropertyInvestmentType.LongTerm) {
            estimatedMonthlyExpensesError = isPositiveValidator(estimatedMonthlyExpenses.value, 'Estimated Monthly Expenses is required.');
            occupancyRateError = isPositiveValidator(occupancyRate.value, 'Occupancy % is required.');
            appreciationRateError = isPositiveValidator(appreciationRate.value, 'Annual Property Appreciation % is required.');
        }

        if (investmentType === PropertyInvestmentType.ShortTerm) {
            estimatedNightlyRateError = isPositiveValidator(estimatedNightlyRate.value, 'Estimated Nightly Rate is required.');
        }

        if (investmentType === PropertyInvestmentType.LongTerm) {
            estimatedMonthlyRentError = isPositiveValidator(estimatedMonthlyRent.value, 'Estimated Monthly Rent is required.');
        }

        // B52
        let purchasePriceError = '';
        let loanPayoffAmountError = '';
        let estimatedConstructionBudgetError = '';
        let afterRemodelError = '';
        let estimatedSplitError = '';
        let depositDueError = '';
        let depositAmountError = '';

        if (investmentType === PropertyInvestmentType.B52) {
            purchasePriceError = purchasePrice.value < 0 ? 'Purchase price cannot be empty.' : '';
            loanPayoffAmountError = isPositiveValidator(loanPayoffAmount.value, 'Loan payoff amount cannot be empty.');
            estimatedConstructionBudgetError = isPositiveValidator(estimatedConstructionBudget.value, 'Estimated construction (rehab) budget cannot be empty.');
            afterRemodelError = isPositiveValidator(afterRemodelValue.value, 'After remodel (repair) value cannot be empty.');
            estimatedSplitError = isNullOrEmptyValidator(estimatedSplit.value, 'Estimated split cannot be empty.');
            depositDueError = isNullOrEmptyValidator(depositDue.value, 'Deposit due cannot be empty.');
            depositAmountError = isPositiveValidator(depositAmount.value, 'Deposit amount cannot be empty.');
        }

        // Both
        const friendlyNameError = isNullOrEmptyValidator(friendlyName.value, 'Property name cannot be empty.');
        const totalInvestorsError = isPositiveValidator(totalInvestors.value, 'Total investors cannot be empty.');
        const estimatedInvestmentAmountError = isPositiveValidator(estimatedInvestmentAmount.value, 'Total investor funds amount cannot be empty.');
        const minInvestmentAmountError = isPositiveValidator(minInvestmentAmount.value, 'Minimum investor funds amount cannot be empty.');
        const maxInvestmentAmountError = isPositiveValidator(maxInvestmentAmount.value, 'Maximum investor funds amount cannot be empty.');
        const listingLinkError = isValidUrl(listingLink.value, 'Please enter a valid listing link including https://.');
        const ndaLinkError = ndaRequired ? isValidUrl(ndaLink.value, 'Please enter a valid NDA link including https://.') : '';
        const escrowCompanySelectionError = props.InvestmentSpecification.AssignedEscrowCompany == null && escrowCompanyData.find(x => x.EscrowCompanyName === escrowCompany.value) == null ? 'Escrow company not found' : '';

        if (incrementPropertyStatus === 1 &&
            (estimatedMonthlyExpensesError || occupancyRateError || appreciationRateError || estimatedNightlyRateError || estimatedMonthlyRentError || estimatedInvestmentAmountError || minInvestmentAmountError || maxInvestmentAmountError || loanPayoffAmountError || depositAmountError || estimatedSplitError || depositDueError || friendlyNameError || totalInvestorsError || listingLinkError || ndaLinkError ||
                estimatedConstructionBudgetError || afterRemodelError || escrowCompanySelectionError || purchasePriceError)) {

            setEstimatedMonthlyExpenses({ ...estimatedMonthlyExpenses, error: estimatedMonthlyExpensesError });
            setOccupancyRate({ ...occupancyRate, error: occupancyRateError });
            setAppreciationRate({ ...appreciationRate, error: appreciationRateError });
            setEstimatedNightlyRate({ ...estimatedNightlyRate, error: estimatedNightlyRateError });
            setEstimatedMonthlyRent({ ...estimatedMonthlyRent, error: estimatedMonthlyRentError });

            setEscrowPurchaseDateTime({ ...escrowPurchaseDateTime, error: '' });
            setEstimatedInvestmentAmount({ ...estimatedInvestmentAmount, error: estimatedInvestmentAmountError });
            setMinInvestmentAmount({ ...minInvestmentAmount, error: minInvestmentAmountError });
            setMaxInvestmentAmount({ ...maxInvestmentAmount, error: maxInvestmentAmountError });

            setLoanPayoffAmount({ ...loanPayoffAmount, error: loanPayoffAmountError });
            setDepositAmount({ ...depositAmount, error: depositAmountError });
            setPurchasePrice({ ...purchasePrice, error: purchasePriceError });
            //setFinalPrice({ ...finalPrice, error: finalPriceError });

            setEstimatedConstructionBudget({ ...estimatedConstructionBudget, error: estimatedConstructionBudgetError });
            setAfterRemodelValue({ ...afterRemodelValue, error: afterRemodelError });

            setTotalInvestors({ ...totalInvestors, error: totalInvestorsError });

            setEstimatedSplit({ ...estimatedSplit, error: estimatedSplitError });
            setDepositDue({ ...depositDue, error: depositDueError });
            setFriendlyName({ ...friendlyName, error: friendlyNameError });
            setListingLink({ ...listingLink, error: listingLinkError });
            setNDALink({ ...ndaLink, error: ndaLinkError });
            setEscrowCompany({ ...escrowCompany, error: escrowCompanySelectionError });
            return;
        }

        setLoading(true);

        const data = {
            PropertyId: props.property.PropertyID,
            PropertyInvestorOfferingID: saveNewOffering ? 0 : props.InvestmentSpecification.PropertyInvestorOfferingID,
            InvestmentType: investmentType,
            Badge: badge.value,
            EstimatedMonthlyExpenses: estimatedMonthlyExpenses.value,
            EstimatedMonthlyRent: estimatedMonthlyRent.value,
            EstimatedNightlyRate: estimatedNightlyRate.value,
            OccupancyRate: occupancyRate.value,
            AppreciationRate: appreciationRate.value,
            EscrowPurchaseDateTime: escrowPurchaseDateTime.value,
            EstimatedInvestmentAmount: estimatedInvestmentAmount.value,
            MinInvestmentAmount: minInvestmentAmount.value,
            MaxInvestmentAmount: maxInvestmentAmount.value,
            LoanPayoffAmount: loanPayoffAmount.value,
            DepositAmount: depositAmount.value,
            //FinalPrice: finalPrice.value,
            PurchasePrice: purchasePrice.value,
            TotalInvestors: totalInvestors.value,
            EstimatedSplit: estimatedSplit.value,
            InvestmentOfferNote: investmentOfferNote.value,
            ConstructionScopeOfWork: constructionScopeOfWork.value,
            ExpiryDateTime: expiryDateTime.value,
            PercentFunded: percentFunded.value,
            FriendlyName: friendlyName.value,
            ListingLink: listingLink.value,
            NDARequired: ndaRequired,
            NDALink: ndaLink.value,
            DepositDue: depositDue.value,
            IncrementPropertyStatus: incrementPropertyStatus,
            EstimatedConstructionBudget: estimatedConstructionBudget.value,
            AfterRemodelValue: afterRemodelValue.value,
            AssignedEscrowCompanyID: escrowCompanyData.length > 0 ? escrowCompanyData.find(x => x.EscrowCompanyName === escrowCompany.value)?.EscrowCompanyID : props.InvestmentSpecification.AssignedEscrowCompany?.EscrowCompanyID,
            InvestorIDs: investors.map(x => x.InvestorID)
        }
        fetchApi('/api/Property/PropertyInvestmentSpecifications', data, 'POST')
            .then((response: JsonResponse) => {
                setLoading(false);
                if (response.Success) {
                    setVisible(false);
                    props.refresh();
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                setLoading(false);
                alert("Unable to save listing details!");
            });
    }

    const monthlyGross = investmentType === PropertyInvestmentType.ShortTerm 
        ? estimatedNightlyRate.value*30*occupancyRate.value
        : estimatedMonthlyRent.value*1*occupancyRate.value;
    const monthlyNet = monthlyGross - estimatedMonthlyExpenses.value;

    const saveOrCreate = props.InvestmentSpecification.PropertyInvestorOfferingID > 0 ? 'Save' : 'Create';

    return <>
        {visible && <Window
            title="Investment Profile" modal
            onClose={() => setVisible(false)}
            initialWidth={Math.min(window.innerWidth, 1000)}
            initialHeight={650}
        >
            <form className="k-form k-form-md p-0">
                <FieldWrapper>
                    <Label>Property Name</Label>
                    <Input
                        value={friendlyName.value}
                        maxLength={50}
                        onChange={(e) => setFriendlyName({ value: e.value || '', error: '' })}
                        valid={friendlyName.error === ''}
                    />
                    <Error>{friendlyName.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Investment Type</Label>
                    <DropDownList
                        data={investmentTypeOptions}
                        dataItemKey="ID"
                        textField="Name"
                        value={investmentTypeOptions.find(x => x.ID === investmentType)}
                        onChange={e => setInvestmentType(e.target.value.ID)}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Badge (Optional)</Label>
                    <Input
                        value={badge.value}
                        maxLength={30}
                        placeholder="Trending, New, Hot, etc."
                        onChange={(e) => setBadge({ value: e.value || '', error: '' })}
                        valid={badge.error === ''}
                    />
                    <Error>{badge.error}</Error>
                </FieldWrapper>
                {(investmentType === PropertyInvestmentType.ShortTerm || investmentType === PropertyInvestmentType.LongTerm) && <>
                    <FieldWrapper>
                        <NumericTextBox
                            label="Estimated Monthly Expenses"
                            value={estimatedMonthlyExpenses.value}
                            onChange={(e) => setEstimatedMonthlyExpenses({ value: e.value || 0, error: '' })}
                            valid={estimatedMonthlyExpenses.error === ''}
                            format="c2"
                            min={0}
                            step={1}
                        />
                        <Error>{estimatedMonthlyExpenses.error}</Error>
                    </FieldWrapper>
                    {investmentType === PropertyInvestmentType.ShortTerm && <FieldWrapper>
                        <NumericTextBox
                            label="Estimated Nightly Rate"
                            value={estimatedNightlyRate.value}
                            onChange={(e) => setEstimatedNightlyRate({ value: e.value || 0, error: '' })}
                            valid={estimatedNightlyRate.error === ''}
                            format="c2"
                            min={0}
                            step={1}
                        />
                        <Error>{estimatedNightlyRate.error}</Error>
                    </FieldWrapper>}
                    {investmentType === PropertyInvestmentType.LongTerm && <FieldWrapper>
                        <NumericTextBox
                            label="Estimated Monthly Rent"
                            value={estimatedMonthlyRent.value}
                            onChange={(e) => setEstimatedMonthlyRent({ value: e.value || 0, error: '' })}
                            valid={estimatedMonthlyRent.error === ''}
                            format="c2"
                            min={0}
                            step={1}
                        />
                        <Error>{estimatedMonthlyRent.error}</Error>
                    </FieldWrapper>}
                    <FieldWrapper>
                        <NumericTextBox
                            label="Estimated Occupancy %"
                            value={occupancyRate.value}
                            format="p"
                            step={.01}
                            min={0}
                            max={1}
                            onChange={(e) => setOccupancyRate({ value: e.value || 0, error: '' })}
                            valid={occupancyRate.error === ''}
                        />
                        <Error>{occupancyRate.error}</Error>
                        <Hint>Rental Monthly Income: {formatNumber(monthlyGross, 'c2')} / Net: {formatNumber(monthlyNet, 'c2')}</Hint>
                    </FieldWrapper>
                    <FieldWrapper>
                        <NumericTextBox
                            label="Estimated Annual Property Appreciation %"
                            value={appreciationRate.value}
                            format="p1"
                            step={.001}
                            min={-1}
                            max={1}
                            onChange={(e) => setAppreciationRate({ value: e.value || 0, error: '' })}
                            valid={appreciationRate.error === ''}
                        />
                        <Error>{appreciationRate.error}</Error>
                        <Hint>Equity Growth 1st Year: {formatNumber(afterRemodelValue.value*appreciationRate.value, 'c2')}</Hint>
                    </FieldWrapper>
                </>}
                <FieldWrapper>
                    <Label>Close of Escrow for Purchase</Label>
                    <DatePicker
                        value={Moment(escrowPurchaseDateTime.value).isValid() ? Moment.utc(escrowPurchaseDateTime.value).toDate() : new Date()}
                        onChange={(e) => setEscrowPurchaseDateTime({ value: e.value ?? new Date(), error: '' })}
                        valid={escrowPurchaseDateTime.error === ''}
                    />
                    <Error>{escrowPurchaseDateTime.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Offer Expiry (Optional)</Label>
                    <DateTimePicker
                        value={Moment(expiryDateTime.value).isValid() ? Moment.utc(expiryDateTime.value).toDate() : null}
                        min={new Date()}
                        onChange={(e) => setExpiryDateTime({ value: e.value, error: '' })}
                        valid={expiryDateTime.error === ''}
                    />
                    <Error>{expiryDateTime.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <NumericTextBox
                        label="% Funded (Optional)"
                        value={percentFunded.value}
                        format="p1"
                        step={.01}
                        min={-1}
                        max={1}
                        onChange={(e) => setPercentFunded({ value: e.value || 0, error: '' })}
                        valid={percentFunded.error === ''}
                    />
                    <Error>{percentFunded.error}</Error>
                </FieldWrapper>
                {investmentType === PropertyInvestmentType.B52 && <>
                    <FieldWrapper>
                        <NumericTextBox
                            label="Purchase Price"
                            value={purchasePrice.value}
                            onChange={(e) => setPurchasePrice({ value: e.value || 0, error: '' })}
                            valid={purchasePrice.error === ''}
                            format="c2"
                            min={0}
                            step={1000}
                        />
                        <Error>{purchasePrice.error}</Error>
                    </FieldWrapper>
                    <FieldWrapper>
                        <NumericTextBox
                            label="Loan Payoff Amount"
                            value={loanPayoffAmount.value}
                            onChange={(e) => setLoanPayoffAmount({ value: e.value || 0, error: '' })}
                            valid={loanPayoffAmount.error === ''}
                            format="c2"
                            min={0}
                            step={1000}
                        />
                        <Error>{loanPayoffAmount.error}</Error>
                    </FieldWrapper>
                    <FieldWrapper>
                        <NumericTextBox
                            label="Estimated Construction Budget"
                            value={estimatedConstructionBudget.value}
                            onChange={(e) => setEstimatedConstructionBudget({ value: e.value || 0, error: '' })}
                            valid={estimatedConstructionBudget.error === ''}
                            format="c2"
                            min={0}
                            step={1000}
                        />
                        <Error>{estimatedConstructionBudget.error}</Error>
                    </FieldWrapper>
                    <FieldWrapper>
                        <NumericTextBox
                            label="After Remodel Value"
                            value={afterRemodelValue.value}
                            onChange={(e) => setAfterRemodelValue({ value: e.value || 0, error: '' })}
                            valid={afterRemodelValue.error === ''}
                            format="c2"
                            min={0}
                            step={1000}
                        />
                        <Error>{afterRemodelValue.error}</Error>
                    </FieldWrapper>
                </>}
                <FieldWrapper>
                    <NumericTextBox
                        label="Total Investors"
                        value={totalInvestors.value}
                        onChange={(e) => setTotalInvestors({ value: e.value || 0, error: '' })}
                        valid={totalInvestors.error === ''}
                        min={0}
                    />
                    <Error>{totalInvestors.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <NumericTextBox
                        label="Estimated Investment Amount"
                        value={estimatedInvestmentAmount.value}
                        onChange={(e) => setEstimatedInvestmentAmount({ value: e.value || 0, error: '' })}
                        valid={estimatedInvestmentAmount.error === ''}
                        format="c2"
                        min={0}
                        step={1000}
                    />
                    <Error>{estimatedInvestmentAmount.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <NumericTextBox
                        label="Min. Investment Amount"
                        value={minInvestmentAmount.value}
                        onChange={(e) => setMinInvestmentAmount({ value: e.value || 0, error: '' })}
                        valid={minInvestmentAmount.error === ''}
                        format="c2"
                        min={0}
                        step={1000}
                    />
                    <Error>{minInvestmentAmount.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <NumericTextBox
                        label="Max. Investment Amount"
                        value={maxInvestmentAmount.value}
                        onChange={(e) => setMaxInvestmentAmount({ value: e.value || 0, error: '' })}
                        valid={maxInvestmentAmount.error === ''}
                        format="c2"
                        min={0}
                        step={1000}
                    />
                    <Error>{maxInvestmentAmount.error}</Error>
                </FieldWrapper>
                {investmentType === PropertyInvestmentType.B52 && <>
                    <FieldWrapper>
                        <NumericTextBox
                            label="Deposit Amount"
                            value={depositAmount.value}
                            onChange={(e) => setDepositAmount({ value: e.value || 0, error: '' })}
                            valid={depositAmount.error === ''}
                            format="c2"
                            min={0}
                            step={1000}
                        />
                        <Error>{depositAmount.error}</Error>
                    </FieldWrapper>
                    <FieldWrapper>
                        <Label>Deposit Due</Label>
                        <TextArea
                            rows={2}
                            maxLength={200}
                            value={depositDue.value}
                            onChange={(e) => setDepositDue({ value: e.value as string || '', error: '' })}
                            valid={depositDue.error === ''}
                        />
                        <Error>{depositDue.error}</Error>
                    </FieldWrapper>
                    <FieldWrapper>
                        <Label>Estimated Split</Label>
                        <TextArea
                            rows={2}
                            maxLength={200}
                            value={estimatedSplit.value}
                            onChange={(e) => setEstimatedSplit({ value: e.value as string || '', error: '' })}
                            valid={estimatedSplit.error === ''}
                        />
                        <Error>{estimatedSplit.error}</Error>
                    </FieldWrapper>
                </>}
                <FieldWrapper>
                    <Label>Investment Offer Note</Label>
                    <TextArea
                        rows={2}
                        maxLength={2000}
                        value={investmentOfferNote.value}
                        onChange={(e) => setInvestmentOfferNote({ value: e.value as string || '', error: '' })}
                        valid={investmentOfferNote.error === ''}
                    />
                    <Error>{investmentOfferNote.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Construction Scope of Work</Label>
                    <TextArea
                        rows={2}
                        maxLength={2000}
                        value={constructionScopeOfWork.value}
                        onChange={(e) => setConstructionScopeOfWork({ value: e.value as string || '', error: '' })}
                        valid={constructionScopeOfWork.error === ''}
                    />
                    <Error>{constructionScopeOfWork.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Listing Link</Label>
                    <Input
                        value={listingLink.value}
                        maxLength={2083}
                        onChange={(e) => setListingLink({ value: e.value || '', error: '' })}
                        valid={listingLink.error === ''}
                    />
                    <Error>{listingLink.error}</Error>
                </FieldWrapper>

                <FieldWrapper>
                    <Label>Escrow Company</Label>
                    <AutoComplete
                        style={{ width: "100%" }}
                        clearButton
                        data={escrowCompanyData}
                        value={escrowCompany.value}
                        textField="EscrowCompanyName"
                        loading={escrowCompanyLoading}
                        onChange={(e) => { setEscrowCompanyLoading(true); setEscrowCompanyData([]); setEscrowCompany({ ...escrowCompany, value: e.value }); searchEscrowCompany(e.value) }}
                        valid={escrowCompany.error === ''}
                    />
                    <Error>{escrowCompany.error}</Error>
                </FieldWrapper>

                <FieldWrapper>
                    <Label>NDA Content</Label>
                    <Checkbox
                        checked={ndaRequired}
                        onChange={(e) => setNDARequired(e.value)}
                    />
                </FieldWrapper>

                {ndaRequired && <FieldWrapper>
                    <Label>NDA Protected Link</Label>
                    <Input
                        value={ndaLink.value}
                        maxLength={2083}
                        placeholder="https://..."
                        onChange={(e) => setNDALink({ value: e.value || '', error: '' })}
                        valid={ndaLink.error === ''}
                    />
                    <Error>{ndaLink.error}</Error>
                </FieldWrapper>}

                <FieldWrapper>
                    <Label>Investors</Label>
                    <MultiSelect
                        data={investorSearchData}
                        textField="FullName"
                        filterable={true}
                        placeholder="if blank, it will send to all investors"
                        loading={investorSearchLoading}
                        onFilterChange={(e) => {
                            setInvestorSearchData([]);
                            if (String(e.filter.value).length > 2) {
                                setInvestorSearchLoading(true);
                                searchInvestors(e.filter);
                            }
                        }}
                        onChange={(e) => { setInvestors(e.value); }}
                    />
                </FieldWrapper>

                <FieldWrapper>
                    <Label>Upload Cover Photo (Replaces Existing Photo, If Any)</Label>
                    <Upload
                        multiple={false}
                        batch={false}
                        restrictions={{
                            allowedExtensions: ['.jpg', '.jpeg', '.png', '.gif'],
                            maxFileSize: 10000000 // 10 MB
                        }}
                        saveField="File"
                        defaultFiles={[]}
                        withCredentials={false}
                        saveHeaders={{
                            'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                        }}
                        saveUrl={`/api/Property/UploadImage/${props.property.PropertyID}/7`}
                        onStatusChange={(e) => {
                            if (e.response?.status === 200) setImages(['']);
                        }}
                    />
                </FieldWrapper>
            </form>
            <div className="text-center mt-3">
                <Button onClick={() => setVisible(false)}>Cancel</Button>
                <Button className="ms-2" disabled={loading || escrowCompanyLoading || investorSearchLoading} onClick={() => { _save(0) }}>{saveOrCreate}</Button>
                {props.InvestmentSpecification.Status !== PropertyInvestorOfferingStatus.Finalized && <Button themeColor="primary" className="ms-2" disabled={loading || escrowCompanyLoading || investorSearchLoading} onClick={() => { _save() }}>
                    {investors.length > 0 ? saveOrCreate + ' & Notify Selected Investors' : saveOrCreate + ' & Notify ALL Investors'}
                </Button>}
                {props.InvestmentSpecification.Status === 1 &&
                    <Button
                        className="ms-2"
                        themeColor="warning"
                        disabled={loading || escrowCompanyLoading || investorSearchLoading}
                        onClick={() => _save(-1)}
                    >
                        Unlist
                    </Button>
                }
                {props.InvestmentSpecification.PropertyInvestorOfferingID > 0 &&
                    <DropDownButton
                        className="ms-2"
                        themeColor="warning"
                        disabled={loading || escrowCompanyLoading || investorSearchLoading}
                        items={[
                            'Create as New Offering',
                            investors.length > 0 ? 'Create & Notify Selected Investors' : 'Create & Notify ALL Investors',
                        ]}
                        onItemClick={(e) => { _save(e.itemIndex === 1 ? 1 : 0, true) }}
                        text="Create New Offering"
                    />
                }
            </div>
        </Window>}
        <div className="groupbox groupbox-editable col-md-12" onClick={() => setVisible(true)}>
            <p className="property-panel-title">Investment Profile - {getPropertyInvestmentType(props.InvestmentSpecification.InvestmentType)}{(isExpired(props.InvestmentSpecification.ExpiryDateTime) && props.InvestmentSpecification.Status === PropertyInvestorOfferingStatus.Open) && <span className='text-danger'> (Expired)</span>}</p>
            {props.InvestmentSpecification.Status >= PropertyInvestorOfferingStatus.Open ?
                <>
                    <div className="row">
                        <div className="col-md">
                            <div className="fw-bold">Close of Escrow</div>
                            <div>{
                                Moment(props.InvestmentSpecification.EscrowPurchaseDateTime).isValid() ?
                                    Moment.utc(props.InvestmentSpecification.EscrowPurchaseDateTime).local().format("MM/DD/YYYY") :
                                    'N/A'
                            }</div>
                        </div>
                        {props.InvestmentSpecification.InvestmentType === PropertyInvestmentType.B52 && <>
                            <div className="col-md">
                                <div className="fw-bold">Purchase Price</div>
                                <div>{formatCurrency(props.InvestmentSpecification.PurchasePrice)}</div>
                            </div>
                            <div className="col-md">
                                <div className="fw-bold">Loan Payoff Amount</div>
                                <div>{formatCurrency(props.InvestmentSpecification.LoanPayoffAmount)}</div>
                            </div>
                        </>}
                        {props.InvestmentSpecification.InvestmentType === PropertyInvestmentType.ShortTerm && <div className="col-md">
                            <div className="fw-bold">Nightly Rate</div>
                            <div>{formatCurrency(props.InvestmentSpecification.EstimatedNightlyRate)}@{formatNumber(props.InvestmentSpecification.OccupancyRate, 'p')}</div>
                        </div>}
                        {props.InvestmentSpecification.InvestmentType === PropertyInvestmentType.LongTerm && <div className="col-md">
                            <div className="fw-bold">Monthly Rent</div>
                            <div>{formatCurrency(props.InvestmentSpecification.EstimatedMonthlyRent)}</div>
                        </div>}
                        {(props.InvestmentSpecification.InvestmentType === PropertyInvestmentType.ShortTerm || props.InvestmentSpecification.InvestmentType === PropertyInvestmentType.LongTerm) && <div className="col-md">
                            <div className="fw-bold">Monthly Expenses</div>
                                <div>{formatCurrency(props.InvestmentSpecification.EstimatedMonthlyExpenses)}</div>
                        </div>}
                        <div className="col-md">
                            <div className="fw-bold">Investment Amount</div>
                            <div>{formatCurrency(props.InvestmentSpecification.EstimatedInvestmentAmount)}</div>
                        </div>
                        {props.InvestmentSpecification.InvestmentType === PropertyInvestmentType.B52 && <div className="col-md">
                            <div className="fw-bold">Deposit Amount</div>
                            <div>{formatCurrency(props.InvestmentSpecification.DepositAmount)}</div>
                        </div>}
                    </div>
                    <div className="row">
                        {props.InvestmentSpecification.InvestmentType === PropertyInvestmentType.B52 && <div className="col-md">
                            <div className="fw-bold">Estimated Investor Profits</div>
                            <div>{props.InvestmentSpecification.EstimatedSplit ?? "N/A"}</div>
                        </div>}
                        {(props.InvestmentSpecification.InvestmentType === PropertyInvestmentType.ShortTerm || props.InvestmentSpecification.InvestmentType === PropertyInvestmentType.LongTerm) && <div className="col-md">
                            <div className="fw-bold">Property Appreciation</div>
                            <div>{formatNumber(props.InvestmentSpecification.AppreciationRate, 'p1')}</div>
                        </div>}
                        <div className="col-md">
                            <div className="fw-bold">Investment Offer Note</div>
                            <div>{props.InvestmentSpecification.InvestmentOfferNote ?? "N/A"}</div>
                        </div>
                        <div className="col-md">
                            <div className="fw-bold">Construction Scope of Work</div>
                            <div>{props.InvestmentSpecification.ConstructionScopeOfWork ?? "N/A"}</div>
                        </div>
                        {props.InvestmentSpecification.InvestmentType === PropertyInvestmentType.B52 && <div className="col-md">
                            <div className="fw-bold">Deposit Due</div>
                            <div>{props.InvestmentSpecification.DepositDue ?? "N/A"}</div>
                        </div>}
                    </div>
                </>
                : <div className="row"><div className="mx-auto">
                    <button type="button" className="btn btn-primary">Mark Property as Offered to Investors</button>
                </div></div>}
        </div>
    </>
}

export default PropertyProfileInvestment;
