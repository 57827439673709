import { GridCellProps } from '@progress/kendo-react-grid';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { formatNumber } from '@telerik/kendo-intl';
import { RegionCostViewModel } from '.';

export const PercentCell = (props: GridCellProps) => {
    if (!props.field || props.rowType !== 'data') return null;

    const dataItem = props.dataItem as RegionCostViewModel;

    const _handleChange = (e: NumericTextBoxChangeEvent) => {
        if (props.onChange) props.onChange({ dataItem: props.dataItem, dataIndex: props.dataIndex, field: props.field, syntheticEvent: e.syntheticEvent, value: e.target.value });
    }

    const value = props.dataItem[props.field];

    return <td>
        {dataItem.inEdit ? (
            <NumericTextBox
                width="100px"
                onChange={_handleChange}
                value={value}
                format="p"
                step={.01}
                min={0}
                max={1}
            />
        ) : (formatNumber(value, "p"))}
    </td>
}