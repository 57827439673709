import { fetchApi } from '../../services/api';
import { useLoaderData, Params } from 'react-router';
import { Title } from '../../utils/title';
import { AppReferralReferralViewModel } from 'TypeGen/appreferral/referrals/app-referral-referral-view-model';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { DateTimeCell } from 'components/DateTimeCell';
import { Link } from 'react-router-dom';
import { ApiLink } from 'TypeGen/api-link';

export const InvestorReferralsLoader = (params: Params<string>) => {
    return fetchApi(`/api/Investor/Referrals/${params.InvestorId}`);
}

const InvestorReferrals = () => {
   const response = useLoaderData() as { Referrals: AppReferralReferralViewModel[] };

   return <>
      <br />
      <Title string="Referrals" />
      <Grid data={response.Referrals} dataItemKey='ID'>
        <GridColumn field="FullName" title="Name" cell={({dataItem}) => {
            const link = dataItem.Links.find((x: ApiLink) => x.Rel === 'Profile') as ApiLink;
            return <td>
                <Link to={link.Href} style={{ color: '#007bff' }}>
                    {dataItem.FullName}
                </Link>
            </td>
        }} />
        <GridColumn field="Email" />
        <GridColumn field="PhoneNumber" title="Phone" />
        <GridColumn field="Active" title="Status" cell={({dataItem}) => <td>{dataItem.Active ? 'Active' : 'Retired'}</td>} />
        <GridColumn field="Relationship" />
        <GridColumn field="CreatedDateTime" title="Created" cell={DateTimeCell} />
      </Grid>
   </>
}

export default InvestorReferrals;