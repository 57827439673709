import * as React from 'react';
import { fetchApi } from '../../services/api';
import LoadingPanel from '../../components/LoadingPanel';
import ImageViewer, { imgDetail } from '../../components/ImageViewer';
import { PropertyViewModel, getPropertyImageType, PropertyImage } from '../../utils/property';

interface Props {
    CloseDialog: () => void;
    Property: PropertyViewModel;
}

interface State {
    isLoading: boolean;
    currImg: number;
    viewerIsOpen: boolean;
    PropertyImages: PropertyImage[];
}


export default class PropertyImageViewer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            currImg: 0,
            viewerIsOpen: false,
            PropertyImages: []
        }
    }

    public render() {
        let images: imgDetail[] = [];
        this.state.PropertyImages.forEach(x => {
            images.push({
                src: `/api/Property/ViewPropertyImage/${x.PropertyImageID}?token=${localStorage.getItem('ACCESS_TOKEN')}`,
                title: getPropertyImageType(x.PropertyImageType)
            });
        });

        return (<>
            {this.state.isLoading && <LoadingPanel />}
            {images && <ImageViewer images={images} isPopup={true} CloseDialog={() => { this.props.CloseDialog() }} />}
        </>);
    }

    public componentDidMount() {
        this.fetch();
    }

    private fetch() {
        this.setState({
            isLoading: true
        });

        fetchApi(`/api/Property/GetPropertyImages/${this.props.Property.PropertyID}`)
            .then((response: PropertyImage[]) => {                
                this.setState({ isLoading: false, PropertyImages: response });
            }).catch(() => {
                this.setState({ isLoading: false, PropertyImages: [] });
            });
    }
}

