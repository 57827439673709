import * as React from 'react';
import * as moment from 'moment';
import LoadingPanel from '../LoadingPanel';
import { fetchApi } from '../../services/api';
import InvestorNotifyAll from './InvestorNotifyAll';
import { Button } from '@progress/kendo-react-buttons';
import { Title } from '../../utils/title';
import { ViewModel } from 'TypeGen/investor/notifications/view-model';
import { PushNotification } from 'TypeGen/investor/notifications/push-notification';
import { Card, CardActions, CardHeader, CardSubtitle, CardTitle } from '@progress/kendo-react-layout';

type Props = {}

type State = {
    isLoading: boolean;
    notifyAll: boolean;
    data: PushNotification[];
}

export default class InvestorNotifications extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            notifyAll: false,
            data: [],
        }

        this.cancelSchedule = this.cancelSchedule.bind(this);
    }

    public render() {
        return (<>
            <Title string="Investor Notifications" />
            <div className="row">
                <div className="col">
                    <h3 className="mb-3">Investor Notifications</h3>
                </div>
                <div className="col text-end">
                    <Button themeColor="primary" onClick={() => this.setState({ notifyAll: true })}>Send Notification</Button>
                </div>
            </div>
            {this.state.isLoading && <LoadingPanel />}
            {this.state.data.map((notification, index) => {
                return <Card key={index} className="mt-2">
                    <CardHeader>
                    <div>
                        {notification.Title && <CardTitle>{notification.Title}</CardTitle>}
                        {notification.Body && <CardTitle className='mt-1'>{notification.Body}</CardTitle>}
                        <CardSubtitle>
                            {notification.ScheduledDateTime ? <p>SCHEDULED: {moment.utc(notification.ScheduledDateTime).local().format("MM/DD/YYYY HH:mm")}</p>
                            : <p>SENT: {moment.utc(notification.SentDateTime).local().format("MM/DD/YYYY HH:mm")}</p>}
                        </CardSubtitle>
                    </div>
                    </CardHeader>
                    <CardActions>
                        {notification.ScheduledDateTime && <Button themeColor="warning" onClick={() => this.onClick(notification.Link)}>Cancel Schedule</Button>}
                        {notification.Link && <Button fillMode="flat" themeColor="primary" onClick={() => this.cancelSchedule(notification.InvestorPushNotificationID)}>{notification.Link}</Button>}
                    </CardActions>
                </Card>
            })}
            {this.state.notifyAll &&
                <InvestorNotifyAll
                    CloseDialog={(refresh) => {
                        this.setState({ notifyAll: false });
                        if (refresh) this.fetch();
                    }}
                />}
        </>);
    }

    public componentDidMount() {
        this.fetch();
    }

    private fetch() {
        this.setState({
            isLoading: true
        })

        fetchApi(`/api/Investor/PushNotifications`)
            .then((response: ViewModel) => {
                this.setState({ isLoading: false, data: response.PushNotifications });
            });
    }

    private onClick(link: string) {
        if (link) window.open(link);
    }

    private cancelSchedule(id: number) {
        if (window.confirm("Are you sure?")) {
            fetchApi(`/api/Investor/CancelScheduledNotification/${id}`, {}, 'DELETE')
                .then(() => {
                    this.fetch();
                });
        }
    }
}