import { useEffect, useState } from 'react';
import { PropertyInvestor, PropertyInvestmentSpecification, PropertyInvestorOfferingStatus, getPropertyInvestmentType } from '../../utils/property';
import { formatCurrency } from '../../utils/generic';
import { ProgressBar } from '@progress/kendo-react-progressbars';
import { fetchApi } from '../../services/api';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { TextArea } from '@progress/kendo-react-inputs';
import { Card, CardBody, CardTitle } from '@progress/kendo-react-layout';

type Props = {
    InvestmentSpecification: PropertyInvestmentSpecification;
    propertyInvestors: PropertyInvestor[];
    refresh: () => void;
}

const PropertyProfileInvestorProgress = (props: Props) => {
    const [totalAccepted, setTotalAccepted] = useState<number>(props.propertyInvestors.map(item => item.AcceptedOffer).reduce((prev, next) => prev + next, 0));
    const [totalEstimatedInvestmentAmount, setTotalEstimatedInvestmentAmount] = useState<number>(props.InvestmentSpecification.EstimatedInvestmentAmount);
    const [reminderItemIndex, setReminderItemIndex] = useState(-1);
    const [additionalInfo, setAdditionalInfo] = useState('');

    const items = [
        "Closing",
        "Finalizing Soon",
        "Custom"
    ];

    useEffect(() => {
        setTotalAccepted(props.propertyInvestors.map(item => item.AcceptedOffer).reduce((prev, next) => prev + next, 0));
        setTotalEstimatedInvestmentAmount(props.InvestmentSpecification.EstimatedInvestmentAmount);
    }, [props.propertyInvestors, props.InvestmentSpecification.EstimatedInvestmentAmount]);

    const _save = () => {
        const data = {
            PropertyInvestorOfferingID: props.InvestmentSpecification.PropertyInvestorOfferingID
        };

        fetchApi('/api/Property/FinalizeInvestorProperty', data, 'POST')
            .then(() => {
                props.refresh();
            })
            .catch(() => {
                alert("Unable to save");
            });
    };

    const _sendReminderEmail = () => {
        const data = {
            PropertyInvestorOfferingID: props.InvestmentSpecification.PropertyInvestorOfferingID,
            AdditionalInfo: additionalInfo,
            Reminder: reminderItemIndex
        };

        fetchApi('/api/Investor/SendReminderEmail', data, 'POST')
            .then(() => {
            })
            .catch(() => {
                alert("Unable to save");
            });

        setReminderItemIndex(-1);
    };

    const _getReasonText = (reasonIndex: number): JSX.Element => {
        switch (reasonIndex) {
            //case 0: //EMD - Not in use
            //    return <p style={{ whiteSpace: "pre-line" }}></p>;
            case 0: //Closing
                return <p>
                    Dear [Investor],<br /><br />
                    We are approaching the close of escrow for the purchase of [Property Name].<br /><br />
                    We received loan docs and escrow is preparing for closing. We will have final numbers to you as soon as we receive the final net sheet. Please be ready to wire within the next 24 hours.<br /><br />
                    [Additional Info]<br /><br />
                    Please let us know if you have any questions.<br />
                </p>
            case 1: //Finalizing Soon
                return <p>
                    Dear [Investor],<br /><br />
                    We are ready to close!  The final amount to wire is [$0.00].  Please confirm what time you plan to send the wire transfer so all parties can confirm when it is received.<br /><br />
                    CC'd in this email is [Escrow Contact Full Name] from [Escrow Company], they will send the wire instructions to you shortly.<br /><br />
                    [Additional Info]<br /><br />
                    Please let us know if you have any questions.<br />
                </p>
            case 2: //Custom
                return <p>
                    Dear [Investor],<br /><br />
                    [Custom Message]<br /><br />
                    Please let us know if you have any questions.<br />
                </p>
        }
        return <p style={{ whiteSpace: "pre-line" }}></p>;
    }

    return <>
        <div className="row">
            <div className="col-lg">
                <span className="fw-bold" style={{ fontSize: "18px" }}>
                    {getPropertyInvestmentType(props.InvestmentSpecification.InvestmentType)} - {formatCurrency(totalAccepted)} raised of {formatCurrency(totalEstimatedInvestmentAmount)} goal
                </span>
            </div>
            <div className="col-lg">
                <div style={{ float: 'right' }}>
                    <DropDownButton
                        className="pe-1"
                        text="Send Reminder"
                        items={items}
                        onItemClick={(e) => { setReminderItemIndex(e.itemIndex) }}
                    />
                </div>
            </div>
            {props.InvestmentSpecification.Status === PropertyInvestorOfferingStatus.Open &&
                <div className="col-lg">
                    <button type="button" className="btn btn-primary" style={{ float: 'right' }}
                        onClick={(e) => {
                            if (window.confirm("Are you sure you want to finalize investments?")) {
                                _save();
                            }
                            e.preventDefault();
                        }}>Finalize Investments</button>
                </div>}
        </div>
        <div className="row">
            <div className="col-lg">
                <ProgressBar max={totalEstimatedInvestmentAmount} value={totalAccepted} labelVisible={false} progressClassName={"bg-success"} style={{ width: '100%' }} />
            </div>
        </div>
        {
            reminderItemIndex > -1 && <Dialog title={`Reminder Dialog`} onClose={() => setReminderItemIndex(-1)}>
                <div>
                    <Card style={{ width: '600px' }}>
                        <CardBody>
                            <CardTitle style={{ fontWeight: '400' as 'bold' }}>{`${items[reminderItemIndex]} Template`}</CardTitle>
                            <p style={{ whiteSpace: "pre-line" }}>{_getReasonText(reminderItemIndex)}</p>
                        </CardBody>
                    </Card>
                </div>
                <div className="mt-2" style={{ width: '100%' }}>
                    <TextArea style={{ width: '100%' }} placeholder={reminderItemIndex === 2 ? "Custom message..." : "Additional info..."} value={additionalInfo} onChange={(e) => setAdditionalInfo(e.value)} />
                </div>
                <DialogActionsBar>
                    <Button onClick={() => setReminderItemIndex(-1)}>Cancel</Button>
                    <Button themeColor="primary" onClick={() => _sendReminderEmail()} disabled={reminderItemIndex === 2 && additionalInfo.length === 0}>Send</Button>
                </DialogActionsBar>
            </Dialog>
        }
    </>
}

export default PropertyProfileInvestorProgress;
