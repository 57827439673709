import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { fetchApi } from '../../services/api';
import LoadingPanel from '../LoadingPanel';
import { getAgentLevels } from '../../utils/agent';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Label } from '@progress/kendo-react-labels';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Checkbox, Input, MaskedTextBox } from '@progress/kendo-react-inputs';
import * as Moment from 'moment';
import { useNavigate, useLoaderData, Params } from 'react-router';
import { Title } from '../../utils/title';
import { ViewModel } from 'TypeGen/agent/get/view-model';

export const AgentProfileLoader = (params: Params<string>) => {
  return fetchApi(`/api/Agent/Get/${params.AgentId}`);
}

const agentLevelOptions = getAgentLevels();

const AgentProfile = () => {

  const navigate = useNavigate();
  const response = useLoaderData() as { isAdmin: boolean, agent: ViewModel };

  const [loading, setLoading] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);
  const admin = response.isAdmin;
  const [agent, setAgent] = React.useState<ViewModel>(response.agent);

  const updateAgentState = (value: Partial<ViewModel>) => {
    const newAgent = Object.assign({}, agent, value);
    setAgent(newAgent);
    setDirty(true);
  }

  const retireAgent = () => {
    const newAgent = Object.assign({}, agent, { Active: false });
    save(newAgent);
}

const unRetireAgent = () => {
    const newAgent = Object.assign({}, agent, { Active: true });
    save(newAgent);
}

const save = (newAgent: ViewModel) => {
    if (newAgent) {
        const data: any = Object.assign({}, newAgent);
        fetchApi(`/api/Agent/Edit`, data, 'POST')
            .then(() => {
                setDirty(false);
                setLoading(false);
                navigate("/Agent/Index");
            }).catch((message: Error) => {
                setLoading(false);
                alert(message);
            });
    }
}

  return <>
    {loading && <LoadingPanel />}
    <div className="k-form k-form-md container">
        <Title string={agent.FirstName} />
        <h1>Agent Profile</h1>

        <div className='row'>
            <div className='col-md-6'>
                <FieldWrapper>
                    <Label>First Name</Label>
                    <Input
                        required
                        readOnly={loading}
                        onChange={e => updateAgentState({ FirstName: e.value })}
                        value={agent.FirstName}
                    />
                </FieldWrapper>
            </div>
            <div className='col-md-6'>
                <FieldWrapper>
                    <Label>Last Name</Label>
                    <Input
                        required
                        readOnly={loading}
                        onChange={e => updateAgentState({ LastName: e.value })}
                        value={agent.LastName}
                    />
                </FieldWrapper>
            </div>
        </div>

        <FieldWrapper>
            <Label>Agent Level</Label>
            <DropDownList
                data={agentLevelOptions}
                dataItemKey="ID"
                textField="Name"
                value={agent ? agentLevelOptions.filter(x => x.ID === agent?.Level)[0] : null}
                onChange={(e) => {
                    updateAgentState({ Level: e.target.value.ID })
                }}
            />
        </FieldWrapper>

        <div className='row'>
            <div className='col-md-6'>
                <FieldWrapper>
                    <Label>Email Address <span className="ms-1 text-muted">(Update in Stripe)</span></Label>
                    <Input
                        required
                        disabled
                        readOnly={loading}
                        type="email"
                        maxLength={100}
                        onChange={e => updateAgentState({ Email: e.value })}
                        value={agent.Email}
                    />
                </FieldWrapper>
            </div>
        </div>

        <div className='row'>
            <div className='col-md-6'>
                <FieldWrapper>
                    <Label>Phone Number <span className="ms-1 text-muted">(Update in Stripe)</span></Label>
                    <MaskedTextBox
                        disabled
                        readonly={loading}
                        validationMessage="Please enter a valid phone number!"
                        mask="(000) 000-0000"
                        className="flex-fill"
                        value={agent.PhoneNumber}
                        onChange={(e) => e.value === '(___) ___-____' ? updateAgentState({ PhoneNumber: '' }) : updateAgentState({ PhoneNumber: e.value })}
                    />
                </FieldWrapper>
            </div>
        </div>

        <FieldWrapper>
            <Label>Referred By</Label>
            <Input
                maxLength={50}
                readOnly={loading}
                onChange={e => updateAgentState({ HowYouHeard: e.value })}
                value={agent.HowYouHeard}
            />
        </FieldWrapper>

        <FieldWrapper>
            <Label>App Referral</Label>
            <DropDownList
                value={agent.AppReferrals.find(x => x.AppReferralID === agent.AppReferralID)}
                data={agent.AppReferrals}
                dataItemKey="AppReferralID"
                textField="Description"
                onChange={e => updateAgentState({ AppReferralID: e.target.value.AppReferralID })}
            />
        </FieldWrapper>

        <div className='row'>
            <div className='col-md-6'>
                <FieldWrapper>
                    <Label>Created On</Label>
                    <span className='k-widget'>
                        {Moment.utc(agent.CreatedDateTime).local().format("MM/DD/YYYY HH:mm")}
                    </span>
                </FieldWrapper>
            </div>
        </div>

        <div className="row">
            <div className="col-md-6">
                <div className="k-form-field mb-1">
                    <Checkbox
                        checked={agent.SubscriptionExpired}
                        disabled={!admin}
                        label="Subscription Expired"
                        onChange={(e) => updateAgentState({ SubscriptionExpired: e.value })}
                    />
                </div>
            </div>
        </div>

        <div className="btn-toolbar justify-content-between my-2">
            {agent?.StripeCustomerID && <Button onClick={() => window.open(`https://dashboard.stripe.com/customers/${agent?.StripeCustomerID}`)}>Open Stripe</Button>}
            <div className="btn-group">
                {admin && !agent.Active &&
                    <Button onClick={() => window.confirm("Un-retire agent?") && unRetireAgent()}>Un-Retire Agent</Button>}
                {admin && agent.Active &&
                    <Button onClick={() => window.confirm("Retire agent?") && retireAgent()}>Retire Agent</Button>}
                <Button themeColor="primary" className="ms-2" disabled={!dirty} onClick={() => save(agent)}>Update</Button>
                </div>
        </div>
    </div>
  </>;
}

export default AgentProfile;
