import * as React from 'react';
import LoadingPanel from '../LoadingPanel';
import { Grid, GridColumn as Column, GridSortChangeEvent, GridToolbar, GridDataStateChangeEvent, GridFilterChangeEvent, GridColumnProps, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { State as GridState, toDataSourceRequestString, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { fetchApi } from '../../services/api';
import { PropertyAddressCell } from './PropertyAddressCell';
import { AgentCell } from './AgentCell';
import { DateTimeCell } from '../DateTimeCell';
import { PropertyStatusCell } from './PropertyStatusCell';
import { PropertyConstructionStatusCell } from './PropertyConstructionStatusCell';
import { drawRefreshButtonOnGridFooter } from '../../utils/kendo';
import { getPropertyConstructionStatuses, getPropertyStatuses, PropertyViewModel } from '../../utils/property';
import PropertyImageViewer from './PropertyImageViewer';
import { debounce } from 'ts-debounce';
import { AdvancePropertySearchResults, PropertyAdvanceFilterDialog } from './PropertyAdvanceFilterDialog';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import ColumnMenu from '../ColumnMenu';
import { Link } from 'react-router-dom';
import { CustomFilterUIDropdown } from './CustomFilterUIDropdown';
import { PropertyTagsCell } from './PropertyTagsCell';
import { Button } from '@progress/kendo-react-buttons';
import { Title } from '../../utils/title';
import dateTimeReviver from '../../utils/dateTimeReviver';
import { downloadIcon } from '@progress/kendo-svg-icons';

type Props = { };

type State = {
    isLoading: boolean;
    data: PropertyViewModel[];
    tags: string[];
    totalRecords: number;
    dataState: GridState;
    allowUnsort: boolean;
    multiple: boolean;
    showImageViewer: boolean;
    showPropertyProfile: boolean;
    selectedProperty: PropertyViewModel | null;
    openAdvanceFilterDialog: boolean;
    advancePropertySearchResults: AdvancePropertySearchResults | null;
}

const propertyStatuses = getPropertyStatuses();
const propertyConstructionStatuses = getPropertyConstructionStatuses();

export default class Property extends React.Component<Props, State> {

    private CONST_TAKE: number = 50;

    private CONST_DATASTATE_FILTER_DEFAULT = {
        logic: 'and',
        filters: []
    } as CompositeFilterDescriptor;

    private CONST_DATASTATE_DEFAULT = {
        take: this.CONST_TAKE, skip: 0,
        sort: [{ field: "SubmittedDateTime", dir: "desc" }],
        filter: { ...this.CONST_DATASTATE_FILTER_DEFAULT }
    } as GridState;

    private export: React.RefObject<ExcelExport>;

    constructor(props: Props) {
        super(props);

        const sessionDataState = sessionStorage.getItem("PropertyGrid-DataState");

        this.state = {
            isLoading: false,
            data: [],
            tags: [],
            totalRecords: 0,
            dataState: sessionDataState ? JSON.parse(sessionDataState, dateTimeReviver) : this.CONST_DATASTATE_DEFAULT,
            allowUnsort: true,
            multiple: true,
            showImageViewer: false,
            showPropertyProfile: false,
            selectedProperty: null,
            openAdvanceFilterDialog: false,
            advancePropertySearchResults: null
        }

        this.export = React.createRef();

        this.dataStateChange = this.dataStateChange.bind(this);
        this.reset = this.reset.bind(this);
        this.download = this.download.bind(this);
    }

    private dataStateChange(changeEvent: GridDataStateChangeEvent) {
        this.setState({ dataState: changeEvent.dataState }, () => { this.fetch() });
    }

    filterChange = (event: GridFilterChangeEvent) => {
        if (event.filter) {
            this.setState({ dataState: { ...this.state.dataState, filter: { ...event.filter } } }, () => {
                this.filterInputChangeDebounced();
            })
        } else {
            this.setState({ dataState: { ...this.state.dataState, filter: { ...this.CONST_DATASTATE_FILTER_DEFAULT } } }, () => {
                this.filterInputChangeDebounced();
            })
        }
    }

    //pageChange = (event: GridPageChangeEvent) => {
    //    this.setState({ dataState: { ...this.state.dataState, skip: event.page.skip, take: event.page.take } }, () => { this.fetch() });
    //}

    sortChange = (event: GridSortChangeEvent) => {
        var isFilterPopupOpen = document.querySelector(`div.k-popup.k-child-animation-container.k-slide-down-enter`) as HTMLDivElement;
        if (isFilterPopupOpen)
            return false;

        const dS = this.state.dataState;
        dS.sort = event.sort;
        this.setState({ dataState: dS }, () => { this.fetch() });
    }

    reset = () => {
        this.setState({
            dataState: { ...this.CONST_DATASTATE_DEFAULT, filter: { ...this.CONST_DATASTATE_FILTER_DEFAULT } },
            advancePropertySearchResults: null
        }, () => { this.fetch() });
    }

    private filterInputChangeDebounced = debounce(this.filterInputChange, 500);
    private filterInputChange(): Promise<boolean> {
        this.fetch();
        return Promise.resolve(false);
    }

    private columnProps(field: string): Partial<GridColumnProps> {
        return {
            field: field,
            columnMenu: ColumnMenu,
            headerClassName: this.isColumnActive(field, this.state.dataState) ? 'active' : ''
        };
    }

    private isColumnActive(field: string, dataState: any) {
        return GridColumnMenuFilter.active(field, dataState.filter);
    }

    public render() {
        return (<>
            <Title string="Properties" />
            <h3 className="mb-2">Properties</h3>
            {this.state.isLoading && <LoadingPanel />}
            {this.state.showImageViewer && <PropertyImageViewer Property={this.state.selectedProperty as PropertyViewModel} CloseDialog={() => { this.setState({ showImageViewer: false }) }} />}
            <ExcelExport data={this.state.data} ref={this.export}>
                <Grid
                    scrollable="none"
                    pageable={{ pageSizes: [50, 100, 200] }}
                    total={this.state.totalRecords}
                    data={this.state.data}
                    {...this.state.dataState}
                    onDataStateChange={this.dataStateChange}
                    resizable={true}
                    onFilterChange={this.filterChange}
                    onSortChange={this.sortChange}
                    sortable={{
                        allowUnsort: this.state.allowUnsort,
                        mode: this.state.multiple ? 'multiple' : 'single'
                    }}
                >
                    <GridToolbar>
                        <Button
                            icon="zoom-in"
                            title="Advance Filters"
                            themeColor={this.state.advancePropertySearchResults !== null ? "primary" : undefined}
                            onClick={() => { this.setState({ openAdvanceFilterDialog: true }) }}
                        />
                        <Button
                            icon="filter-clear"
                            title="Clear Filters and Sort"
                            onClick={this.reset}
                        />
                        <Button
                            icon="refresh"
                            title="Refresh"
                            onClick={() => { this.fetch() }}
                        />
                        <Link
                            to="/Property/Submit"
                            role="button"
                            className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-primary k-rounded-md k-icon-button"
                            title="Submit Property"
                        >
                            <span role="presentation" style={{ color: 'white' }} className="k-button-icon k-icon k-i-add"></span>
                        </Link>
                        <Button
                            icon="download"
                            svgIcon={downloadIcon}
                            title='Download'
                            style={{ marginLeft: 'auto' }}
                            onClick={this.download}
                        />
                    </GridToolbar>
                    <Column {...this.columnProps("Address.StreetAddress")} title="Address" cell={PropertyAddressCell} />
                    <Column {...this.columnProps("Address.City")} title="City" />
                    <Column {...this.columnProps("Address.State")} title="State" />
                    <Column {...this.columnProps("Address.Zip")} title="Zip" />
                    <Column
                        field="Status"
                        cell={PropertyStatusCell}
                        columnMenu={(props) =>
                            <GridColumnMenuFilter
                                {...props}
                                expanded
                                filterUI={(props) =>
                                    <CustomFilterUIDropdown
                                      {...props}
                                      data={propertyStatuses}
                                      textField="Name"
                                      dataItemKey="ID"
                                      operator='eq'
                                      defaultItem={{ ID: 0, Name: "Select Status" }}
                                    />
                                }
                            />
                        }
                        headerClassName={this.isColumnActive("Status", this.state.dataState) ? 'active' : ''}
                    />
                    <Column
                        field="ConstructionStatus"
                        title="Construction"
                        cell={PropertyConstructionStatusCell}
                        columnMenu={(props) =>
                            <GridColumnMenuFilter
                                {...props}
                                expanded
                                filterUI={(props) =>
                                    <CustomFilterUIDropdown
                                      {...props}
                                      data={propertyConstructionStatuses}
                                      textField="Name"
                                      dataItemKey="ID"
                                      operator='eq'
                                      defaultItem={{ ID: 0, Name: "Select Status" }}
                                    />
                                }
                            />
                        }
                        headerClassName={this.isColumnActive("ConstructionStatus", this.state.dataState) ? 'active' : ''}
                    />
                    <Column {...this.columnProps("ModifiedDateTime")} title="Last Updated" cell={DateTimeCell} filter="date" />
                    <Column {...this.columnProps("AssignedAgent.FullName")} title="Assigned To" cell={AgentCell} minResizableWidth={275} width={275} />
                    <Column {...this.columnProps("SubmittedAgent.FullName")} title="Submitted By" cell={AgentCell} minResizableWidth={275} width={275} />
                    <Column {...this.columnProps("SubmittedDateTime")} title="Submitted Date" cell={DateTimeCell} filter="date" />
                    <Column
                        field="Tags"
                        title="Labels"
                        cell={PropertyTagsCell}
                        columnMenu={(props) =>
                            <GridColumnMenuFilter
                                {...props}
                                expanded
                                filterUI={(props) =>
                                    <CustomFilterUIDropdown
                                      {...props}
                                      data={this.state.tags}
                                      operator="contains"
                                      defaultItem="Select Label"
                                    />
                                }
                            />
                        }
                        headerClassName={this.isColumnActive("Tags", this.state.dataState) ? 'active' : ''}
                    />
                </Grid>
            </ExcelExport>
            {this.state.openAdvanceFilterDialog &&
                <PropertyAdvanceFilterDialog
                    SearchResults={this.state.advancePropertySearchResults}
                    CloseDialog={(results: AdvancePropertySearchResults | null) => {
                        if (isEqual(results, this.state.advancePropertySearchResults)) {
                            this.setState({ openAdvanceFilterDialog: false });
                        } else {
                            this.setState({ openAdvanceFilterDialog: false, advancePropertySearchResults: results }, () => {
                                this.fetch()
                            });
                        }
                    }} />
            }
        </>);
    }

    public handleResize() {
        if ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement !== null) {
            ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement).style.height = (document.documentElement.clientHeight - 125) + "px";
        }
    }

    public componentDidMount() {
        this.fetch();
        this.fetchTags();
        drawRefreshButtonOnGridFooter(() => { this.fetch() });
    }

    private download() {
        this.export.current?.save();
    }

    private recurseFilters(filters: ((CompositeFilterDescriptor | FilterDescriptor)[] | undefined)) {
        if (filters !== undefined && filters != null && filters.length) {
            filters.forEach((f: CompositeFilterDescriptor | FilterDescriptor) => {
                if ((f as any).logic) {
                    this.recurseFilters((f as CompositeFilterDescriptor).filters);
                } else {
                    if ((f as any).field) {
                        var fil = f as FilterDescriptor;
                        if (Object.prototype.toString.call(fil.value) === '[object Date]') {
                            //2022-01-01 00:00 -05:00
                            var DateTimeOffsetUTC = fil.value as Date;
                            //2022-01-01 05:00
                            var DateTimeUTC = new Date(DateTimeOffsetUTC.getUTCFullYear(), DateTimeOffsetUTC.getUTCMonth(),
                                DateTimeOffsetUTC.getUTCDate(), DateTimeOffsetUTC.getUTCHours());
                            fil.value = DateTimeUTC;
                        }
                    }
                }
            });
        }
    }

    private fetch() {
        this.setState({ isLoading: true });
        sessionStorage.setItem("PropertyGrid-DataState", JSON.stringify(this.state.dataState));

        const dataGridState = cloneDeep(this.state.dataState); //don't overwrite local tz
        this.recurseFilters(dataGridState.filter?.filters);

        let queryStr = `${toDataSourceRequestString(dataGridState)}`;

        const data = this.state.advancePropertySearchResults ?? {};

        fetchApi(`/api/Property/List?${queryStr}`, data, 'POST')
            .then(({ Data, Total }) => {
                this.setState({ isLoading: false, data: Data, totalRecords: Total });
            })
    }

    private fetchTags() {
        fetchApi('/api/Property/PropertyTags')
            .then((response: { Tags: string[] }) => {
                this.setState({ tags: response.Tags });
            })
    }
}