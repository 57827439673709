import * as React from 'react';
import LoadingPanel from '../LoadingPanel';
import { PropertyStatus, getPropertyStatus } from '../../utils/property';
import { IdTextPair } from '../../utils/generic';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Checkbox } from '@progress/kendo-react-inputs';

export enum DiscardSearchOption {
    No,
    Yes,
    Only
}

export type AdvancePropertySearchResults = {
    // HasResults: boolean,
    City: string,
    State: string,
    Zip: string,
    Radius: number,
    PropertyStatuses: number[],
    ShowDiscarded: DiscardSearchOption
}

interface Props {
    CloseDialog: (SearchResults: AdvancePropertySearchResults | null) => void;
    SearchResults: AdvancePropertySearchResults | null;
}

type State = {
    searchResults: AdvancePropertySearchResults;
    isLoading: boolean;
}

export class PropertyAdvanceFilterDialog extends React.Component<Props, State> {
    private statusOption: IdTextPair[] = [];
    private discardOption: IdTextPair[] = [];

    private defaultSearchResults = {
        // HasResults: false,
        City: "",
        State: "",
        Zip: "",
        Radius: 0,
        PropertyStatuses: [],
        ShowDiscarded: DiscardSearchOption.No
    } as AdvancePropertySearchResults;

    constructor(props: Props) {
        super(props);

        let enumMember: string;
        for (enumMember in PropertyStatus) {
            const num = Number(enumMember);
            if (!isNaN(num)) {
                this.statusOption.push({ text: getPropertyStatus(num), id: num });
            }
        }

        for (enumMember in DiscardSearchOption) {
            const num = Number(enumMember);
            if (!isNaN(num)) {
                this.discardOption.push({ text: DiscardSearchOption[num], id: num });
            }
        }

        this.state = {
            isLoading: false,
            searchResults: this.props.SearchResults != null ? cloneDeep(this.props.SearchResults) : cloneDeep(this.defaultSearchResults)
        }
    }

    private getResults(): AdvancePropertySearchResults | null {        
        return isEqual(this.defaultSearchResults, this.state.searchResults) ? null : this.state.searchResults
    }

    public render() {
        return (
            <Dialog title="Advance Property Search" onClose={() => this.props.CloseDialog(this.getResults())} width={1024}>
                {this.state.isLoading && <LoadingPanel />}
                <div className="container">
                    <div className="row">
                        <div className="col-md-3" style={{ textAlign: "left" }}>
                            <div className="row">
                                <div className="col-md-12" style={{ textAlign: "left" }}>
                                    <div style={{ fontWeight: 500 }}>Status</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12" style={{ textAlign: "left" }}>
                                    {this.statusOption.map((propertyStatus, index) => {
                                        return (
                                            <FieldWrapper key={propertyStatus.id}>
                                                <Checkbox
                                                    id={`propertyStatusCheckBox_${propertyStatus.id}`}
                                                    label={propertyStatus.text}
                                                    checked={this.state.searchResults.PropertyStatuses.indexOf(propertyStatus.id) > -1}
                                                    onChange={(e) => {
                                                        const value = Number(e.nativeEvent.target.id.split("_")[1]);
                                                        let propertyStatuses = this.state.searchResults.PropertyStatuses;
                                                        var index = propertyStatuses.indexOf(value);
                                                        if (e.value && index === -1) {
                                                            propertyStatuses.push(value);
                                                        } else if (!e.value && index > -1) {
                                                            propertyStatuses.splice(index, 1);
                                                        }
                                                        this.setState({
                                                            searchResults: {
                                                                ...this.state.searchResults,
                                                                PropertyStatuses: propertyStatuses
                                                            }
                                                        })
                                                    }} />
                                            </FieldWrapper>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9" style={{ textAlign: "left" }}>
                            <div className="row">
                                <div className="col-md-12" style={{ textAlign: "left" }}>
                                    <div style={{ fontWeight: 500 }}>Location/Proximity</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12" style={{ textAlign: "left" }}>
                                    <div className="form-inline">
                                        <div className="form-group mb-2">
                                            <label className="sr-only" />
                                            <input type="text" className="form-control" placeholder="city" value={this.state.searchResults.City} onChange={(e) => {
                                                this.setState({ searchResults: { ...this.state.searchResults, City: e.target.value } })
                                            }} />
                                        </div>,
                                        <div className="form-group mb-2 ms-2">
                                            <label className="sr-only" />
                                            <input type="text" className="form-control" placeholder="state" value={this.state.searchResults.State} maxLength={2} style={{ width: '75px' }} onChange={(e) => {
                                                this.setState({ searchResults: { ...this.state.searchResults, State: e.target.value } })
                                            }} />
                                        </div>
                                        <div className="form-group mb-2 ms-2">
                                            <label className="sr-only" />
                                            <input type="text" className="form-control" placeholder="zip" value={this.state.searchResults.Zip} maxLength={7} style={{ width: '125px' }} onChange={(e) => {
                                                this.setState({ searchResults: { ...this.state.searchResults, Zip: e.target.value } })
                                            }} />
                                        </div>
                                        <div className="form-group mb-2 ms-2">
                                            <label className="sr-only" />
                                            <input type="number" className="form-control" placeholder="radius" value={this.state.searchResults.Radius} maxLength={3} style={{ width: '85px' }} onChange={(e) => {
                                                this.setState({ searchResults: { ...this.state.searchResults, Radius: Number(e.target.value) } })
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-12" style={{ textAlign: "left" }}>
                                    <div className="form-inline">
                                        <div className="form-group mb-2">
                                            <label htmlFor="idShowDiscardedProperties">Include Discarded Properties?</label>
                                            <DropDownList
                                                data={this.discardOption}
                                                className="ms-2"
                                                textField="text"
                                                dataItemKey="id"
                                                defaultValue={this.discardOption.filter(x => x.id === this.state.searchResults.ShowDiscarded)[0]}
                                                value={this.discardOption.filter(x => x.id === this.state.searchResults.ShowDiscarded)[0]}
                                                onChange={(e) => {
                                                    this.setState({ searchResults: { ...this.state.searchResults, ShowDiscarded: (e.target.value as IdTextPair).id } })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <DialogActionsBar>
                    <Button onClick={(e) => {
                        this.props.CloseDialog(null);
                        e.preventDefault();
                    }}>Clear</Button>
                    <Button themeColor="primary" onClick={(e) => {
                        this.props.CloseDialog(this.getResults());
                        e.preventDefault();
                    }}>Search</Button>
                </DialogActionsBar>
            </Dialog>
        );
    }
}
