import { useState } from 'react';
import Moment from 'moment';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label, Error } from '@progress/kendo-react-labels';
import { Window } from '@progress/kendo-react-dialogs';
import { getPropertyExpectCounter, PropertyViewModel } from '../../utils/property';
import { auctionHomeValidator, hudHomeValidator, isNullOrEmptyValidator, isNullValidator, tenantsValidator, toYesNo } from '../../utils/generic';
import { fetchApi } from '../../services/api';
import { NumericTextBox, RadioGroup, Checkbox } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Button } from '@progress/kendo-react-buttons';

type Props = {
    property: PropertyViewModel;
    refresh: () => void;
}

const PropertyProfileCommunication = (props: Props) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [hudHome, setHudHome] = useState({ value: props.property.Communications.IsHudHome, error: '' });
    const [auctionHome, setAuctionHome] = useState({ value: props.property.Communications.IsAuctionHome, error: '' });
    const [tenants, setTenants] = useState({ value: props.property.Communications.Tenants, error: '' });
    const [reasonForSelling, setReasonForSelling] = useState({ value: props.property.Communications.ReasonForSelling, error: '' });
    const [courtApproved, setCourtApproved] = useState({ value: props.property.Communications.IsCourtApproved, error: '' });
    const [isReviewDate, setIsReviewDate] = useState({ value: props.property.Communications.IsHudHome === null ? null : props.property.Communications.ReviewDate !== null, error: '' });
    const [reviewDate, setReviewDate] = useState(props.property.Communications.ReviewDate === null ? null : Moment(props.property.Communications.ReviewDate).toDate());
    const [beforeReviewDate, setBeforeReviewDate] = useState({ value: props.property.Communications.BeforeReviewDate, error: '' });
    const [offerCount, setOfferCount] = useState({ value: props.property.Communications.OfferCount, error: '' });
    const [negotiable, setNegotiable] = useState({ value: props.property.Communications.IsNegotiable, error: '' });
    const [expectCounter, setExpectCounter] = useState({ value: props.property.Communications.IsHudHome === null ? null : props.property.Communications.ExpectCounter == null ? 2 : props.property.Communications.ExpectCounter ? 0 : 1, error: '' });
    const [buyerRepresentation, setBuyerRepresentation] = useState({ value: props.property.Communications.BuyerRepresentation, error: '' });
    const [motivationCashPayment, setMotivationCashPayment] = useState(props.property.Communications.MotivationCashPayment);
    const [motivationGoodPrice, setMotivationGoodPrice] = useState(props.property.Communications.MotivationGoodPrice);
    const [motivationGoodTerms, setMotivationGoodTerms] = useState(props.property.Communications.MotivationGoodTerms);
    const [motivationQuickClose, setMotivationQuickClose] = useState(props.property.Communications.MotivationQuickClose);

    const yesNoRadioOptions = [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' }
    ];

    const tenantsRadioOptions = [
        { label: 'Yes, Leaving', value: 0 },
        { label: 'Yes, Staying', value: 1 },
        { label: 'No', value: 2 }
    ];

    const reasonsForSelling = [
        "Can’t afford the mortgage anymore",
        "Inherited the home",
        "Motivated to move elsewhere",
        "Need to liquidate",
        "No longer need/want the home",
        "Probate",
        "Short Sale/Foreclosure",
        "Someone died",
        "Too many problems with the home"
    ];

    const expectCountRadioOptions = [
        { label: 'Yes', value: 0 },
        { label: 'Best Offer Only', value: 1 },
        { label: 'Unknown', value: 2 }
    ];

    const buyerRepresentationRadioOptions = [
        { label: 'Yes', value: 0 },
        { label: 'No', value: 1 },
        { label: 'Off Market - Seller is self represented', value: 2 }
    ];

    const _save = () => {

        // Validation
        const hudHomeError = hudHomeValidator(hudHome.value);
        const auctionHomeError = auctionHomeValidator(auctionHome.value);
        const tenantsError = tenantsValidator(tenants.value);
        const reasonForSellingError = isNullOrEmptyValidator(reasonForSelling.value, 'Select Reason for selling.');
        let courtApprovedError = '';
        let isReviewDateError = '';
        let beforeReviewDateError = '';
        if (["Probate", "Short Sale/Foreclosure"].indexOf(reasonForSelling.value) !== -1) {
            courtApprovedError = isNullValidator(courtApproved.value, 'Select Court Approved.');
            isReviewDateError = isNullValidator(isReviewDate.value, 'Select Review Date');
            if (isReviewDate.value) {
                beforeReviewDateError = isNullValidator(beforeReviewDate.value, 'Select Before Review Date');
            }
        }
        const offerCountError = isNullValidator(offerCount.value, 'Enter offer count, or put 0.');
        const negotiableError = isNullValidator(negotiable.value, 'Select Negotiable');
        const expectCounterError = isNullValidator(expectCounter.value, 'Select Counter Expected.');
        const buyerRepresentationError = isNullValidator(buyerRepresentation.value, 'Select Representing.');

        if (hudHomeError || auctionHomeError || tenantsError || reasonForSellingError || courtApprovedError || isReviewDateError || beforeReviewDateError || offerCountError || negotiableError || expectCounterError || buyerRepresentationError) {
            setHudHome({ ...hudHome, error: hudHomeError });
            setAuctionHome({ ...auctionHome, error: auctionHomeError });
            setTenants({ ...tenants, error: tenantsError });
            setReasonForSelling({ ...reasonForSelling, error: reasonForSellingError });
            setCourtApproved({ ...courtApproved, error: courtApprovedError });
            setIsReviewDate({ ...isReviewDate, error: isReviewDateError });
            setBeforeReviewDate({ ...beforeReviewDate, error: beforeReviewDateError });
            setOfferCount({ ...offerCount, error: offerCountError });
            setNegotiable({ ...negotiable, error: negotiableError });
            setExpectCounter({ ...expectCounter, error: expectCounterError });
            setBuyerRepresentation({ ...buyerRepresentation, error: buyerRepresentationError });
            return;
        }

        setLoading(true);

        const data = {
            PropertyID: props.property.PropertyID,
            IsHudHome: hudHome.value,
            IsAuctionHome: auctionHome.value,
            Tenants: tenants.value,
            ReasonForSelling: reasonForSelling.value,
            IsCourtApproved: courtApproved.value,
            HasReviewDate: isReviewDate.value,
            ReviewDate: reviewDate,
            BeforeReviewDate: beforeReviewDate.value,
            OfferCount: offerCount.value,
            IsNegotiable: negotiable.value,
            ExpectCounter: expectCounter.value === 0 ? true : expectCounter.value === 1 ? false : null,
            BuyerRepresentation: buyerRepresentation.value,
            MotivationCashPayment: motivationCashPayment,
            MotivationGoodPrice: motivationGoodPrice,
            MotivationGoodTerms: motivationGoodTerms,
            MotivationQuickClose: motivationQuickClose
        }
        fetchApi('/api/Property/PropertyCommunication', data, 'POST')
            .then(() => {
                setLoading(false);
                setVisible(false);
                props.refresh();
            })
            .catch(() => {
                setLoading(false);
                alert("Unable to save communication!");
            });
    }

    const _motivationForSellingRemarks = () => {
        let str = props.property.Communications.MotivationCashPayment ? "Cash Payment, " : "";
        str += props.property.Communications.MotivationGoodPrice ? "Good Price, " : "";
        str += props.property.Communications.MotivationGoodTerms ? "Good Terms, " : "";
        str += props.property.Communications.MotivationQuickClose ? "Quick Close, " : "";
        str = str.trim();
        return str ? str.substring(0, str.length - 1) : "None";
    }

    return <>
        {visible && <Window
            title="Communication" modal
            onClose={() => setVisible(false)}
            initialWidth={Math.min(window.innerWidth, 600)}
            initialHeight={650}
        >
            <form className="k-form k-form-md p-0">
                <FieldWrapper>
                    <Label>Is this a HUD Home?</Label>
                    <RadioGroup layout="horizontal" data={yesNoRadioOptions} value={hudHome.value?.toString()} onChange={(e) => setHudHome({ value: e.value === 'true', error: '' })} />
                    <Error>{hudHome.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Is this a Auction Home?</Label>
                    <RadioGroup layout="horizontal" data={yesNoRadioOptions} value={auctionHome.value?.toString()} onChange={(e) => setAuctionHome({ value: e.value === 'true', error: '' })} />
                    <Error>{auctionHome.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Tenants?</Label>
                    <RadioGroup layout="horizontal" data={tenantsRadioOptions} value={tenants.value} onChange={(e) => setTenants({ value: e.value, error: '' })} />
                    <Error>{tenants.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <DropDownList data={reasonsForSelling} label="Reason for selling" value={reasonForSelling.value} onChange={(e) => setReasonForSelling({ value: e.value, error: '' })} />
                    <Error>{reasonForSelling.error}</Error>
                </FieldWrapper>
                {["Probate", "Short Sale/Foreclosure"].indexOf(reasonForSelling.value) !== -1 && <>
                    <FieldWrapper>
                        <Label>Is it court approved?</Label>
                        <RadioGroup layout="horizontal" data={yesNoRadioOptions} value={courtApproved.value?.toString()} onChange={(e) => setCourtApproved({ value: e.value === 'true', error: '' })} />
                        <Error>{courtApproved.error}</Error>
                    </FieldWrapper>
                    <FieldWrapper>
                        <Label>Is there a review date?</Label>
                        <RadioGroup layout="horizontal" data={yesNoRadioOptions} value={isReviewDate.value?.toString()} onChange={(e) => setIsReviewDate({ value: e.value === 'true', error: '' })} />
                        <Error>{isReviewDate.error}</Error>
                    </FieldWrapper>
                    {isReviewDate.value && <>
                        <FieldWrapper>
                            <Label>When?</Label>
                            <DatePicker value={reviewDate} min={new Date()} max={new Date(Date.now() + (courtApproved.value ? 14 : 90) * 24*60*60*1000)} onChange={(e) => setReviewDate(e.value || reviewDate)} />
                            {reviewDate == null && <Error>Enter Review Date</Error>}
                        </FieldWrapper>
                        <FieldWrapper>
                            <Label>Is seller willing to review and accept an offer prior to date above?</Label>
                            <RadioGroup layout="horizontal" data={yesNoRadioOptions} value={beforeReviewDate.value?.toString()} onChange={(e) => setBeforeReviewDate({ value: e.value === 'true', error: '' })} />
                            <Error>{beforeReviewDate.error}</Error>
                        </FieldWrapper>
                    </>}
                </>}
                <FieldWrapper>
                    <NumericTextBox label="How many offers?" value={offerCount.value} min={0} onChange={(e) => setOfferCount({ value: e.value, error: '' })} />
                    <Error>{offerCount.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Negotiable?</Label>
                    <RadioGroup layout="horizontal" data={yesNoRadioOptions} value={negotiable.value?.toString()} onChange={(e) => setNegotiable({ value: e.value === 'true', error: '' })} />
                    <Error>{negotiable.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Can we expect counter?</Label>
                    <RadioGroup layout="horizontal" data={expectCountRadioOptions} value={expectCounter.value} onChange={(e) => setExpectCounter({ value: e.value, error: '' })} />
                    <Error>{expectCounter.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Is the listing agent representing any buyers on this property?</Label>
                    <RadioGroup layout="horizontal" data={buyerRepresentationRadioOptions} value={buyerRepresentation.value} onChange={(e) => setBuyerRepresentation({ value: e.value, error: '' })} />
                    <Error>{buyerRepresentation.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Seller's motivation</Label>
                    <Checkbox label='Cash Payment' value={motivationCashPayment} onChange={(e) => setMotivationCashPayment(e.value)} />
                    <br />
                    <Checkbox label='Good Price' value={motivationGoodPrice} onChange={(e) => setMotivationGoodPrice(e.value)} />
                    <br />
                    <Checkbox label='Good Terms' value={motivationGoodTerms} onChange={(e) => setMotivationGoodTerms(e.value)} />
                    <br />
                    <Checkbox label='Quick Close' value={motivationQuickClose} onChange={(e) => setMotivationQuickClose(e.value)} />
                </FieldWrapper>
            </form>
            <div className="text-center">
                <Button onClick={() => setVisible(false)}>Cancel</Button>
                &nbsp;&nbsp;
                <Button themeColor="primary" disabled={loading} onClick={_save}>Save</Button>
            </div>
        </Window>}
        <div className="groupbox groupbox-editable col-md-12" onClick={() => setVisible(true)}>
            <p className="property-panel-title">Communication</p>
            {props.property.Status > 40 ? <div className="row">
                <div className="col-md">
                    <div className="fw-bold">HUD Home</div>
                    <div>{toYesNo(props.property.Communications.IsHudHome)}</div>
                </div>
                <div className="col-md">
                    <div className="fw-bold">Auction Home</div>
                    <div>{toYesNo(props.property.Communications.IsAuctionHome)}</div>
                </div>
                <div className="col-md">
                    <div className="fw-bold">Selling Reason</div>
                    <div>{props.property.Communications.ReasonForSelling ?? "N/A"}</div>
                </div>
                <div className="col-md">
                    <div className="fw-bold">Current Offers</div>
                    <div>{props.property.Communications.OfferCount ?? "N/A"}</div>
                </div>
                <div className="col-md">
                    <div className="fw-bold">Negotiable</div>
                    <div>{toYesNo(props.property.Communications.IsNegotiable)}</div>
                </div>
                <div className="col-md">
                    <div className="fw-bold">Counter Expected</div>
                    <div>{getPropertyExpectCounter(props.property.Communications.ExpectCounter)}</div>
                </div>
                <div className="col-md">
                    <div className="fw-bold">Seller's Motivation</div>
                    <div>{_motivationForSellingRemarks()}</div>
                </div>
            </div> : <div className="row"><div className="mx-auto">
                <button type="button" className="btn btn-primary">Initiate Contact</button>
            </div></div>}
        </div>
    </>
}

export default PropertyProfileCommunication;
