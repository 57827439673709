import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import { fetchApi } from '../../services/api';
import LoadingPanel from '../LoadingPanel';
import { Input, InputPrefix, InputSuffix, NumericTextBox, TextBox } from '@progress/kendo-react-inputs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { Title } from '../../utils/title';
import { appReferralTypeOptions, AppReferralViewModel, CommissionType, commissionTypeOptions } from '.';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Icon } from "@progress/kendo-react-common";
import { JsonResponse } from '../../utils/generic';

type Params = {
    AppReferralId: string;
}

const AppReferral = () => {
    const buttonRef = React.useRef<Button>(null);
    const { AppReferralId } = useParams<Params>();
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [showQRCode, setShowQRCode] = React.useState(false);
    //const [, setAdmin] = React.useState(false);
    //const [validation, setValidation] = React.useState(false);
    const [appReferral, setAppReferral] = React.useState<AppReferralViewModel | null>(null);
    const [newRecord, setNewRecord] = React.useState(false);

    React.useEffect(() => {
        setNewRecord(AppReferralId === '0');
        if (AppReferralId === '0') {
            emptyRecord();
        } else {
            requestAppReferral(AppReferralId);
        }
    }, [AppReferralId]);

    const updateAppReferralState = (value: Partial<AppReferralViewModel>) => {
        const newAppReferral = Object.assign({}, appReferral, value);
        setAppReferral(newAppReferral);
    }

    const emptyRecord = () => {
        const appReferral = {
            AppReferralID: 0,
            Active: true,
            Description: '',
            ReferralURL: '',
            Salesperson1CommissionAmount: 0,
            Salesperson1CommissionType: CommissionType.Flat,
            Salesperson1Name: '',
            Salesperson1Email: '',
            Salesperson2CommissionAmount: 0,
            Salesperson2CommissionType: CommissionType.Flat,
            Salesperson2Name: '',
            Salesperson2Email: '',
            QRCode: ''
        } as AppReferralViewModel;
        setAppReferral(appReferral);
    }

    const requestAppReferral = (AppReferralId: string | undefined) => {
        setLoading(true);
        fetchApi(`/api/AppReferral/Get/${AppReferralId}`)
            .then((response) => {
                //setAdmin(response.isAdmin);
                setAppReferral(response);
                setLoading(false);
            });
    }

    const retireAppReferral = () => {
        const newAppReferral = Object.assign({}, appReferral, { Active: false });
        save(newAppReferral);
    }

    const unRetireAppReferral = () => {
        const newAppReferral = Object.assign({}, appReferral, { Active: true });
        save(newAppReferral);
    }

    const save = (appReferral: AppReferralViewModel) => {
        //setValidation(true);
        if (appReferral) {
            if (appReferral.Salesperson1Email && appReferral.Salesperson1CommissionAmount) {
                setLoading(true)
                fetchApi(`/api/AppReferral/Save`, appReferral, 'POST')
                    .then((response: JsonResponse) => {
                        // setAppReferral({ ...appReferral, AppReferralID: response.Id } as AppReferralViewModel);
                        setLoading(false);
                        setShowQRCode(false);
                        setNewRecord(false);
                        navigate("/AppReferrals");
                    }).catch((message: Error) => {
                        setLoading(false);
                        alert(message);
                    });
            }
        }
    }

    return <>
        <Title string={appReferral?.Description || 'New App Referral'} />
        {loading && <LoadingPanel />}
        {appReferral &&
            <div className="k-form k-form-md container">
                <FieldWrapper className="mt-4">
                    <Label>Application Type</Label>
                    <DropDownList
                        disabled={newRecord === false}
                        data={appReferralTypeOptions}
                        dataItemKey="ID"
                        textField="Name"
                        required
                        value={appReferral ? appReferralTypeOptions.filter(x => x.ID === appReferral?.AppType)[0] : null}
                        onChange={(e) => { updateAppReferralState({ AppType: e.target.value.ID }); setShowQRCode(true); }}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Description</Label>
                    <Input
                        type="text"
                        maxLength={120}
                        placeholder="Description"
                        readOnly={loading}
                        required
                        onChange={e => updateAppReferralState({ Description: e.value })}
                        value={appReferral.Description}
                    />
                </FieldWrapper>

                <FieldWrapper className="mt-5">
                    <Label>Salesperson Name 1</Label>
                    <Input
                        type="text"
                        maxLength={100}
                        placeholder="Name"
                        readOnly={loading}
                        onChange={e => updateAppReferralState({ Salesperson1Name: e.value })}
                        value={appReferral.Salesperson1Name}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Salesperson E-Mail 1</Label>
                    <Input
                        type="email"
                        maxLength={50}
                        placeholder="E-mail"
                        readOnly={loading}
                        required
                        onChange={e => updateAppReferralState({ Salesperson1Email: e.value })}
                        value={appReferral.Salesperson1Email}
                    />
                </FieldWrapper>
                <div className='row'>
                    <div className='col-md-6'>
                        <FieldWrapper>
                            <Label>Commission Type</Label>
                            <DropDownList
                                data={commissionTypeOptions}
                                dataItemKey="ID"
                                textField="Name"
                                value={appReferral ? commissionTypeOptions.filter(x => x.ID === appReferral?.Salesperson1CommissionType)[0] : null}
                                onChange={(e) => updateAppReferralState({ Salesperson1CommissionType: e.target.value.ID })}
                            />
                        </FieldWrapper>
                    </div>
                    <div className='col-md-6'>
                        <FieldWrapper>
                            <Label>Commission Amount</Label>
                            <NumericTextBox
                                defaultValue={appReferral?.Salesperson1CommissionAmount}
                                format={appReferral?.Salesperson1CommissionType === CommissionType.Flat ? "c2" : "p1"}
                                min={0}
                                max={appReferral?.Salesperson1CommissionType === CommissionType.Flat ? undefined : 1}
                                step={appReferral?.Salesperson1CommissionType === CommissionType.Flat ? 100 : 0.05}
                                onChange={(e) => updateAppReferralState({ Salesperson1CommissionAmount: e.value ?? 0 })}
                            />
                        </FieldWrapper>
                    </div>
                </div>
                <hr className="mt-4 mb-4" />
                <FieldWrapper>
                    <Label>Salesperson Name 2</Label>
                    <Input
                        type="text"
                        maxLength={100}
                        placeholder="Name"
                        readOnly={loading}
                        onChange={e => updateAppReferralState({ Salesperson2Name: e.value })}
                        value={appReferral.Salesperson2Name}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Salesperson E-Mail 2</Label>
                    <Input
                        type="email"
                        maxLength={50}
                        placeholder="E-mail"
                        readOnly={loading}
                        onChange={e => updateAppReferralState({ Salesperson2Email: e.value })}
                        value={appReferral.Salesperson2Email}
                    />
                </FieldWrapper>
                <div className='row'>
                    <div className='col-md-6'>
                        <FieldWrapper>
                            <Label>Commission Type</Label>
                            <DropDownList
                                data={commissionTypeOptions}
                                dataItemKey="ID"
                                textField="Name"
                                value={appReferral ? commissionTypeOptions.filter(x => x.ID === appReferral?.Salesperson2CommissionType)[0] : null}
                                onChange={(e) => updateAppReferralState({ Salesperson2CommissionType: e.target.value.ID })}
                            />
                        </FieldWrapper>
                    </div>
                    <div className='col-md-6'>
                        <FieldWrapper>
                            <Label>Commission Amount</Label>
                            <NumericTextBox
                                defaultValue={appReferral?.Salesperson2CommissionAmount}
                                format={appReferral?.Salesperson2CommissionType === CommissionType.Flat ? "c2" : "p1"}
                                min={0}
                                max={appReferral?.Salesperson2CommissionType === CommissionType.Flat ? undefined : 1}
                                step={appReferral?.Salesperson2CommissionType === CommissionType.Flat ? undefined : 0.05}
                                onChange={(e) => updateAppReferralState({ Salesperson2CommissionAmount: e.value ?? 0 })}
                            />
                        </FieldWrapper>
                    </div>
                </div>

                <div className='row mt-4'>
                    <div className="col"></div>
                    <div className="col" style={{ textAlign: 'right' }}>
                        <div className="btn-group">
                            {!newRecord && !appReferral.Active &&
                                <Button onClick={() => window.confirm("Un-retire app referral?") && unRetireAppReferral()}>Un-Retire App Referral</Button>
                            }
                            {!newRecord && appReferral.Active &&
                                <Button onClick={() => window.confirm("Retire app referral?") && retireAppReferral()}>Retire App Referral</Button>}
                            <Button themeColor="primary" className="ms-2" onClick={(e) => save(appReferral)}>{newRecord ? 'Save' : 'Update'}</Button>
                        </div>
                    </div>

                </div>

                {!newRecord && !showQRCode && <div className='row'>
                    <FieldWrapper style={{ textAlign: 'center' }}>
                        <a href={`data:image/png;base64,${appReferral.QRCode}`} download="QR Code.png">
                            <img style={{ backgroundColor: 'white', height: '200px' }} alt=''
                                src={`data:image/png;base64,${appReferral.QRCode}`}></img>
                        </a>
                        {/*<span>{appReferral.ReferralURL}</span>*/}
                    </FieldWrapper>
                    <FieldWrapper style={{ textAlign: 'center' }}>
                        <div>
                            <TextBox
                                value={appReferral.ReferralURL}
                                readOnly
                                placeholder="Type message"
                                prefix={() => (
                                    <InputPrefix>
                                        <Icon name="hyperlink" />
                                    </InputPrefix>
                                )}
                                suffix={() => (
                                    <InputSuffix>
                                        <Button
                                            ref={buttonRef}
                                            themeColor="primary"
                                            fillMode={"flat"}
                                            rounded={null}
                                            onClick={() => {
                                                navigator.clipboard.writeText(appReferral.ReferralURL);
                                                if (buttonRef.current)
                                                    if (buttonRef.current.element)
                                                        buttonRef.current.element.innerText = 'Copied!'
                                            }}
                                        >
                                            Copy
                                        </Button>
                                    </InputSuffix>
                                )}
                                style={{ width: 400 }}
                            />
                        </div>
                    </FieldWrapper>

                </div>}


            </div >
        }
    </>;
}

export default AppReferral;
