import { Button } from '@progress/kendo-react-buttons';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { Label, Error } from '@progress/kendo-react-labels';
import { paths } from 'App';
import { useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { fetchApi } from 'services/api';
import { EMAIL_REGEX, JsonResponse } from 'utils/generic';

type Inputs = {
    FirstName: string;
    LastName: string;
    Email: string;
    PhoneNumber: string;
};

const ContractorCreate = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
    } = useForm<Inputs>();

    const [loading, setLoading] = useState(false);

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        try {
            const res: JsonResponse = await fetchApi('/api/Contractor/Create', data, 'POST');
            if (res.Success) {
                navigate(paths.Contractor);
            } else {
                setError('root', { message: `Error: ${res.ErrorMessage}` });
            }
        } catch (error) {
            setLoading(false);
            setError('root', { message: `Error: Unknown Error!` });
        }
    };

    return (
        <div className="k-form k-form-md">
            <h3>Add a contractor</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-md-6">
                        <Controller
                            name="FirstName"
                            control={control}
                            disabled={loading}
                            rules={{ required: 'First name cannot be empty.' }}
                            render={({ field }) => (
                                <FieldWrapper>
                                    <Label>First Name</Label>
                                    <Input {...field} />
                                    <Error>{errors.FirstName?.message}</Error>
                                </FieldWrapper>
                            )}
                        />
                    </div>
                    <div className="col-md-6">
                        <Controller
                            name="LastName"
                            control={control}
                            disabled={loading}
                            rules={{ required: 'Last name cannot be empty.' }}
                            render={({ field }) => (
                                <FieldWrapper>
                                    <Label>Last Name</Label>
                                    <Input {...field} />
                                    <Error>{errors.LastName?.message}</Error>
                                </FieldWrapper>
                            )}
                        />
                    </div>
                </div>
                <Controller
                    name="Email"
                    control={control}
                    disabled={loading}
                    rules={{ pattern: { value: EMAIL_REGEX, message: 'Ooops! We need a valid email address.' } }}
                    render={({ field }) => (
                        <FieldWrapper>
                            <Label>Email</Label>
                            <Input {...field} />
                            <Error>{errors.Email?.message}</Error>
                        </FieldWrapper>
                    )}
                />
                <Controller
                    name="PhoneNumber"
                    control={control}
                    disabled={loading}
                    rules={{ required: 'Phone number cannot be empty.', minLength: { value: 9, message: 'Ooops! We need a valid phone number.' } }}
                    render={({ field }) => (
                        <FieldWrapper>
                            <Label>Phone Number</Label>
                            <Input {...field} />
                            <Error>{errors.PhoneNumber?.message}</Error>
                        </FieldWrapper>
                    )}
                />
                <br />
                <Button type="submit">Add Contractor</Button>
                <Error>{errors.root?.message}</Error>
            </form>
        </div>
    );
};

export default ContractorCreate;
