import { useEffect, useState } from 'react';
import { fetchApi } from '../../services/api';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Error, Label } from '@progress/kendo-react-labels';
import { getPropertyLotSizeUnit, PropertyStatus, PropertyViewModel } from '../../utils/property';
import { formatNumber, isNullOrEmptyValidator, isPositiveValidator, isValidUrl } from '../../utils/generic';
import { Window } from '@progress/kendo-react-dialogs';
import { NumericTextBox, Input, TextArea } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';

type Props = {
    property: PropertyViewModel;
    refresh: () => void;
}

const PropertyProfileListing = (props: Props) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [bedrooms, setBedrooms] = useState({ value: props.property.ListingSpecifications.FinalBedrooms, error: '' });
    const [bathrooms, setBathrooms] = useState({ value: props.property.ListingSpecifications.FinalBathrooms, error: '' });
    const [squareFeet, setSquareFeet] = useState({ value: props.property.ListingSpecifications.FinalSquareFeet, error: '' });

    const [listingCode, setListingCode] = useState({ value: props.property.ListingSpecifications.ListingCode || '', error: '' });
    const [listingLink, setListingLink] = useState({ value: props.property.ListingSpecifications.ListingLink || '', error: '' });
    const [lockboxCode, setLockboxCode] = useState({ value: props.property.ListingSpecifications.LockboxCode || '', error: '' });
    const [viewingInstructions, setViewingInstructions] = useState({ value: props.property.ListingSpecifications.ViewingInstructions || '', error: '' });

    useEffect(() => {
        if (props.property.Status < PropertyStatus.Listed) {
            setBedrooms({ value: props.property.Specifications.Bedrooms, error: '' });
            setBathrooms({ value: props.property.Specifications.Bathrooms, error: '' });
            setSquareFeet({ value: props.property.Specifications.SquareFeet, error: '' });
        }
    }, [props.property.Status, props.property.Specifications.Bedrooms, props.property.Specifications.Bathrooms, props.property.Specifications.SquareFeet]);

    const _save = () => {

        const bedroomsError = isPositiveValidator(bedrooms.value, 'Bedrooms cannot be empty.');
        const bathroomsError = isPositiveValidator(bathrooms.value, 'Bathrooms cannot be empty.');
        const squareFeetError = isPositiveValidator(squareFeet.value, 'Square Feet cannot be empty.');

        const listingCodeError = isNullOrEmptyValidator(listingCode.value, 'Listing code cannot be empty.');
        const listingLinkError = isValidUrl(listingLink.value, 'Please enter a valid listing link including https://.');
        const lockBoxCodeError = isNullOrEmptyValidator(lockboxCode.value, 'Lockbox code cannot be empty.');

        const viewingInstructionsError = isNullOrEmptyValidator(viewingInstructions.value, 'Viewing instructions cannot be empty.');

        if (bedroomsError || bathroomsError || squareFeetError || listingCodeError || listingLinkError || lockBoxCodeError || viewingInstructionsError) {
            setBedrooms({ ...bedrooms, error: bedroomsError });
            setBathrooms({ ...bathrooms, error: bathroomsError });
            setSquareFeet({ ...squareFeet, error: squareFeetError });

            setListingCode({ ...listingCode, error: listingCodeError });
            setListingLink({ ...listingLink, error: listingLinkError });
            setLockboxCode({ ...lockboxCode, error: lockBoxCodeError });
            setViewingInstructions({ ...viewingInstructions, error: viewingInstructionsError });
            return;
        }

        setLoading(true);

        const data = {
            PropertyID: props.property.PropertyID,

            Bathrooms: bathrooms.value,
            Bedrooms: bedrooms.value,
            SquareFeet: squareFeet.value,

            ListingCode: listingCode.value,
            ListingLink: listingLink.value,
            LockboxCode: lockboxCode.value,
            ViewingInstructions: viewingInstructions.value
        }
        fetchApi('/api/Property/PropertyListingSpecifications', data, 'POST')
            .then(() => {
                setLoading(false);
                setVisible(false);
                props.refresh();
            })
            .catch(() => {
                setLoading(false);
                alert("Unable to save listing details!");
            });
    }

    return <>
        {visible && <Window
            title="Listing" modal
            onClose={() => setVisible(false)}
            initialWidth={Math.min(window.innerWidth, 600)}
            initialHeight={650}
        >
            <form className="k-form k-form-md p-0">
                <FieldWrapper>
                    <NumericTextBox
                        label="Final No. of Bedrooms"
                        value={bedrooms.value}
                        onChange={(e) => setBedrooms({ value: e.value || 0, error: '' })}
                        format="n0"
                        max={100}
                        min={1}
                    />
                    <Error>{bedrooms.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <NumericTextBox
                        label="Final No. of Bathrooms"
                        value={bathrooms.value}
                        onChange={(e) => setBathrooms({ value: e.value || 0, error: '' })}
                        format="n0"
                        max={100}
                        min={1}
                    />
                    <Error>{bathrooms.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <NumericTextBox
                        label={`Final Living Space (${getPropertyLotSizeUnit(0)})`}
                        value={squareFeet.value}
                        onChange={(e) => setSquareFeet({ value: e.value || 0, error: '' })}
                        format="n0"
                        min={1}
                    />
                    <Error>{squareFeet.error}</Error>
                </FieldWrapper>

                <FieldWrapper>
                    <Label>Listing Code</Label>
                    <Input
                        maxLength={10}
                        value={listingCode.value}
                        onChange={(e) => setListingCode({ value: e.value || '', error: '' })}
                    />
                    <Error>{listingCode.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Listing Link</Label>
                    <Input
                        maxLength={2083}
                        value={listingLink.value}
                        onChange={(e) => setListingLink({ value: e.value || '', error: '' })}
                    />
                    <Error>{listingLink.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Lockbox Code</Label>
                    <Input
                        maxLength={10}
                        value={lockboxCode.value}
                        onChange={(e) => setLockboxCode({ value: e.value || '', error: '' })}
                    />
                    <Error>{lockboxCode.error}</Error>
                </FieldWrapper>

                <FieldWrapper>
                    <Label>Viewing Instructions</Label>
                    <TextArea
                        rows={6}
                        value={viewingInstructions.value}
                        onChange={(e) => setViewingInstructions({ value: e.value as string || '', error: '' })}
                    />
                    <Error>{viewingInstructions.error}</Error>
                </FieldWrapper>

            </form>
            <div className="text-center">
                <Button onClick={() => setVisible(false)}>Cancel</Button>
                &nbsp;&nbsp;
                <Button themeColor="primary" disabled={loading} onClick={_save}>Save</Button>
            </div>
        </Window>}
        <div className="groupbox groupbox-editable col-md-12" onClick={() => setVisible(true)}>
            <p className="property-panel-title">Listing</p>
            {props.property.Status >= PropertyStatus.Listed ?
                <>
                    <div className="row">
                        <div className="col-md">
                            <div className="fw-bold">Listing Code</div>
                            <div>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={props.property.ListingSpecifications.ListingLink}
                                    style={{ color: '#007bff' }}
                                    onClick={(e) => { e.stopPropagation(); }}
                                >{props.property.ListingSpecifications.ListingCode}
                                </a>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="fw-bold">Final Bedrooms</div>
                            <div>{props.property.ListingSpecifications.FinalBedrooms}</div>
                        </div>
                        <div className="col-md">
                            <div className="fw-bold">Final Bathrooms</div>
                            <div>{props.property.ListingSpecifications.FinalBathrooms}</div>
                        </div>
                        <div className="col-md">
                            <div className="fw-bold">Final Living Space</div>
                            <div>{formatNumber(props.property.ListingSpecifications.FinalSquareFeet)} {getPropertyLotSizeUnit(0)}</div>
                        </div>
                        <div className="col-md">
                            <div className="fw-bold">Lockbox Code</div>
                            <div>{props.property.ListingSpecifications.LockboxCode}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <div className="fw-bold">Viewing Instructions</div>
                            <div>{props.property.ListingSpecifications.ViewingInstructions}</div>
                        </div>
                    </div>
                </>
                : <div className="row"><div className="mx-auto">
                    <button type="button" className="btn btn-primary">Mark Property as Listed</button>
                </div></div>}
        </div>
    </>
}

export default PropertyProfileListing;
