import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { UserViewModel } from '../../utils/user';

export const LockedOutCell = (props: GridCellProps) => {
    const dataItem = props.dataItem as UserViewModel;
    return (
        <td>
            {!dataItem.IsLockedOut ? "Active" : "Retired"}
        </td >
    );
}