import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchApi } from '../../services/api';
import { JsonResponse } from '../../utils/generic';
import { Title } from '../../utils/title';

const ChangePassword = () => {

    const navigate = useNavigate();
    const [OldPassword, setOldPassword] = React.useState('');
    const [NewPassword, setNewPassword] = React.useState('');
    const [NewPasswordVerify, setNewPasswordVerify] = React.useState('');

    const changePassword = () => {
        if (!OldPassword || !NewPassword || (NewPassword !== NewPasswordVerify) || (OldPassword === NewPassword)) {
            alert("Passwords don't match!");
            return;
        } else if(NewPassword) {
            if (!NewPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/)) {
                alert('Password does not match the criteria')
                return;
            }
        }

        const data = {
            OldPassword: OldPassword,
            NewPassword: NewPassword
        };
        fetchApi('api/User/ChangePassword', data, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    alert("Successfully Changed Password!");
                    navigate(response.Links.find(x => x.Rel === "Profile")?.Href || '');
                } else {
                    alert(response.ErrorMessage);
                }
            });
    }

    return <React.Fragment>
        <Title string='Change My Password' />
        <div className="container">
            <h1>Change Password</h1>
            <div>
                <div className="form-group">
                    <label htmlFor="oldPassword">Current Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="oldPassword"
                        placeholder="Current password"
                        onChange={((e) => setOldPassword(e.target.value))}
                    />
                </div>
                <div style={{ fontStyle: "italic", marginBottom: "12px"}}>
                    * Password must be 6 to 20 characters long and contain at least one lowercase letter, one uppercase letter and one numeric digit.
                </div>
                <br />
                <div className="form-group">
                    <label htmlFor="newPassword">New Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="newPassword"
                        placeholder="New password"
                        autoComplete="new-password"
                        onChange={((e) => setNewPassword(e.target.value))}
                    />
                </div>
                <br />
                <div className="form-group">
                    <label htmlFor="newPasswordVerify">Confirm New Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="newPasswordVerify"
                        placeholder="Confirm New password"
                        autoComplete="new-password"
                        onChange={((e) => setNewPasswordVerify(e.target.value))}
                    />
                </div>
                <br />
                <button type="submit" className="btn btn-primary" onClick={changePassword}>Change Password</button>
            </div>
        </div>
    </React.Fragment>;
}

export default ChangePassword;
