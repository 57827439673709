import * as React from 'react';
import {  useNavigate, useParams } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import { fetchApi } from '../../services/api';
import LoadingPanel from '../LoadingPanel';
import { EscrowCompanyViewModel } from '.';
import { TextArea, MaskedTextBox } from '@progress/kendo-react-inputs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { formatPhoneNumber } from '../../utils/generic';
import { Title } from '../../utils/title';

type Params = {
    EscrowCompanyId: string;
}

const EscrowCompanyProfile = () => {

    const { EscrowCompanyId } = useParams<Params>();
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [dirty, setDirty] = React.useState(false);
    const [, setAdmin] = React.useState(false);
    const [validation, setValidation] = React.useState(false);
    const [escrowCompany, setEscrowCompany] = React.useState<EscrowCompanyViewModel | null>(null);
    const [newRecord, setNewRecord] = React.useState(false);

    React.useEffect(() => {
        setNewRecord(EscrowCompanyId === 'new');
        if (EscrowCompanyId === 'new') {
            emptyRecord();
        } else {
            requestEscrowCompany(EscrowCompanyId);
        }
    }, [EscrowCompanyId]);

    const updateEscrowCompanyState = (value: Partial<EscrowCompanyViewModel>) => {
        const newEscrowCompany = Object.assign({}, escrowCompany, value);
        setEscrowCompany(newEscrowCompany);
        setDirty(true)
    }

    const emptyRecord = () => {
        const escrowCompany = {
            EscrowCompanyID: 0,
            EscrowCompanyName: '',
            FirstName: '',
            LastName: '',
            PhoneNumber: '',
            Email: '',
            StreetNumber: '',
            StreetName: '',
            UnitNumber: '',
            City: '',
            StateCode: '',
            ZipCode: '',
            WireInstructions: '',
        } as EscrowCompanyViewModel;
        setEscrowCompany(escrowCompany);
    }

    const requestEscrowCompany = (escrowCompanyId: string | undefined) => {
        setLoading(true);
        fetchApi(`/api/EscrowCompany/Get/${escrowCompanyId}`)
            .then((response) => {
                setAdmin(response.isAdmin);
                setEscrowCompany(response.escrowCompany);
                setDirty(false);
                setLoading(false);
            });
    }

    const save = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        setValidation(true);
        if (escrowCompany) {
            if (escrowCompany.FirstName && escrowCompany.LastName && escrowCompany.Email && escrowCompany.EscrowCompanyName
                && escrowCompany.StreetName && escrowCompany.StreetNumber && escrowCompany.City && escrowCompany.StateCode && escrowCompany.ZipCode) {
                setLoading(true)
                fetchApi(`/api/EscrowCompany/Save`, escrowCompany, 'POST')
                    .then((response) => {
                        setEscrowCompany({ ...escrowCompany, Id: response.Id } as EscrowCompanyViewModel);
                        setLoading(false);
                        setDirty(false);
                        navigate("/EscrowCompany/Index");
                    }).catch((message: Error) => {
                        setLoading(false);
                        alert(message);
                    });
            }
        }
    }

    return <>
        <Title string={escrowCompany?.EscrowCompanyName || 'New Escrow Company'} />
        {loading && <LoadingPanel />}
        {escrowCompany &&
            <div className="container">
                <h1>Escrow Company Profile</h1>

                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group mb-2">
                            <label>Company Name {validation && !escrowCompany.EscrowCompanyName && <span className="error-msg">*Required</span>}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="EscrowCompanyName"
                                placeholder="Company Name"
                                value={escrowCompany.EscrowCompanyName || ''}
                                onChange={(e) => updateEscrowCompanyState({ EscrowCompanyName: e.target.value })}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group mb-2">
                            <label>Street Number {validation && !escrowCompany.StreetNumber && <span className="error-msg">*Required</span>}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="StreetNumber"
                                placeholder="Street Number"
                                value={escrowCompany.StreetNumber || ''}
                                onChange={(e) => updateEscrowCompanyState({ StreetNumber: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-2">
                            <label>Street Name {validation && !escrowCompany.StreetName && <span className="error-msg">*Required</span>}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="StreetName"
                                placeholder="Street Name"
                                value={escrowCompany.StreetName || ''}
                                onChange={(e) => updateEscrowCompanyState({ StreetName: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group mb-2">
                            <label>Unit Number</label>
                            <input
                                className="form-control"
                                type="text"
                                name="UnitNumber"
                                placeholder="Unit Number"
                                value={escrowCompany.UnitNumber || ''}
                                onChange={(e) => updateEscrowCompanyState({ UnitNumber: e.target.value })}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <div className="form-group mb-2">
                            <label>City {validation && !escrowCompany.City && <span className="error-msg">*Required</span>}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="City"
                                placeholder="City"
                                value={escrowCompany.City || ''}
                                onChange={(e) => updateEscrowCompanyState({ City: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group mb-2">
                            <label>State {validation && !escrowCompany.StateCode && <span className="error-msg">*Required</span>}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="StateCode"
                                placeholder="State"
                                value={escrowCompany.StateCode || ''}
                                onChange={(e) => updateEscrowCompanyState({ StateCode: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group mb-2">
                            <label>Zip {validation && !escrowCompany.ZipCode && <span className="error-msg">*Required</span>}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="ZipCode"
                                placeholder="Zip"
                                value={escrowCompany.ZipCode || ''}
                                onChange={(e) => updateEscrowCompanyState({ ZipCode: e.target.value })}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group mb-2">
                            <label>First Name {validation && !escrowCompany.FirstName && <span className="error-msg">*Required</span>}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="FirstName"
                                placeholder="Contact First Name"
                                value={escrowCompany.FirstName || ''}
                                onChange={(e) => updateEscrowCompanyState({ FirstName: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-2">
                            <label>Last Name {validation && !escrowCompany.LastName && <span className="error-msg">*Required</span>}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="LastName"
                                placeholder="Contact Last Name"
                                value={escrowCompany.LastName || ''}
                                onChange={(e) => updateEscrowCompanyState({ LastName: e.target.value })}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group mb-2">
                            <label>E-Mail {validation && !escrowCompany.Email && <span className="error-msg">*Required</span>}</label>
                            <input
                                className="form-control"
                                type="email"
                                placeholder="Contact Email"
                                value={escrowCompany.Email || ''}
                                onChange={(e) => updateEscrowCompanyState({ Email: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-2">
                            <label>Phone Number {validation && !escrowCompany.PhoneNumber && <span className="error-msg">*Required</span>}</label><br />
                            <MaskedTextBox
                                width={200}
                                mask="(000) 000-0000"
                                defaultValue={formatPhoneNumber(escrowCompany.PhoneNumber) || ''}
                                onChange={(e) => updateEscrowCompanyState({ PhoneNumber: e.target.value })}
                            />
                        </div>
                    </div>
                </div>

                <form className="k-form">
                    <FieldWrapper>
                        <Label className="mb-2">Wire Instructions</Label>
                        <TextArea
                            placeholder="Wire Instructions"
                            rows={12}
                            value={escrowCompany.WireInstructions || ''}
                            onChange={(e) => updateEscrowCompanyState({ WireInstructions: e.value as string })}
                        />
                    </FieldWrapper>
                </form>

                <div className="text-end mt-4">
                    <Button onClick={() => navigate("/EscrowCompany/Index")}>Cancel</Button>
                    <Button themeColor="primary" className="ms-2" disabled={!dirty} onClick={save}>{newRecord ? 'Create' : 'Update'}</Button>
                </div>
            </div>
        }
    </>;
}

export default EscrowCompanyProfile;
