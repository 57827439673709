import React from "react";
import useCollapse from "react-collapsed";
import { useThemeSwitcher } from "react-css-theme-switcher";

type Props = {
    ignoreTransitionShowAll?: boolean;
}

const FadeInDivPanel: React.FC<React.PropsWithChildren<Props>> = ({ ignoreTransitionShowAll, children }) => {
    const { getCollapseProps, setExpanded, isExpanded } = useCollapse({ collapsedHeight: 380, defaultExpanded: ignoreTransitionShowAll });
    const { currentTheme } = useThemeSwitcher();
    const isDarkMode = currentTheme === 'dark';

    return (
        <div className={`groupbox position-relative ${isExpanded ? 'fadeIn' : 'fadeOut'}`} onClick={() => isExpanded === false && setExpanded(true)}>
            <section {...getCollapseProps()}>
                <div style={isExpanded ? { pointerEvents: `auto` } : { pointerEvents: `none` }}>
                    {children}
                </div>
                {!isExpanded && <div className={`fadeInArrow ${isDarkMode ? 'text-dark' : ''}`}>
                    <span className="k-icon k-i-arrow-chevron-down" style={{ fontSize: "45px" }} />
                </div>}
            </section>
        </div>
    );
};

export default FadeInDivPanel;