import * as React from 'react';
import CenterDivPanel from './CenterDivPanel';

export default class LoadingPanel extends React.PureComponent {
    render() {
        return (
            <CenterDivPanel>
                <div className="spinner-grow text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </CenterDivPanel>
        );
    }
}