import { GridCellProps } from "@progress/kendo-react-grid";
import { PropertyViewModel } from '../../utils/property';

export const PropertyTagsCell = (props: GridCellProps) => {
    const dataItem = props.dataItem as PropertyViewModel;
    return (
        <td>
            {dataItem.Tags?.replace('|', ', ')}
        </td>
    );
}