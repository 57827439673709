import { GridFilterCellProps } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';

const PhoneNumberFilterCell = (props: GridFilterCellProps) => {

    const onChange = (event: NumericTextBoxChangeEvent) => {
      props.onChange({
          value: event.value,
          operator: 'contains',
          syntheticEvent: event.syntheticEvent
      });
    }

    const onClearButtonClick = (event: any) => {
        event.preventDefault();
        props.onChange({
            value: '',
            operator: '',
            syntheticEvent: event
        });
    }
    return <div className='k-filtercell'>
        <NumericTextBox
            style={{ minWidth: "75px" }}
            onChange={onChange}
            value={props.value}
            spinners={false}
            format="0"
        />
        &nbsp;
        <Button
            icon="filter-clear"
            className="k-clear-button-visible"
            title="Clear"
            disabled={!props.value}
            onClick={onClearButtonClick}
        />
    </div>;
}

export default PhoneNumberFilterCell;