import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";

export const EmailAddressCell = (props: GridCellProps) => {
    if (!props.field) return null;
    const emailAddress = props.dataItem[props.field] as string;
    return (
        <td>
            <a
                href={`mailto:${emailAddress}`}
                style={{ color: '#007bff' }}
            >{emailAddress}
            </a>
        </td >
    );
}