import { fetchApi } from '../../../services/api';
import { PropertyRegionViewModel } from '.';

export const insertItem = async (item: PropertyRegionViewModel) => {
  await fetchApi('/api/PropertyRegion/Insert', item, 'POST');
  const data = await getItems();
  return data;
};

export const getItems = async () => {
  const response = await fetchApi('/api/PropertyRegion/List');
    return {
        PropertyRegions: response.PropertyRegions as PropertyRegionViewModel[],
        IsAdmin: response.IsAdmin
    };  
};

export const updateItem = async (item: PropertyRegionViewModel) => {
  await fetchApi('/api/PropertyRegion/Update', item, 'POST');
};

export const deleteItem = async (item: PropertyRegionViewModel) => {
  await fetchApi('/api/PropertyRegion/Remove', item, 'POST');
};