import { Button } from '@progress/kendo-react-buttons';
import { GridCellProps } from '@progress/kendo-react-grid';
import { RegionCostViewModel } from '.';

type MyCommandCellProps = GridCellProps & {
    editField: string;
    add: (dataItem: RegionCostViewModel) => void;
    update: (dataItem: RegionCostViewModel) => void;
    discard: (dataItem: RegionCostViewModel) => void;
    cancel: (dataItem: RegionCostViewModel) => void;
    edit: (dataItem: RegionCostViewModel) => void;
    remove: (dataItem: RegionCostViewModel) => void;
    disabled: boolean;
}

export const MyCommandCell = (props: MyCommandCellProps) => {
  const dataItem = props.dataItem as RegionCostViewModel;
  const isNewItem = dataItem.RegionCostFactorID === undefined;

  return dataItem.inEdit ? (
    <td className="k-command-cell">
      <Button
        className="k-grid-save-command"
        disabled={props.disabled}
        onClick={() => (isNewItem ? props.add(dataItem) : props.update(dataItem))}
      >
        {isNewItem ? "Add" : "Update"}
      </Button>
      <Button
        className="k-grid-cancel-command"
        disabled={props.disabled}
        onClick={() => (isNewItem ? props.discard(dataItem) : props.cancel(dataItem))}
      >
        {isNewItem ? "Discard" : "Cancel"}
      </Button>
    </td>
  ) : (
    <td className="k-command-cell">
      <Button
        themeColor="primary"
        className="k-grid-edit-command"
        disabled={props.disabled}
        onClick={() => props.edit(dataItem)}
      >
        Edit
      </Button>
      <Button
        className="k-grid-remove-command"
        disabled={props.disabled}
        onClick={() =>
          window.confirm("Confirm deleting region: " + dataItem.Region) &&
          props.remove(dataItem)
        }
      >
        Remove
      </Button>
    </td>
  );
};