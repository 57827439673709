import { Dialog } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useEffect, useState } from 'react';
import { AddressViewModel } from '../types/address';
import { Input } from '@progress/kendo-react-inputs';
import { StateCodes } from '../utils/states';
import { Button } from '@progress/kendo-react-buttons';

type Props = {
  data: AddressViewModel;
  readOnly?: boolean;
  title: string;
  save: (data: AddressViewModel) => Promise<void>;
}

export const EditableAddress: React.FC<Props> = (props: Props) => {

  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [data, setData] = useState(props.data);

  // Update internal data
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const stopEditing = () => {
    if (saving) return;
    setData(props.data);
    setEditing(false);
  }

  const startEditing = () => {
    if (props.readOnly) return;
    setEditing(true);
  }

  const save = async () => {
    setSaving(true);
    await props.save(data);
    setSaving(false);
    setEditing(false);
  }

  const saveForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    save();
  }

  const captureEscape = (e: any) => {
    if (e.key === "Escape") {
      stopEditing();
    }
  }

  return <>
    {editing && <Dialog title={props.title} onClose={stopEditing} width={525} height={480}>
      <form className="k-form k-form-md p-0" onKeyDown={captureEscape} onSubmit={saveForm}>
        <div className="row">
          <div className="col">
            <FieldWrapper>
              <Input
                required
                autoFocus
                maxLength={30}
                label="Address Line 1"
                value={data.AddressLine1}
                onChange={(e) => setData({ ...data, AddressLine1: e.value })}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Input
                maxLength={30}
                label="Address Line 2"
                value={data.AddressLine2}
                onChange={(e) => setData({ ...data, AddressLine2: e.value })}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Input
                required
                maxLength={50}
                label="City"
                value={data.City}
                onChange={(e) => setData({ ...data, City: e.value })}
              />
            </FieldWrapper>
            <FieldWrapper>
              <DropDownList
                required
                data={StateCodes}
                label="State"
                textField="Name"
                dataItemKey="ID"
                value={StateCodes.find(x => x.ID === data.State)}
                onChange={(e) => setData({ ...data, State: e.target.value.ID }) }
              />
            </FieldWrapper>
            <FieldWrapper>
              <Input
                required
                maxLength={7}
                label="Zip Code"
                value={data.ZipCode}
                onChange={(e) => setData({ ...data, ZipCode: e.value })}
              />
            </FieldWrapper>
          </div>
        </div>
        <br />
        <Button themeColor="primary" disabled={saving} type="submit" style={{ width: '100%' }}>
          {saving ? "Saving..." : "Update Address"}
        </Button>
      </form>
    </Dialog>}
    <span onClick={startEditing} className={`${props.readOnly ? 'disabled' : 'editable-field'} text-start btn btn-block`}>
      {data.AddressLine1 ? <>
        {data.AddressLine1}<br />
        {data.AddressLine2 && <>{data.AddressLine2}<br /></>}
        {data.City}, {data.State} {data.ZipCode}
      </> : <span className='text-muted'>Add {props.title}</span>}
    </span>
  </>;
};