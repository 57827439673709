import { GridCellProps } from "@progress/kendo-react-grid";
import { AgentViewModel, getAgentLevel } from '../../utils/agent';

export const AgentLevelCell = (props: GridCellProps) => {
    const dataItem = props.dataItem as AgentViewModel;
    return (
        <td>
            {getAgentLevel(dataItem.Level)}
        </td>
    );
}