import './site.css';
import { createRoot } from 'react-dom/client';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { App } from './App';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { version } from './version';
import React from 'react';
import { AuthorizationProvider } from './store/authentication';
import { TitleProvider } from './utils/title';

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};
const defaultTheme = localStorage.getItem("darkmode") ? "dark" : "light";

Sentry.init({
  release: `belwood@${version}`,
  enabled: window.location.hostname !== "localhost",
  dsn: "https://1cc6b763e5444217b1f4e8670f88e8c2@o261143.ingest.sentry.io/5526716",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    })
  ],

  // Only sample 5% of sessions
  tracesSampleRate: 0.05,
});

const queryClient = new QueryClient();

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);
root.render(
  <AuthorizationProvider>
    <ThemeSwitcherProvider
      themeMap={themes}
      defaultTheme={defaultTheme}
      insertionPoint={document.getElementById('inject-styles-here')}
      attr='data-bs-theme'
    >
      <TitleProvider title="Belwood Investments">
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={App} />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </TitleProvider>
    </ThemeSwitcherProvider>
  </AuthorizationProvider>);
