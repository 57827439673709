import * as React from 'react';
import LoadingPanel from '../LoadingPanel';
import { State as GridState, CompositeFilterDescriptor, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridSortChangeEvent, GridDataStateChangeEvent, GridFilterChangeEvent, GridToolbar, GridColumnMenuFilter, GridColumnProps } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { InvestorViewModel } from '../../utils/investor';
import { fetchApi } from '../../services/api';
import { drawRefreshButtonOnGridFooter, GridCellFilterType } from '../../utils/kendo';
import InvestorNameCell from './InvestorNameCell';
import { PhoneNumberCell } from '../PhoneNumberCell';
import { EmailAddressCell } from '../EmailAddressCell';
import ActiveFilterCell from '../ActiveFilterCell';
import { ActiveCell } from '../ActiveCell';
import { DateTimeCell } from '../DateTimeCell';
import { debounce } from 'ts-debounce';
import { Link } from 'react-router-dom';
import { paths } from '../../App';
import { Title } from '../../utils/title';
import { Button } from '@progress/kendo-react-buttons';
import { downloadIcon } from '@progress/kendo-svg-icons';
import PhoneNumberFilterCell from './PhoneNumberFilterCell';
import { CustomFilterUIDropdown } from 'components/Property/CustomFilterUIDropdown';
import { InvestorTypesCell } from './InvestorTypesCell';
import ColumnMenu from 'components/ColumnMenu';
import dateTimeReviver from 'utils/dateTimeReviver';

type Props = {}

type State = {
    isLoading: boolean;
    types: string[];
    data: InvestorViewModel[];
    dataState: GridState;
    totalRecords: number;
    isAdmin: boolean;
}

export default class Agent extends React.Component<Props, State> {

    private CONST_TAKE: number = 50;

    private CONST_DATASTATE_FILTER_DEFAULT = {
        logic: 'and',
        filters: []
    } as CompositeFilterDescriptor;

    private CONST_DATASTATE_DEFAULT = {
        take: this.CONST_TAKE,
        skip: 0,
        sort: [{ field: "FullName", dir: "asc" }],
        filter: { ...this.CONST_DATASTATE_FILTER_DEFAULT }
    } as GridState;

    private export: React.RefObject<ExcelExport>;

    constructor(props: Props) {
        super(props);

        const sessionDataState = sessionStorage.getItem("InvestorGrid-DataState");

        this.state = {
            isLoading: false,
            data: [],
            types: [],
            totalRecords: 0,
            dataState: sessionDataState ? JSON.parse(sessionDataState, dateTimeReviver) : this.CONST_DATASTATE_DEFAULT,
            isAdmin: false
        }

        this.export = React.createRef();

        this.dataStateChange = this.dataStateChange.bind(this);
        this.reset = this.reset.bind(this);
        this.download = this.download.bind(this);
    }

    private dataStateChange(changeEvent: GridDataStateChangeEvent) {
        this.setState({ dataState: changeEvent.dataState }, () => { this.fetch() });
    }

    filterChange = (event: GridFilterChangeEvent) => {
        if (event.filter) {
            this.setState({ dataState: { ...this.state.dataState, filter: { ...event.filter } } }, () => {
                this.filterInputChangeDebounced();
            })
        } else {
            this.setState({ dataState: { ...this.state.dataState, filter: { ...this.CONST_DATASTATE_FILTER_DEFAULT } } }, () => {
                this.filterInputChangeDebounced();
            })
        }
    }

    sortChange = (event: GridSortChangeEvent) => {
        var isFilterPopupOpen = document.querySelector(`div.k-popup.k-child-animation-container.k-slide-down-enter`) as HTMLDivElement;
        if (isFilterPopupOpen)
            return false;

        const dS = this.state.dataState;
        dS.sort = event.sort;
        this.setState({ dataState: dS }, () => { this.fetch() });
    }

    reset = () => {
        this.setState({
            dataState: { ...this.CONST_DATASTATE_DEFAULT, filter: { ...this.CONST_DATASTATE_FILTER_DEFAULT } },
        }, () => { this.fetch() });
    }

    private filterInputChangeDebounced = debounce(this.filterInputChange, 500);
    private filterInputChange(): Promise<boolean> {
        this.fetch();
        return Promise.resolve(false);
    }

    private columnProps(field: string): Partial<GridColumnProps> {
        return {
            field: field,
            columnMenu: ColumnMenu,
            headerClassName: this.isColumnActive(field, this.state.dataState) ? 'active' : ''
        };
    }

    private isColumnActive(field: string, dataState: GridState): boolean {
        return GridColumnMenuFilter.active(field, dataState.filter);
    }

    public render() {
        return (<>
            <Title string="Investors" />
            <h3 className="mb-2">Investors</h3>
            {this.state.isLoading && <LoadingPanel />}
            <ExcelExport data={this.state.data} ref={this.export}>
                <Grid
                    scrollable="none"
                    pageable={{ pageSizes: [50, 100, 200, 500] }}
                    total={this.state.totalRecords}
                    data={this.state.data}
                    {...this.state.dataState}
                    onDataStateChange={this.dataStateChange}
                    resizable={true}
                    onFilterChange={this.filterChange}
                    onSortChange={this.sortChange}
                    sortable={{
                        allowUnsort: true,
                        mode: 'multiple'
                    }}
                >
                    <GridToolbar>
                        <Link
                            to={paths.InvestorSignup}
                            role="button"
                            className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-primary k-rounded-md k-icon-button"
                            title="Add Investor"
                        >
                            <span role="presentation" style={{ color: 'white' }} className="k-button-icon k-icon k-i-add"></span>
                        </Link>
                        <Button
                            icon="filter-clear"
                            title="Clear Filters and Sort"
                            onClick={this.reset}
                        />
                        {this.state.isAdmin && <Button
                            icon="download"
                            svgIcon={downloadIcon}
                            title='Download'
                            style={{ marginLeft: 'auto' }}
                            onClick={this.download}
                        />}
                    </GridToolbar>
                    <Column {...this.columnProps("FullName")} title="Full Name" cell={InvestorNameCell} />
                    <Column {...this.columnProps("VestingName")} title="Vesting Name" />
                    <Column
                        field="InvestorTypes"
                        title="Type"
                        cell={InvestorTypesCell}
                        columnMenu={(props) =>
                            <GridColumnMenuFilter
                                {...props}
                                expanded
                                filterUI={(props) =>
                                    <CustomFilterUIDropdown
                                      {...props}
                                      data={this.state.types}
                                      operator="contains"
                                      defaultItem="Select Type"
                                    />
                                }
                            />
                        }
                        headerClassName={this.isColumnActive("InvestorTypes", this.state.dataState) ? 'active' : ''}
                    />
                    <Column {...this.columnProps("PhoneNumber")} title="Phone" cell={PhoneNumberCell} filterCell={PhoneNumberFilterCell} />
                    <Column {...this.columnProps("Email")} cell={EmailAddressCell} />
                    <Column {...this.columnProps("Active")} cell={ActiveCell} filter='boolean' filterCell={ActiveFilterCell(GridCellFilterType.ServerSide)} />
                    <Column field="CreatedDateTime" title="Created Date" cell={DateTimeCell} filterable={false} />
                </Grid>
            </ExcelExport>
        </>);
    }

    public componentDidMount() {
        this.fetch();
        this.fetchTags();
        drawRefreshButtonOnGridFooter(() => { this.fetch() });
    }

    private download() {
        this.export.current?.save();
    }

    private fetch() {
        this.setState({
            isLoading: true
        })

        const dataGridState = this.state.dataState;
        let queryStr = `${toDataSourceRequestString(dataGridState)}`;

        fetchApi(`/api/Investor/List?${queryStr}`)
            .then((response: { DataSourceResult: any, isAdmin: boolean, }) => {
                this.setState({ isLoading: false, data: response.DataSourceResult.Data, totalRecords: response.DataSourceResult.Total, isAdmin: response.isAdmin });
            })
    }

    private fetchTags() {
        fetchApi('/api/Investor/InvestorTypes')
            .then((response: { InvestorTypes: string[] }) => {
                this.setState({ types: response.InvestorTypes });
            })
    }
}