import * as React from 'react';
import Map from '../../Map';
import Marker from '../../Map/Marker';
import InfoWindow from '../../Map/InfoWindow';
import { fetchApi } from '../../../services/api';
import { PropertyViewModel, getPropertyStatus, PropertyStatus } from '../../../utils/property';
import { IdTextPair } from '../../../utils/generic';
import LoadingPanel from '../../LoadingPanel';
import { AdvancePropertySearchResults } from '../PropertyAdvanceFilterDialog';
import { Button } from '@progress/kendo-react-buttons';
import { Checkbox } from '@progress/kendo-react-inputs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Title } from '../../../utils/title';

type Props = {};

type State = {
    isLoading: boolean;
    properties: PropertyViewModel[];
    totalRecords: number,

    zoom: number;
    bounds: number | null;
    activeMarker: google.maps.LatLng | null | undefined;
    activeMarkerTitle: string;
    activeMarkerDescription: string;
    activeProperty: PropertyViewModel | null;
    showingInfoWindow: boolean;
}

export class PropertyMap extends React.Component<Props, State> {

    private statusOption: IdTextPair[] = [];
    private propertyStatuses: number[] = [];

    constructor(props: Props) {
        super(props);
        for (var enumMember in PropertyStatus) {
            const num = Number(enumMember);
            if (!isNaN(num)) {
                this.statusOption.push({ text: getPropertyStatus(num), id: num });
            }
        }

        this.state = {
            isLoading: false,
            properties: [],
            totalRecords: 0,

            zoom: 10,
            bounds: null,
            activeMarker: null,
            showingInfoWindow: false,
            activeMarkerTitle: '',
            activeProperty: null,
            activeMarkerDescription: '',
        }

        this.onMarkerClick = this.onMarkerClick.bind(this);
    }

    public render() {
        return (<>
            <Title string="Property Map" />
            <h3 className="mb-4">Property Map</h3>
            {this.state.isLoading && <LoadingPanel />}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-2">
                        <div className="row">
                            <div className="col-md-12">
                                <label style={{ fontWeight: 500 }}>Property Status</label>
                                {this.statusOption.map((propertyStatus, index) => {
                                    return (
                                        <FieldWrapper key={propertyStatus.id}>
                                            <Checkbox
                                                label={propertyStatus.text}
                                                checked={this.propertyStatuses.includes(propertyStatus.id)}
                                                id={`propertyStatusCheckBox_${propertyStatus.id}`}
                                                onChange={(e) => {
                                                    const value = Number(e.nativeEvent.target.id.split("_")[1]);
                                                    let propertyStatuses = this.propertyStatuses;
                                                    var index = propertyStatuses.indexOf(value);
                                                    if (e.value && index === -1) {
                                                        propertyStatuses.push(value);
                                                    } else if (!e.value && index > -1) {
                                                        propertyStatuses.splice(index, 1);
                                                    }
                                                    this.fetch();
                                                }} />
                                        </FieldWrapper>
                                    )
                                })}

                                <Button onClick={(e) => {
                                    this.propertyStatuses = [];
                                    this.fetch();
                                    e.preventDefault();
                                }}>Clear</Button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div style={{ position: "relative", minHeight: "100%", height: '75vh' }}>
                            {this.state.properties && <Map
                                clickableIcons={true}
                                zoom={5}
                                streetViewControl={true}
                                scrollwheel={false}
                                center={{ lat: 39.828175, lng: -98.5795 }}
                                style={{ position: 'absolute', width: '100%', height: '100%' }}
                            >

                                {this.state.properties.map(property => {
                                    return (
                                        <Marker key={property.PropertyID}
                                            onClick={(marker) => this.onMarkerClick(property, marker, "", "")}
                                            position={{
                                                lat: property.Address.Latitude,
                                                lng: property.Address.Longitude,
                                            }}
                                        />
                                    );
                                })}

                                {this.state.showingInfoWindow && <InfoWindow
                                    position={this.state.activeMarker}
                                    pixelOffset={new window.google.maps.Size(0, -37)}
                                    content={`<div>
                                        <p class="text-dark m-0">
                                            ${this.state.activeProperty && `${this.state.activeProperty.Address.StreetNumber} ${this.state.activeProperty.Address.StreetName}`}
                                        </p>
                                        <p class="text-dark m-0">
                                            ${this.state.activeProperty && `${this.state.activeProperty.Address.City}, ${this.state.activeProperty.Address.State}  ${this.state.activeProperty.Address.Zip}`}
                                        </p>
                                        <p className="text-dark m-0" style={{ fontStyle: "italic" }}>
                                            ${this.state.activeProperty &&
                                                `<a href="/Property/PropertyProfile/${this.state.activeProperty?.PropertyID}" style="color:#007bff" target="_blank">${getPropertyStatus(this.state.activeProperty.Status)}</a>`
                                            }
                                        </p>
                                        <p className="text-dark m-0" style={{ fontStyle: "italic" }}>
                                            ${this.state.activeProperty &&
                                                `<a href="https://www.google.com/maps/place/${this.state.activeProperty.Address.StreetNumber} ${this.state.activeProperty.Address.StreetName} ${this.state.activeProperty.Address.City}, ${this.state.activeProperty.Address.State} ${this.state.activeProperty.Address.Zip}" style="color:#007bff" target="_blank">View on Google Maps</a>`
                                            }
                                        </p>
                                    </div>`}
                                />}
                            </Map>}
                        </div>
                    </div>
                </div>
            </div>
        </>);
    }

    private onMarkerClick(property: PropertyViewModel, marker: google.maps.Marker, title: string, description: string = '') {
        this.setState({
            activeMarker: marker.getPosition(),
            activeMarkerTitle: title,
            activeMarkerDescription: description,
            activeProperty: property,
            showingInfoWindow: true,
        }, () => {
            //this.forceUpdate();
        });
    }

    public componentDidMount() {
        this.fetch();
    }

    private fetch() {
        this.setState({
            isLoading: true,
            showingInfoWindow: false
        })

        let queryStr = `?pageSize=1000&page=1&sort=PropertyID-desc`;

        const data = { PropertyStatuses: this.propertyStatuses } as AdvancePropertySearchResults;

        fetchApi(`/api/Property/List?${queryStr}`, data, 'POST')
            .then(({ Data, Total }) => {
                const _properties = Data as PropertyViewModel[];
                this.setState({ isLoading: false, properties: _properties, totalRecords: Total });
            });
    }
}

export default PropertyMap;