export const StateCodes = [
  { ID: "AL", Name: "AL - Alabama" },
  { ID: "AK", Name: "AK - Alaska" },
  { ID: "AS", Name: "AS - American Samoa" },
  { ID: "AZ", Name: "AZ - Arizona" },
  { ID: "AR", Name: "AR - Arkansas" },
  { ID: "CA", Name: "CA - California" },
  { ID: "CO", Name: "CO - Colorado" },
  { ID: "CT", Name: "CT - Connecticut" },
  { ID: "DE", Name: "DE - Delaware" },
  { ID: "DC", Name: "DC - District of Columbia" },
  { ID: "FM", Name: "FM - Federated States of Micronesia" },
  { ID: "FL", Name: "FL - Florida" },
  { ID: "GA", Name: "GA - Georgia" },
  { ID: "GU", Name: "GU - Guam" },
  { ID: "HI", Name: "HI - Hawaii" },
  { ID: "ID", Name: "ID - Idaho" },
  { ID: "IL", Name: "IL - Illinois" },
  { ID: "IN", Name: "IN - Indiana" },
  { ID: "IA", Name: "IA - Iowa" },
  { ID: "KS", Name: "KS - Kansas" },
  { ID: "KY", Name: "KY - Kentucky" },
  { ID: "LA", Name: "LA - Louisiana" },
  { ID: "ME", Name: "ME - Maine" },
  { ID: "MH", Name: "MH - Marshall Islands" },
  { ID: "MD", Name: "MD - Maryland" },
  { ID: "MA", Name: "MA - Massachusetts" },
  { ID: "MI", Name: "MI - Michigan" },
  { ID: "MN", Name: "MN - Minnesota" },
  { ID: "MS", Name: "MS - Mississippi" },
  { ID: "MO", Name: "MO - Missouri" },
  { ID: "MT", Name: "MT - Montana" },
  { ID: "NE", Name: "NE - Nebraska" },
  { ID: "NV", Name: "NV - Nevada" },
  { ID: "NH", Name: "NH - New Hampshire" },
  { ID: "NJ", Name: "NJ - New Jersey" },
  { ID: "NM", Name: "NM - New Mexico" },
  { ID: "NY", Name: "NY - New York" },
  { ID: "NC", Name: "NC - North Carolina" },
  { ID: "ND", Name: "ND - North Dakota" },
  { ID: "MP", Name: "MP - Northern Mariana Islands" },
  { ID: "OH", Name: "OH - Ohio" },
  { ID: "OK", Name: "OK - Oklahoma" },
  { ID: "OR", Name: "OR - Oregon" },
  { ID: "PW", Name: "PW - Palau" },
  { ID: "PA", Name: "PA - Pennsylvania" },
  { ID: "PR", Name: "PR - Puerto Rico" },
  { ID: "RI", Name: "RI - Rhode Island" },
  { ID: "SC", Name: "SC - South Carolina" },
  { ID: "SD", Name: "SD - South Dakota" },
  { ID: "TN", Name: "TN - Tennessee" },
  { ID: "TX", Name: "TX - Texas" },
  { ID: "UT", Name: "UT - Utah" },
  { ID: "VT", Name: "VT - Vermont" },
  { ID: "VI", Name: "VI - Virgin Islands" },
  { ID: "VA", Name: "VA - Virginia" },
  { ID: "WA", Name: "WA - Washington" },
  { ID: "WV", Name: "WV - West Virginia" },
  { ID: "WI", Name: "WI - Wisconsin" },
  { ID: "WY", Name: "WY - Wyoming" },
  { ID: "AB", Name: "AB - Alberta" },
  { ID: "BC", Name: "BC - British Columbia" },
  { ID: "MB", Name: "MB - Manitoba" },
  { ID: "NB", Name: "NB - New Brunswick" },
  { ID: "NF", Name: "NF - Newfoundland" },
  { ID: "NT", Name: "NT - Northwest Territories" },
  { ID: "NS", Name: "NS - Nova Scotia" },
  { ID: "NU", Name: "NU - Nunavut" },
  { ID: "ON", Name: "ON - Ontario" },
  { ID: "PE", Name: "PE - Prince Edward Island" },
  { ID: "QC", Name: "QC - Quebec" },
  { ID: "SK", Name: "SK - Saskatchewan" },
  { ID: "YT", Name: "YT - Yukon" },
];