import { createBrowserRouter, Navigate } from 'react-router-dom';
import Layout, { InvestorLayout, AppReferralLayout } from './components/Layout';
import UserLogin from './components/User/Login';
import UserLogout from './components/User/Logout';
import RequestAccountDeletion from 'components/User/RequestAccountDeletion';
import Home from './components/Home';
import Property from './components/Property';
import PropertySubmit from './components/Property/PropertySubmit';
import PropertyMap from './components/Property/Map';
import PropertyRegions from './components/Property/Regions';
import PropertyRegionCost from './components/Property/RegionCost';
import PropertyCalculator from './components/Property/Calculator';
import Agent from './components/Agent';
import AgentProfile, { AgentProfileLoader } from './components/Agent/AgentProfile';
import AgentNotifications from './components/Agent/Notifications';
import Investor from './components/Investor';
import InvestorSignup from './components/Investor/InvestorSignup';
import InvestorProfile, { InvestorProfileLoader } from './components/Investor/InvestorProfile';
import InvestorTransactions, { InvestorTransactionsLoader } from './components/Investor/InvestorTransactions';
import InvestorReferrals, { InvestorReferralsLoader } from './components/Investor/InvestorReferrals';
import InvestorNotifications from './components/Investor/Notifications';
import InvestorRewardsClaimed from './components/Investor/RewardsClaimed';
import UploadCallLeads from './components/UploadCallLeads';
import UploadSMSLeads from './components/UploadSMSLeads';
import Projects from './components/Construction/Projects';
import Project from 'components/Construction/Project';
import Task from 'components/Construction/Task';
import Users from './components/User';
import UserProfile from './components/User/UserProfile';
import CompanySettings, { CompanySettingsLoader } from './components/Company/Settings';
import PropertyProfile from './components/Property/PropertyProfile';
import ChangePassword from './components/User/ChangePassword';
import Payment from './components/Payment/Index';
import Privacy from './components/Privacy';
import EscrowCompany from './components/EscrowCompany';
import EscrowCompanyProfile from './components/EscrowCompany/EscrowCompanyProfile';
import AppReferrals from './components/AppReferral';
import AppReferralProfile from './components/AppReferral/AppReferralProfile';
import AppReferralReferrals, { AppReferralReferralsLoader } from './components/AppReferral/AppReferralReferrals';
import Contractor from './components/Contractor';
import ContractorCreate from 'components/Contractor/ContractorCreate';
import ContractorProfile from 'components/Contractor/ContractorProfile';

export enum paths {
    Home = "/Home/Index",
    PropertyPropertyProfile = "/Property/PropertyProfile/:propertyId",
    UserLogin = "/User/Login",
    UserLogout = "/User/Logout",
    RequestAccountDeletion = "/RequestAccountDeletion",
    Users = "/User/Index",
    UserProfile = "/User/:UserId/UserProfile",
    CompanySettings = "/Company/Settings",
    ChangePassword = "/User/ChangePassword",
    Property = "/Property/Index",
    PropertyProfile = "/Property/Profile/:propertyId",
    PropertySubmit = "/Property/Submit",
    PropertyMap = "/Property/Map/Index",
    PropertyRegions = "/Property/Regions",
    PropertyRegionCost = "/Property/RegionCost",
    PropertyCalculator = "/Property/Calculator",
    Agent = "/Agent/Index",
    AgentProfile = "/Agent/:AgentId/Profile",
    AgentNotifications = "/Agent/Notifications",
    Investor = "/Investor/Index",
    InvestorSignup = "/Investor/Signup",
    InvestorProfile = "/Investor/:InvestorId/Profile",
    InvestorNotifications = "/Investor/Notifications",
    InvestorRewardsClaimed = "/Investor/RewardsClaimed",
    Contractor = "/Contractor/Index",
    ContractorCreate = "/Contractor/Create",
    ContractorProfile = "/Contractor/:ContractorId/Profile",
    UploadCallLeads = "/UploadCallLeads",
    UploadSMSLeads = "/UploadSMSLeads",
    Projects = "/Construction/Projects",
    Project = "/Construction/Project/:constructionProjectId",
    Task = "/Construction/Task/:constructionTaskId",
    Payment = "/Payment/Index",
    Privacy = "/Privacy",
    EscrowCompany = "/EscrowCompany/Index",
    EscrowCompanyProfile = "/EscrowCompany/:EscrowCompanyId/EscrowCompanyProfile",
    AppReferrals = "/AppReferrals",
    AppReferralProfile = "/AppReferral/:AppReferralId/Profile",
    AppReferralReferrals = "/AppReferral/:AppReferralId/Referrals",
}

export const App = createBrowserRouter([
    { path: "/", element: <Navigate to='/User/Login' /> },
    { path: paths.RequestAccountDeletion, element: <RequestAccountDeletion /> },
    { path: paths.PropertyPropertyProfile, element: <PropertyProfile /> },
    { path: paths.UserLogout, element: <UserLogout /> },
    { path: "/", element: <Layout />, children: [
        { path: paths.UserLogin, element: <UserLogin /> },
        { path: paths.Users, element: <Users /> },
        { path: paths.UserProfile, element: <UserProfile /> },
        { path: paths.CompanySettings, element: <CompanySettings />, loader: CompanySettingsLoader },
        { path: paths.ChangePassword, element: <ChangePassword /> },
        { path: paths.Home, element: <Home /> },
        { path: paths.Property, element: <Property /> },
        { path: paths.PropertySubmit, element: <PropertySubmit /> },
        { path: paths.PropertyMap, element: <PropertyMap /> },
        { path: paths.PropertyProfile, element: <PropertyProfile /> },
        { path: paths.PropertyRegions, element: <PropertyRegions /> },
        { path: paths.PropertyRegionCost, element: <PropertyRegionCost /> },
        { path: paths.PropertyCalculator, element: <PropertyCalculator /> },
        { path: paths.Agent, element: <Agent /> },
        { path: paths.AgentProfile, element: <AgentProfile />, loader: ({ params }) => AgentProfileLoader(params) },
        { path: paths.AgentNotifications, element: <AgentNotifications /> },
        { path: paths.Investor, element: <Investor /> },
        { path: paths.InvestorSignup, element: <InvestorSignup /> },
        { path: "/Investor/:InvestorId", element: <InvestorLayout />, children: [
            { path: "Profile", element: <InvestorProfile />, loader: ({ params }) => InvestorProfileLoader(params) },
            { path: "Transactions", element: <InvestorTransactions />, loader: ({ params }) => InvestorTransactionsLoader(params) },
            { path: "Referrals", element: <InvestorReferrals />, loader: ({ params }) => InvestorReferralsLoader(params) },
        ]},
        { path: paths.Contractor, element: <Contractor /> },
        { path: paths.ContractorCreate, element: <ContractorCreate /> },
        { path: paths.ContractorProfile, element: <ContractorProfile /> },
        { path: paths.InvestorNotifications, element: <InvestorNotifications /> },
        { path: paths.InvestorRewardsClaimed, element: <InvestorRewardsClaimed />},
        { path: paths.UploadCallLeads, element: <UploadCallLeads /> },
        { path: paths.UploadSMSLeads, element: <UploadSMSLeads /> },
        { path: paths.Projects, element: <Projects /> },
        { path: paths.Project, element: <Project /> },
        { path: paths.Task, element: <Task /> },
        { path: paths.Payment, element: <Payment /> },
        { path: paths.Privacy, element: <Privacy /> },
        { path: paths.EscrowCompany, element: <EscrowCompany /> },
        { path: paths.EscrowCompanyProfile, element: <EscrowCompanyProfile /> },
        { path: paths.AppReferrals, element: <AppReferrals /> },
        { path: "/AppReferral/:AppReferralId", element: <AppReferralLayout />, children: [
            { path: "Profile", element: <AppReferralProfile /> },
            { path: "Referrals", element: <AppReferralReferrals /> , loader: ({ params }) => AppReferralReferralsLoader(params) },
        ]},
    ]}
]);
