import {  useNavigate } from 'react-router-dom';
import { AuthorizationContext } from '../../store/authentication';
import { useEffect, useContext } from 'react';

const Logout = () => {

    const navigate = useNavigate();
    const { state, clearUserToken } = useContext(AuthorizationContext);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        let redirectUrl = urlParams.get('redirectUrl') ?? '';
        clearUserToken(redirectUrl);
    },[clearUserToken]);

    useEffect(() => {
        if (!state.token) {
            navigate("/User/Login");
        }
    }, [navigate, state.token])

    return (
        <p>Redirecting...</p>
    );
}

export default Logout;