import * as React from 'react';
import { useState } from 'react';
import { fetchApi } from '../../services/api';
import LoadingPanel from '../LoadingPanel';
import { useEffect } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Input, InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { Title } from '../../utils/title';

type LineItem = {
    Description: string,
    Amount: number
}

let defaultLineItem: LineItem = {
    Description: '',
    Amount: 500
}

const Payment = () => {

    const [lineItems, setLineItems] = useState<LineItem[]>([defaultLineItem]);
    const [loading, setLoading] = useState<boolean>(false);

    // Because this page doesn't fetch anything on load
    useEffect(() => {
        if (!localStorage.getItem('ACCESS_TOKEN')) {
            window.location.href = '/User/Logout';
        }
    }, []);

    const updateLineItemDescription = (index: number) => (e: InputChangeEvent) => {
        let newLineItems = [...lineItems];
        newLineItems[index].Description = e.value;
        setLineItems(newLineItems);
    }

    const updateLineItemAmount = (index: number) => (e: NumericTextBoxChangeEvent) => {
        let newLineItems = [...lineItems];
        newLineItems[index].Amount = e.target.value || 0;
        setLineItems(newLineItems);
    }

    const addLineItem = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let newLineItems = [...lineItems];
        newLineItems.push({
            Description: '',
            Amount: 500
        });
        setLineItems(newLineItems);
    }

    const removeLineItem = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let newLineItems = [...lineItems];
        newLineItems.pop();
        setLineItems(newLineItems);
    }

    const createInvoice = async () => {

        if (lineItems.filter(x => x.Description === '').length > 0) {
            alert("Please enter a line item first!");
            return;
        }

        if (!window.confirm("Are you sure?")) return;

        setLoading(true);

        fetchApi('/api/Payment/CreateInvoice', { LineItems: lineItems }, 'POST')
            .then(() => {
                setLoading(false);
                setLineItems([defaultLineItem]);
                alert('Successfully created an invoice!');
            })
            .catch((err) => {
                setLoading(false);
                alert('Error creating an invoice!')
            });
    }

    return <div>
        <Title string="Pay Fees" />
        {loading && <LoadingPanel />}
        <div className="row">
            <div className="col">
                <h3 className="mb-3">Create Invoice for ANC</h3>
            </div>
            <div className="col text-end">
                <a 
                    target="_blank" rel="noreferrer" className="btn btn-primary"
                    href={`/api/Payment/VisitPortal/${localStorage.getItem('ACCESS_TOKEN')}`}
                >
                    Manage Billing
                </a>
            </div>
        </div>
        {lineItems.map((lineItem, index) => {
            return <div className="row mb-3" key={index}>
                <div className="col-md-9">
                    <Input
                        required
                        type="text"
                        placeholder="Description..."
                        value={lineItem.Description}
                        onChange={updateLineItemDescription(index)}
                    />
                </div>
                <div className="col-md-3">
                    <NumericTextBox
                        required
                        placeholder="$0.00"
                        format="c0"
                        min={1}
                        step={500}
                        value={lineItem.Amount}
                        onChange={updateLineItemAmount(index)}
                    />
                </div>
            </div>
        })
        }
        <button type="submit" className="btn btn-primary" onClick={addLineItem}>Add Item</button>&nbsp;
        <button disabled={lineItems.length === 1} className="btn btn-primary" onClick={removeLineItem}>Remove Item</button>&nbsp;
        <Button
            disabled={lineItems.length === 0}
            themeColor="primary"
            onClick={createInvoice}
        >
            Create Invoice
        </Button>
  </div>
};


export default Payment;
