import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { PropertyRegionViewModel } from '.';
import { getLocationType } from '../../../utils/property';

export const LocationTypeCell = (props: GridCellProps) => {
    const dataItem = props.dataItem as PropertyRegionViewModel;

    const locationTypes = [
        { text: 'County', id: 0 },
        { text: 'Zip', id: 1 },
        { text: 'City', id: 2 }
    ];

    const _handleChange = (e: DropDownListChangeEvent) => {
        if (props.onChange) props.onChange({ dataItem: props.dataItem, dataIndex: props.dataIndex, field: props.field, syntheticEvent: e.syntheticEvent, value: e.target.value.id });
    }

    return (
        <td>
            {dataItem.inEdit ? (
                <DropDownList
                    style={{ width: "100px" }}
                    data={locationTypes}
                    onChange={_handleChange}
                    value={locationTypes.find(x => x.id === dataItem.LocationType)}
                    textField="text"
                    dataItemKey="id"
                />
            ) : getLocationType(dataItem.LocationType)}
        </td>
    );
}