import { Grid, GridToolbar, GridColumn as Column, GridCellProps, GridItemChangeEvent } from '@progress/kendo-react-grid';
import { useEffect, useState } from 'react';
import { LocationTypeCell } from './LocationTypeCell';
import { RegionType } from '../../../utils/property';
import { MyCommandCell } from './MyCommandCell';
import { getItems, insertItem, updateItem, deleteItem } from './service';
import { Button } from '@progress/kendo-react-buttons';
import { Title } from '../../../utils/title';

export type PropertyRegionViewModel = {
    PropertyRegionID: number;
    LocationType: RegionType;
    LocationName: string;
    State: string;
    Region: number;

    inEdit: boolean;
}

const Regions = () => {

    const editField = "inEdit";
    const [propertyRegions, setPropertyRegions] = useState<PropertyRegionViewModel[]>([]);
    const [isAdmin, setIsAdmin] = useState<boolean>();

    useEffect(() => {
        getItems().then((data) => { setPropertyRegions(data.PropertyRegions); setIsAdmin(data.IsAdmin) });
    }, []);

    const _commandCell = (props: GridCellProps) => (
        <MyCommandCell
            {...props}
            edit={_enterEdit}
            remove={_remove}
            add={_add}
            discard={_discard}
            update={_update}
            cancel={_cancel}
            editField={editField}
            disabled={!isAdmin}
        />
    );

    const _remove = (dataItem: PropertyRegionViewModel) => {
        deleteItem(dataItem);
        setPropertyRegions(propertyRegions.filter(x => x.PropertyRegionID !== dataItem.PropertyRegionID));
    };

    const _add = (dataItem: PropertyRegionViewModel) => {
        dataItem.inEdit = false;

        insertItem(dataItem)
            .then((data) => setPropertyRegions(data.PropertyRegions));
    };

    const _update = async (dataItem: PropertyRegionViewModel) => {
        dataItem.inEdit = false;
        await updateItem(dataItem);

        // basically forceUpdate()
        const data = propertyRegions.map(item =>
            item.PropertyRegionID === dataItem.PropertyRegionID ? dataItem : item
        );
        setPropertyRegions(data);
    };

    const _discard = (dataItem: PropertyRegionViewModel) => {
        const data = [...propertyRegions];
        data.splice(0, 1)
        setPropertyRegions(data);
    };

    const _cancel = (dataItem: PropertyRegionViewModel) => {
        getItems().then((response) => {
            const originalItem = response.PropertyRegions.find(x => x.PropertyRegionID === dataItem.PropertyRegionID);
            if (originalItem) {
                const data = propertyRegions.map(item =>
                    item.PropertyRegionID === originalItem.PropertyRegionID ? originalItem : item
                );
                setPropertyRegions(data);
            }
        });
    };

    const _enterEdit = (dataItem: PropertyRegionViewModel) => {
        setPropertyRegions(propertyRegions.map(item =>
            item.PropertyRegionID === dataItem.PropertyRegionID ? { ...item, inEdit: true } : item
        ));
    };

    const _itemChange = (event: GridItemChangeEvent) => {
        const data = propertyRegions.map(item =>
            item.PropertyRegionID === event.dataItem.PropertyRegionID ? { ...item, [event.field || '']: event.value } : item
        );

        setPropertyRegions(data);
    }

    const _addNew = () => {
        const newDataItem = { inEdit: true } as PropertyRegionViewModel;
        setPropertyRegions([newDataItem, ...propertyRegions]);
    }

    return <>
        <Title string="Locations" />
        <Grid
            scrollable="none"
            data={propertyRegions}
            onItemChange={_itemChange}
            editField={editField}
        >
            <GridToolbar>
                <Button
                    themeColor="primary"
                    onClick={_addNew}
                    disabled={!isAdmin}
                >
                    Add Location
            </Button>
            </GridToolbar>
            <Column field="Region" editor="numeric" />
            <Column field="LocationType" title="Type" cell={LocationTypeCell} />
            <Column field="LocationName" title="Location" />
            <Column field="State" title="State" />
            <Column cell={_commandCell} width="200px" />
        </Grid>
    </>;
}

export default Regions;
