import * as React from 'react';
import LoadingPanel from './LoadingPanel';
import { Upload, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import { Title } from '../utils/title';

const UploadSMSLeads = () => {

    const [loading, setLoading] = React.useState(false);

    const onStatusChange = (e: UploadOnStatusChangeEvent) => {
        if (e.response) {
            setLoading(false);
            alert(`${e.response.response.RecordsSaved} Records Uploaded!`);
        }
    }

    return <div className="row align-items-center">
        <div className="col">
            {loading && <LoadingPanel />}
            <Title string="Upload SMS Leads" />
            <h1>Upload SMS Leads</h1>
            <p className="p-1">Should be saved as .csv from Excel with the columns "Address, Name, Phone".</p>
            <Upload
                batch={false}
                multiple={false}
                autoUpload={false}
                defaultFiles={[]}
                withCredentials={false}
                saveHeaders={{
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                }}
                restrictions={{
                    allowedExtensions: ['.csv']
                }}
                saveUrl={`/api/CallLead/UploadSMSLeads`}
                saveMethod="POST"
                onBeforeUpload={() => setLoading(true)}
                onStatusChange={onStatusChange}
            />
        </div>
    </div>
};


export default UploadSMSLeads;
