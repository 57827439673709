import { PropertyInvestmentType } from 'TypeGen/property-investment-type';
import { PropertyInvestorStatus } from 'TypeGen/property-investor-status';
import { EscrowCompanyViewModel } from '../components/EscrowCompany';
import { AgentViewModel } from './agent';
import { IDName } from './generic';
import { InvestorViewModel } from './investor';
import { ConstructionProjectViewModel } from 'TypeGen/construction-project-view-model';

export enum PropertyStatus {
    Queued = 10,
    Reserved = 20,
    Qualified = 30,
    Registered = 40,
    Initiated = 50,
    Negotiated = 60,
    BidRejected = 70,
    BidAccepted = 80,
    Cancelled = 90,
    Closed = 100,
    OfferedInvestor = 110,
    FinalizedInvestor = 120,
    Listed = 130,
    SalePending = 135,
    Sold = 140
}

export const PropertyStatusLabel = new Map<PropertyStatus, string>([
    [PropertyStatus.Queued, 'Queued'],
    [PropertyStatus.Reserved, 'Reserved'],
    [PropertyStatus.Qualified, 'Qualified'],
    [PropertyStatus.Registered, 'Registered'],
    [PropertyStatus.Initiated, 'Initiated'],
    [PropertyStatus.Negotiated, 'Negotiated'],
    [PropertyStatus.BidRejected, 'Bid Rejected'],
    [PropertyStatus.BidAccepted, 'Bid Accepted'],
    [PropertyStatus.Cancelled, 'Cancelled'],
    [PropertyStatus.Closed, 'Closed'],
    [PropertyStatus.OfferedInvestor, 'Offered Investor'],
    [PropertyStatus.FinalizedInvestor, 'Finalized Investor'],
    [PropertyStatus.Listed, 'Listed'],
    [PropertyStatus.SalePending, 'Sale Pending'],
    [PropertyStatus.Sold, 'Sold'],
]);

export enum PropertyConstructionStatus {
    Planning = 10,
    Demolition = 20,
    Construction = 30,
    Staging = 70,
    Listed = 80,
    Contracted = 90,
    Sold = 100,
    Refinanced = 110,
    Rented = 120,
}

export const PropertyConstructionStatusLabel = new Map<PropertyConstructionStatus, string>([
    [PropertyConstructionStatus.Planning, 'Planning'],
    [PropertyConstructionStatus.Demolition, 'Demolition'],
    [PropertyConstructionStatus.Construction, 'Construction'],
    [PropertyConstructionStatus.Staging, 'Staging'],
    [PropertyConstructionStatus.Listed, 'Listed'],
    [PropertyConstructionStatus.Contracted, 'Contracted'],
    [PropertyConstructionStatus.Sold, 'Sold'],
    [PropertyConstructionStatus.Refinanced, 'Refinanced'],
    [PropertyConstructionStatus.Rented, 'Rented'],
]);

export type PropertyViewModel = {
    PropertyID: number;
    Status: PropertyStatus;
    ConstructionStatus: PropertyConstructionStatus | null;
    Address: PropertyAddress;
    SubmittedAgent: AgentViewModel;
    AssignedAgent: AgentViewModel | null;
    PhysicalAttributes: PropertyPhysicalAttribute;
    Specifications: PropertySpecification;
    Communications: PropertyCommunication;
    ListingSpecifications: PropertyListingSpecification;
    InvestmentSpecifications: PropertyInvestmentSpecification[];
    Active: boolean;
    LatestNote: string;
    ModifiedDateTime: Date;
    SubmittedDateTime: Date;
    ClosedDateTime: Date | null;
    HasBidAuthorization: boolean;
    HasAddBidAuthorization: boolean;
    HasNote: boolean;
    Tags: string | null;
    ConstructionProject: ConstructionProjectViewModel;
}

type PropertyAddress = {
    UnitNumber?: string;
    StreetNumber: string;
    StreetName: string;
    StreetAddress: string;
    City: string;
    State: string;
    Zip: string;
    Latitude: number;
    Longitude: number;
}

export enum PropertyUnitType {
    Unknown,
    Single,
    Duplex,
    Triplex
}

export enum PropertyLotSizeUnit {
    Sqft,
    Acres
}

export enum PropertyFrontBack {
    None,
    Front,
    Back,
    Both,
}

export enum PropertyCondition {
    Unknown,
    Good,
    Bad
}

export enum PropertyInvestorOfferingStatus {
    Draft,
    Open,
    Finalized,
}

export const PropertyInvestmentTypeLabel = new Map<PropertyInvestmentType, string>([
    [PropertyInvestmentType.B52, 'B52'],
    [PropertyInvestmentType.ShortTerm, 'Short Term'],
    [PropertyInvestmentType.LongTerm, 'Long Term'],
]);

type PropertyPhysicalAttribute = {
    BusyStreet: PropertyFrontBack | null;
    Cemetery: PropertyFrontBack | null;
    School: PropertyFrontBack | null;
    DoubleYellowLine: PropertyFrontBack | null;
    CommercialBuilding: PropertyFrontBack | null;
    Roof: PropertyCondition | null;
}

type PropertySpecification = {
    Bathrooms: number;
    Bedrooms: number;
    YearBuilt: number;
    UnitType: PropertyUnitType;
    LotSize: number;
    LotSizeUnit: PropertyLotSizeUnit;
    SquareFeet: number;
}

type PropertyListingSpecification = {
    FinalBathrooms: number;
    FinalBedrooms: number;
    FinalSquareFeet: number;
    ListingCode: string;
    ListingLink: string;
    LockboxCode: string;
    ViewingInstructions: string;
}

type PropertyCommunication = {
    BeforeReviewDate: boolean | null;
    ExpectCounter: boolean | null;
    IsAuctionHome: boolean | null;
    Tenants: number | null;
    IsCourtApproved: boolean | null;
    IsHudHome: boolean | null;
    IsNegotiable: boolean | null;
    BuyerRepresentation: number | null;
    MotivationCashPayment: boolean;
    MotivationGoodPrice: boolean;
    MotivationGoodTerms: boolean;
    MotivationQuickClose: boolean;
    OfferCount: number | null;
    ReasonForSelling: string;
    ReviewDate: Date | null;
}

export type PropertyInvestmentSpecification = {
    PropertyInvestorOfferingID: number;
    Status: PropertyInvestorOfferingStatus;
    InvestmentType: PropertyInvestmentType;
    Badge: string;

    // Rental
    EstimatedMonthlyExpenses: number;
    EstimatedMonthlyRent: number;
    EstimatedNightlyRate: number;
    OccupancyRate: number;
    AppreciationRate: number;

    // B52
    LoanPayoffAmount: number;
    DepositAmount: number;
    FinalPrice: number;
    TotalInvestors: number;
    DepositDue: string;
    EstimatedInvestmentAmount: number;
    MinInvestmentAmount: number;
    MaxInvestmentAmount: number;
    EscrowPurchaseDateTime: Date;
    EstimatedSplit: string;
    InvestmentOfferNote: string;
    ConstructionScopeOfWork: string;
    ExpiryDateTime: Date | null;
    PercentFunded: number | null;
    FriendlyName: string;
    ListingLink: string;
    NDARequired: boolean;
    NDALink: string;

    PurchasePrice: number;
    RehabCost: number;
    AfterRepairValue: number;
    AssignedEscrowCompany: EscrowCompanyViewModel | null;
}

export enum PropertyTitle {
    Buyer,
    Seller,
    FiftyFifty
}

export const PropertyTitleLabel = new Map<PropertyTitle, string>([
    [PropertyTitle.Buyer, 'Buyer'],
    [PropertyTitle.Seller, 'Seller'],
    [PropertyTitle.FiftyFifty, '50/50'],
]);

export enum PropertyEscrow {
    Buyer,
    Seller,
    FiftyFifty
}

export const PropertyEscrowLabel = new Map<PropertyEscrow, string>([
    [PropertyEscrow.Buyer, 'Buyer'],
    [PropertyEscrow.Seller, 'Seller'],
    [PropertyEscrow.FiftyFifty, '50/50'],
]);

export enum PropertyHOA {
    Buyer,
    Seller,
    None
}

export enum PropertyRenovation {
    Light,
    Medium,
    Heavy
}

export enum PropertyBidStatus {
    Unknown = 0,
    Submitted = 10,
    Accepted = 20,
    Rejected = 30
}

export enum PropertyOfferStatus {
    Submitted = 10,
    Rejected = 20,
    Countered = 30,
    Accepted = 40
}

export enum PropertyOfferFinanceType {
    Unknown = 0,
    VA = 10,
    FHA = 20,
    Conventional = 30,
    Cash = 40
}

export type PropertyBid = {
    PropertyBidID: number;
    CloseOfEscrowDays: number;
    EarnestMoneyDeposit: number;
    EarnestMoneyDepositDays: number;
    EscalactionClause: boolean;
    EscalactionClauseReason: string;
    Escrow: PropertyEscrow;
    HOA: PropertyHOA;
    Price: number;
    SubmittedDateTime: Date;
    Title: PropertyTitle;
    SubmittedAgent: AgentViewModel;
    ReasonForRejecting: string;
    Notes: string;
    AfterRepairValue: number;
    RehabCost: number;
    PropertyRenovation: PropertyRenovation;
    BidStatus: PropertyBidStatus;
}

export type PropertyInvestor = {
    PropertyInvestorID: number;
    PropertyInvestorOfferingID: number;
    SubmittedDateTime: Date;
    Status: PropertyInvestorStatus;
    InvestmentType: PropertyInvestmentType;
    HowYouHeard: string;
    OriginalOffer: number;
    AcceptedOffer: number;
    DepositAmount: number;
    PayoutAmount: number;
    DeedPosition: number;
    FundingSource: string;
    SubmittedInvestor: InvestorViewModel;
    ReasonForRejecting: string;
    InvoicePaid: boolean | null;
    StripeInvoiceID: string;
    JointVentureURL: string;
}

export type PropertyOffer = {
    PropertyOfferID: number;
    OfferStatus: PropertyOfferStatus;
    Price: number;
    CloseOfEscrowDays: number;
    ClosingCostCredit: boolean;
    ClosingCostCreditAmount: number;
    FinanceType: PropertyOfferFinanceType;
    EarnestMoneyDeposit: number;
    EarnestMoneyDepositDays: number;
    Title: PropertyTitle;
    Escrow: PropertyEscrow;
    EscalactionClause: boolean;
    EscalactionClauseReason: string;
    ReasonForRejecting: string;
    SubmittedDateTime: Date;
    SubmittedAgent: AgentViewModel;
    Notes: string;
    ClientFullName: string;
}

export enum PropertyImageType {
    Unclassified,
    BusyStreet,
    Cemetery,
    School,
    DoubleYellowLine,
    CommercialBuilding,
    Roof,
    InvestorListing
}

export type PropertyImage = {
    PropertyImageID: number;
    UploadDateTime: Date;
    PropertyImageType: PropertyImageType;
}

export enum RegionType {
    County,
    City,
    Zip
}

export const RegionTypeLabel = new Map<RegionType, string>([
    [RegionType.County, 'County'],
    [RegionType.City, 'City'],
    [RegionType.Zip, 'Zip'],
]);

export function getLocationType(value: RegionType): string {
    return RegionTypeLabel.get(value) as string;
}

export function getPropertyTitle(value: PropertyTitle): string {
    return PropertyTitleLabel.get(value) as string;
}
export function getPropertyEscrow(value: PropertyEscrow): string {
    return PropertyEscrowLabel.get(value) as string;
}
export function getPropertyHOA(value: PropertyHOA): string {
    switch (value) {
        case PropertyHOA.None:
            return "No One";
    }
    return PropertyHOA[value];
}
export function getPropertyRenovation(value: PropertyRenovation): string {
    return PropertyRenovation[value];
}

export function getPropertyStatus(propertyStatus: PropertyStatus): string {
    return PropertyStatusLabel.get(propertyStatus) as string;
}

export function getPropertyConstructionStatus(propertyConstructionStatus: PropertyConstructionStatus): string {
    return PropertyConstructionStatusLabel.get(propertyConstructionStatus) as string;
}

export function getPropertyInvestmentType(propertyInvestmentType: PropertyInvestmentType): string {
    return PropertyInvestmentTypeLabel.get(propertyInvestmentType) as string;
}

export function getPropertyUnitType(unitType: PropertyUnitType): string {
    return PropertyUnitType[unitType];
}

export function getPropertyOfferFinanceType(financeType: PropertyOfferFinanceType): string {
    return PropertyOfferFinanceType[financeType];
}

export function getPropertyOfferStatus(offerStatus: PropertyOfferStatus): string {
    switch (offerStatus) {
        case PropertyOfferStatus.Submitted:
            return "Pending Decision"
    }
    return PropertyOfferStatus[offerStatus];
}

export function getPropertyInvestorStatus(offerStatus: PropertyInvestorStatus): string {
    switch (offerStatus) {
        case PropertyInvestorStatus.Rejected:
            return "Declined"
        case PropertyInvestorStatus.Submitted:
            return "Pending Decision"
    }
    return PropertyInvestorStatus[offerStatus];
}

export function getPropertyLotSizeUnit(lotSizeUnit: PropertyLotSizeUnit): string {
    switch (lotSizeUnit) {
        case PropertyLotSizeUnit.Acres:
            return "ac."
        case PropertyLotSizeUnit.Sqft:
            return "sq. ft."
    }
    return PropertyLotSizeUnit[lotSizeUnit];
}

export function getPropertyImageType(propertyImageType: PropertyImageType): string {
    switch (propertyImageType) {
        case PropertyImageType.BusyStreet:
            return "Busy Street";
        case PropertyImageType.DoubleYellowLine:
            return "Double Yellow Line";
        case PropertyImageType.CommercialBuilding:
            return "Commercial Building";
        case PropertyImageType.InvestorListing:
            return "Investor Listing";
        default:
            return PropertyImageType[propertyImageType] as string;
    };
}

export function getPropertyFrontBack(propertyFrontBack: PropertyFrontBack | null): string {
    switch (propertyFrontBack) {
        case null:
        case PropertyFrontBack.None:
            return '';
        case PropertyFrontBack.Both:
            return "Back & Front";
        default:
            return PropertyFrontBack[propertyFrontBack] as string;
    }
}

export function getPropertyCondition(propertyCondition: PropertyCondition | null): string {
    if (propertyCondition == null) return '';
    return PropertyCondition[propertyCondition] as string;
}

export function getPropertyFrontBackProperty(property: PropertyViewModel, propertyImageType: PropertyImageType): string {
    switch (propertyImageType) {
        case PropertyImageType.BusyStreet:
            return getPropertyFrontBack(property.PhysicalAttributes.BusyStreet);
        case PropertyImageType.Cemetery:
            return getPropertyFrontBack(property.PhysicalAttributes.Cemetery);
        case PropertyImageType.CommercialBuilding:
            return getPropertyFrontBack(property.PhysicalAttributes.CommercialBuilding);
        case PropertyImageType.DoubleYellowLine:
            return getPropertyFrontBack(property.PhysicalAttributes.DoubleYellowLine);
        case PropertyImageType.Roof:
            return getPropertyCondition(property.PhysicalAttributes.Roof);
        case PropertyImageType.School:
            return getPropertyFrontBack(property.PhysicalAttributes.School);
        default:
            return getPropertyFrontBack(PropertyFrontBack.None);
    }
}

export function getPropertyExpectCounter(expectCount: boolean | null): string {
    if (expectCount == null) return "Unknown";
    return expectCount ? "Yes" : "Best Offer Only"
}

export const getPropertyStatuses = () => {
    const options: IDName[] = [];
    for (var enumMember in PropertyStatus) {
      const num = Number(enumMember);
      if (!isNaN(num)) {
        options.push({ ID: num, Name: getPropertyStatus(num) });
      }
    }
    return options;
}

export const getPropertyConstructionStatuses = () => {
    const options: IDName[] = [];
    for (var enumMember in PropertyConstructionStatus) {
      const num = Number(enumMember);
      if (!isNaN(num)) {
        options.push({ ID: num, Name: getPropertyConstructionStatus(num) });
      }
    }
    return options;
}

export function getPropertyInvestmentTypes() {
    const options: IDName[] = [];
    for (var enumMember in PropertyInvestmentType) {
      const num = Number(enumMember);
      if (!isNaN(num)) {
        options.push({ ID: num, Name: getPropertyInvestmentType(num) });
      }
    }
    return options;
}