import { useEffect, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Button } from 'reactstrap';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { formatNumber } from '@telerik/kendo-intl';
import { fetchApi } from '../../../services/api';
import LoadingPanel from '../../LoadingPanel';
import { isNullOrEmptyValidator } from '../../../utils/generic';
import { Title } from '../../../utils/title';

const PropertyCalculator = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [location, setLocation] = useState<string>('');
    const [sqft, setSqft] = useState<number | null>(null);
    const [constructionType, setConstructionType] = useState<number | null>(null);
    const [arv, setArv] = useState<number | null>(null);
    const [offerPrice, setOfferPrice] = useState<number>(0);

    const constructionTypes = [
        { text: 'Light', id: 0 },
        { text: 'Medium', id: 1 },
        { text: 'Heavy', id: 2 }
    ];

    // Because this page doesn't fetch anything on load
    useEffect(() => {
        if (!localStorage.getItem('ACCESS_TOKEN')) {
            window.location.href = '/User/Logout';
        }
    }, []);

    const _getOfferPrice = () => {

        const locationError = isNullOrEmptyValidator(location, 'Please enter a home address');
        const sqftError = sqft == null || sqft < 1 ? 'Please enter house square footage' : '';
        const constructionTypeError = constructionType == null ? 'Please select a Construction Type' : '';
        const arvError = arv == null || arv < 1 ? 'Please enter house After Repair Value (ARV)' : '';

        if (locationError || sqftError || constructionTypeError || arvError) {
            alert([locationError, sqftError, constructionTypeError, arvError].filter(x => x).join("\r\n"));
            return;
        }

        setLoading(true);
        setOfferPrice(0);

        const data = {
            Location: location,
            Sqft: sqft,
            PropertyRenovation: constructionType,
            AfterRepairValue: arv
        }
        fetchApi('/api/Property/PropertyCalculator', data, 'POST')
            .then((response: { OfferPrice: number, County: string, RegionNotFound: boolean }) => {
                setLoading(false);
                setOfferPrice(response.OfferPrice);
                if (response.RegionNotFound) {
                    alert(`We are currently not accepting properties from this region!\r\nCounty: ${response.County}`);
                }
            })
            .catch(() => {
                setLoading(false);
                alert("Unable to calculate offer price!");
            })
    }

    return <div>
        <Title string="Offer Calculator" />
        {loading && <LoadingPanel />}
        <GooglePlacesAutocomplete
            autocompletionRequest={{
                componentRestrictions: {
                    country: ['us'],
                },
                types: ["address"]
            }}
            selectProps={{
                placeholder: 'Enter Home Address',
                styles: {
                    option: (provided) => ({
                        ...provided,
                        color: '#343a40',
                    }),
                    container: (provided) => ({
                        ...provided,
                        zIndex: 2,
                    }),
                },
                onChange: (e) => {
                    setLocation(e?.label);
                    setOfferPrice(0);
                }
            }}
        />

        <NumericTextBox
            width="100%"
            label="House Square Footage"
            min={100}
            step={100}
            max={30000}
            value={sqft}
            onChange={(e) => {
                setSqft(e.value || 0);
                setOfferPrice(0);
            }}
        />

        <DropDownList
            label="Construction Type"
            style={{ width: '100%' }}
            data={constructionTypes}
            textField="text"
            dataItemKey="id"
            value={constructionTypes.find(x => x.id === constructionType)}
            onChange={(e) => {
                setConstructionType(e.value.id);
                setOfferPrice(0);
            }}
        />

        <NumericTextBox
            width="100%"
            label="After Repair Value (ARV)"
            min={50000}
            step={5000}
            format="c0"
            max={10000000}
            value={arv}
            onChange={(e) => {
                setArv(e.value || 0);
                setOfferPrice(0);
            }}
        />

        <br /><br />
        <Button color="primary" onClick={_getOfferPrice}>Get Offer Price</Button>

        {offerPrice > 0 && <>
            <hr />
            <h1 className="display-5 text-success">Offer: {formatNumber(offerPrice, "c")}</h1>
        </>}
    </div>
}

export default PropertyCalculator;
