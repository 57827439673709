import LoadingPanel from '../LoadingPanel';
import { Title } from '../../utils/title';
import { CompositeFilterDescriptor, DataSourceRequestState, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Grid, GridToolbar, GridColumn as Column, GridDataStateChangeEvent, GridCellProps, GridFilterCellProps } from '@progress/kendo-react-grid';
import { useCallback, useEffect, useRef, useState } from 'react';
import { fetchApi } from '../../services/api';
import { Link } from 'react-router-dom';
import { IDName } from '../../utils/generic';
import { formatNumber } from '@telerik/kendo-intl';
import { debounce } from 'ts-debounce';
import { DropdownFilterCell } from '../DropdownFilterCell';

export enum CommissionType {
    Flat,
    Percentage
}

export enum AppType {
    AgentApp,
    InvestorApp
}

export function getAppReferralAppType(appType: AppType): string {
    switch (appType) {
        case AppType.AgentApp:
            return "Agent App";
        case AppType.InvestorApp:
            return "Investor App";
        default:
            return AppType[appType];
    }
}

export function getAppReferralAppTypes() {
    const typeOptions: IDName[] = [];
    for (var enumMember in AppType) {
        const num = Number(enumMember);
        if (!isNaN(num))
            typeOptions.push({ ID: num, Name: getAppReferralAppType(num) });
    }
    return typeOptions;
}
export const appReferralTypeOptions = getAppReferralAppTypes();
//const appReferralTypeOptions2 = [{ ID: -1, Name: '-' } as IDName].concat(appReferralTypeOptions);

export function getCommissionTypes() {
    const typeOptions: IDName[] = [];
    for (var enumMember in CommissionType) {
        const num = Number(enumMember);
        if (!isNaN(num))
            typeOptions.push({ ID: num, Name: CommissionType[num] });
    }
    return typeOptions;
}
export const commissionTypeOptions = getCommissionTypes();

export type AppReferralViewModel = {
    AppReferralID: number;

    Description: string;
    AppType: AppType;
    ReferralURL: string;

    Salesperson1Name: string;
    Salesperson1Email: string;
    Salesperson1CommissionAmount: number;
    Salesperson1CommissionType: CommissionType;

    Salesperson2Name: string;
    Salesperson2Email: string;
    Salesperson2CommissionAmount: number;
    Salesperson2CommissionType: CommissionType;

    Active: boolean;
    QRCode: string;
}

interface DownloadQRCodeCommandCellProps extends GridCellProps {
    downloadQRCode: (item: AppReferralViewModel) => void;
}
const DownloadQRCodeCommandCell = (props: DownloadQRCodeCommandCellProps) => {
    return (
        <td>
            <button className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md k-icon-button" onClick={() => { props.downloadQRCode(props.dataItem) }}>
                <span role="presentation" className="k-button-icon k-icon k-i-download"></span>
            </button>
        </td>
    );
};

const AppTypeFilterCell = (props: GridFilterCellProps) => (
    //.concat({ ID: -1, Name: '-' } as IDName)
    <DropdownFilterCell
        {...props}
        data={appReferralTypeOptions}
        defaultItem={{ ID: -1, Name: '-' } as IDName}
    />
);

//const DetailComponent = (props: GridDetailRowProps) => {
//    const dataItem = props.dataItem as AppReferralViewModel;
//    return (
//        <span>{dataItem.Description}</span>
//    );
//};

const AppReferrals = () => {
    const resetDataState = {
        skip: 0,
        take: 50,
        sort: [{
            field: "AppReferralID",
            dir: "desc"
        }]
    } as GridState;

    // const sessionStorageAndFilter = sessionStorage.getItem("AppReferralGrid-andFilter");
    let andFilter: CompositeFilterDescriptor | undefined;
    //if (sessionStorageAndFilter) {
    //    andFilter = JSON.parse(sessionStorageAndFilter);
    // } else {
    andFilter = {
        logic: 'and', filters: [
            { field: 'active', operator: 'eq', value: true },
        ]
    }
    //}
    const [dataState, setDataState] = useState<DataSourceRequestState>({ ...resetDataState, filter: andFilter });
    const [loading, setLoading] = useState(false);
    const [appReferrals, setAppReferrals] = useState<AppReferralViewModel[]>([]);
    const totalRecords = useRef(0);
    const DataState_Take = 50;

    const fetchAppReferral = (appReferralId: number) => {
        setLoading(true);
        fetchApi(`/api/AppReferral/Get/${appReferralId}`)
            .then((response: AppReferralViewModel) => {
                setLoading(false);
                downloadURI(response.QRCode, `QRCode${response.AppReferralID}.png`);
            });
    }

    const fetchAppReferrals = (val: GridState) => {
        //sessionStorage.setItem("AppReferralGrid-andFilter", JSON.stringify(val.filter));
        setLoading(true);
        const dataGridState = { ...val };

        dataGridState.take = DataState_Take;
        dataGridState.skip = 0;
        window.scrollTo(0, 0);
        document.getElementsByClassName("k-grid-content")[0].scrollTop = 0;

        const queryStr = `${toDataSourceRequestString(dataGridState)}`;
        fetchApi(`/api/AppReferral/List?${queryStr}`, {}, 'POST')
            .then(({ Data, Total }) => {
                totalRecords.current = Total;
                setAppReferrals(Data);
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSearchInput = useCallback(
        debounce((val: GridState) => {
            fetchAppReferrals(val);
        }, 750), []);

    const dataStateChange = (changeEvent: GridDataStateChangeEvent) => {
        setDataState(changeEvent.dataState);
        debounceSearchInput(changeEvent.dataState);
    }

    useEffect(() => {
        fetchAppReferrals(dataState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const AppReferralIDCell = (props: GridCellProps) => {
        if (!props.field)
            return null;

        let dataItem: AppReferralViewModel = props.dataItem;

        return (
            <td colSpan={props.colSpan} style={props.style}>
                <Link to={`/AppReferral/${dataItem.AppReferralID}/Profile`} style={{ color: '#007bff' }}>
                    {dataItem.AppReferralID}
                </Link><br />
            </td>
        );
    };

    const AppTypeCell = (props: GridCellProps) => {
        if (!props.field)
            return null;

        let dataItem: AppReferralViewModel = props.dataItem;

        return (
            <td colSpan={props.colSpan} style={props.style}>
                {appReferralTypeOptions.find(x => x.ID === dataItem.AppType)?.Name}
            </td>
        );
    };

    const CommissionAmountCell = (props: GridCellProps) => {
        if (!props.field)
            return null;

        let dataItem: AppReferralViewModel = props.dataItem;

        //const index = Number(props.field.replace(/salesperson/ig, '').replace(/commission/ig, ''));
        const index = Number(props.field.replace(/[^0-9]/g, ''));
        let salespersonCommType: CommissionType;
        let salespersonCommAmt: number;
        switch (index) {
            case 1:
                salespersonCommType = dataItem.Salesperson1CommissionType;
                salespersonCommAmt = dataItem.Salesperson1CommissionAmount;
                break;
            default:
                salespersonCommType = dataItem.Salesperson2CommissionType;
                salespersonCommAmt = dataItem.Salesperson2CommissionAmount;
                break;
        }

        return (
            <td colSpan={props.colSpan} style={props.style}>
                {
                    salespersonCommAmt > 0 ?
                        salespersonCommType === CommissionType.Percentage ? formatNumber(salespersonCommAmt, 'p1') : formatNumber(salespersonCommAmt, 'c2') : ''
                }
            </td>
        );
    };

    const downloadURI = (uri: string, name: string) => {
        var link = document.createElement("a");
        link.setAttribute('download', name);
        link.href = `data:image/png;base64,${uri}`;
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const downloadQRCode = (item: AppReferralViewModel) => {
        fetchAppReferral(item.AppReferralID);
    };
    const MyDownloadQRCodeCommandCell = (props: GridCellProps) => (
        <DownloadQRCodeCommandCell {...props} downloadQRCode={downloadQRCode} />
    );
    return <div className="row align-items-center">
        <div className="col">
            {loading && <LoadingPanel />}
            <Title string="App Referrals" />
            <h3 className="mb-2">App Referrals</h3>
            <Grid
                resizable={true}
                filterable={true}
                sortable={true}
                pageable={true}
                {...dataState}
                data={appReferrals}
                onDataStateChange={dataStateChange}
            //detail={DetailComponent}                
            >
                <GridToolbar>
                    <Link
                        //hidden={!this.state.isAdmin}
                        to="/AppReferral/0/Profile"
                        role="button"
                        className="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-primary k-rounded-md"
                    >
                        <span style={{ color: 'white' }}>Add Referral</span>
                    </Link>
                </GridToolbar>
                <Column field="AppReferralID" title="ID" filter="numeric" cell={AppReferralIDCell} width={150} />
                <Column field="Description" title="Description" />
                <Column field="AppType" title="Application" cell={AppTypeCell} filterCell={AppTypeFilterCell} />
                <Column field="Salesperson1Email" title="Salesperson 1" filter="text" />
                <Column field="Salesperson1CommissionAmount" title="Commission" filter="numeric" cell={CommissionAmountCell} />
                <Column field="Salesperson2Email" title="Salesperson 2" filter="text" />
                <Column field="Salesperson2CommissionAmount" title="Commission" filter="numeric" cell={CommissionAmountCell} />
                <Column cell={MyDownloadQRCodeCommandCell} title="QR" filterable={false} sortable={false} width={56} />
            </Grid>
        </div>
    </div>
};
export default AppReferrals;
