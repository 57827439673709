import { GridCellProps } from "@progress/kendo-react-grid";
import { parsePhoneNumber } from "libphonenumber-js";

export const PhoneNumberCell = (props: GridCellProps) => {
    if (!props.field) return null;
    const value = parsePhoneNumber(props.dataItem[props.field], "US");
    return (
        <td>
            {value ? value.countryCallingCode === '1' ? value.formatNational() : value.formatInternational() : props.dataItem[props.field]}
        </td>
    );
}