import { Outlet, useParams } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Container, Nav, Navbar, NavItem } from 'reactstrap';
import NavMenu from './NavMenu';

export default function Layout() {
    return <>
        <NavMenu/>
        <Container fluid={true}>
            <Outlet />
        </Container>
    </>;
}

type InvestorParams = {
    InvestorId: string;
}

export function InvestorLayout() {
    const { InvestorId } = useParams<InvestorParams>();
    return <>
        <Navbar dark expand className='bg-dark container'>
            <Link to="/Investor/Index" className='navbar-brand'>Investors</Link>
            <Nav className="me-auto" navbar>
                <NavItem>
                    <NavLink to={`/Investor/${InvestorId}/Profile`} className="nav-link">Profile</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to={`/Investor/${InvestorId}/Transactions`} className="nav-link">Transactions</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to={`/Investor/${InvestorId}/Referrals`} className="nav-link">Referrals</NavLink>
                </NavItem>
            </Nav>
        </Navbar>
        <Outlet />
    </>;
}

type AppReferralParams = {
    AppReferralId: string;
}

export function AppReferralLayout() {
    const { AppReferralId } = useParams<AppReferralParams>();
    return <>
        <Navbar dark expand className='bg-dark container'>
            <Link to="/AppReferrals" className='navbar-brand'>App Referrals</Link>
            <Nav className="me-auto" navbar>
                <NavItem>
                    <NavLink to={`/AppReferral/${AppReferralId}/Profile`} className="nav-link">Profile</NavLink>
                </NavItem>
                {AppReferralId !== '0' && <NavItem>
                    <NavLink to={`/AppReferral/${AppReferralId}/Referrals`} className="nav-link">Referrals</NavLink>
                </NavItem>}
            </Nav>
        </Navbar>
        <Outlet />
    </>;
}
